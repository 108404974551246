import React, {useEffect, useRef, useState} from "react";
import {Modal, Table, displayDate, Button, notification} from "../../../components/Elements/appUtils";
import { useDispatch } from "react-redux";
import { installmentHistoryFxn, verifyInstallmentFxn } from "../actions";
import { appInvoiceListFxn } from "../../student/actions";
import InputBox from "../../../components/Elements/InputBox";
import VerifyInstallmentModal from "./verifyInstallmentModal";

const InstallmentHistoryModal = (props) => {
    const { visible, invoiceId, applicationId, onClose } = props;
    const [installments, setInstallments] = useState([]);
    const [showModal, setShowModal] = useState({
        selectedInstallment: {},
        visible: false
    });

    const dispatch = useDispatch();
    const tableRef = useRef()

    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};

    const apiRequest = async () => {

        return new Promise(async (resolve) => {

            const resp = await dispatch(installmentHistoryFxn({ applicationId, invoiceId }));
            const response = resp ? resp : []
            resolve({data: response});
        });
    };

    const events = {
        showVerifyModal: (record) => {
            setShowModal({
                selectedInstallment: record,
                visible: true
            });
        },
        hideVerifyModal: () => {
            setShowModal({
                selectedInstallment:'',
                visible: false
            });
            tableRef.current.reload();
        },
    };

    const columns = [
        { title: "Sr. No.", key: "serialNumber", render: (text, record, index) => index + 1 },
        { title: "Installment Date", dataIndex: "installmentDate", key: "installmentDate", render: (item) => displayDate(item) },
        { title: "Amount Paid", dataIndex: "installmentAmount", key: "installmentAmount" },
        {
            title: "Action",
            key: "action",

            render: (text, record) => (

                <>
                    <div className={'mt-2'}>
                        {record.status === "not_verified" ? (
                            <label className="badge bg-label-danger">Not Verified</label>
                        ) : record.receipt && record.receipt.path ? (
                            <a className={'btn rounded-pill btn-outline-primary btn-xs'} href={record.receipt.path}>
                                <i className={'bx bxs-file-pdf'}/> View Receipt
                            </a>
                        ) : user.userType === 'university' ? (
                            <a className={'btn rounded-pill btn-outline-primary btn-xs'} onClick={() => events.showVerifyModal(record)}>
                                Verify Installment
                            </a>
                        ) : null}
                    </div>
                </>
            )
        },
    ];

    return (
        <>
            <Modal title="Installment History" visible={visible} onClose={onClose}>
                <Table columns={columns} apiRequest={apiRequest} bordered ref={tableRef} showView={false}/>
            </Modal>

            {showModal.visible && (
                <VerifyInstallmentModal
                    installment={ showModal.selectedInstallment}
                    onClose={() => events.hideVerifyModal()}
                    visible={showModal.visible}
                />
            )}
        </>
    );
};

export default InstallmentHistoryModal;
