import React, {Suspense, useEffect, useRef, useState} from "react";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {displayDate, Form, notification} from "../../../components/Elements/appUtils";
import {Modal} from "../../../components/Elements/appUtils";
import {useDispatch} from "react-redux";
import {documentListFxn, latestDocumentListFxn, uploadDocumentFxn} from "../../../components/profileComponent/action";
import {documentTypes} from "../../../components/_utils/_utils";
import _ from "lodash"

const Documents = (props) => {
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false);
    const [addDocumentModal, setAddDocumentModal] = useState(false);
    const [documentList, setDocumentList] = useState([]);
    const [otherDoc, setOtherDoc] = useState();


    let {applicationId, studentId} = props
    let {form: {getFieldDecorator, getFieldError, setFieldsValue, getFieldValue}} = props;
    const [imageSrc, setImageSrc] = useState({});

    const handleSubmit = async () => {
        const {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                let fd = new FormData();
                let types = valData.name ? valData.name : imageSrc.name
                let obj = {
                    studentId: studentId,
                    type: types
                }
                let checkIsOther = _.find(documentTypes, (item) => {
                    return item.name == types
                })
                if (!checkIsOther) {
                    obj.applicationId = applicationId;
                } else {
                    if (imageSrc.appIdRequired) {
                        obj.applicationId = applicationId;
                    }
                }

                fd.append('obj', JSON.stringify(obj));
                if (imageSrc) {
                    fd.append('document', imageSrc.file)
                }
                let resp = await dispatch(uploadDocumentFxn(fd));
                if (resp && resp.success) {
                    setImageSrc({});
                    setShowModal(false)
                    closeDocumentModal()
                    setFieldsValue({})
                    apiRequest()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                });
            }
        });
    };
    const handleFileChange = (e, item) => {
        setShowModal(true)
        setAddDocumentModal(false)
        const file = e.target.files[0];
        if (file) {
            setImageSrc(prev => ({
                name: item?.name,
                file: file,
                appIdRequired: item?.appIdRequired,
            }));
        }
    };
    const handleCancel = () => {
        setShowModal(false);
        setImageSrc({})
    };
    const closeDocumentModal = () => {
        setAddDocumentModal(false);
    };
    const openDocumentModal = () => {
        setAddDocumentModal(true);
    };
    const inputSchema = [
        {
            key: "name",
            label: "Document Name",
            type: "text",
            span: "col-md-12",
            onChange: (value) => {
                setFieldsValue({"name": value});
            }
        },
        {
            key: "file",
            label: "Document",
            type: "file",
            span: "col-md-12",
            onChange: (files) => {
                if (files && files.length) {
                    setImageSrc({
                        "file": files[0],
                    });
                }
                setShowModal(true)
            }
        },
    ]
    const apiRequest = async (params) => {
        params = {applicationId, studentId}
        let {data} = await dispatch(latestDocumentListFxn(params));
        setDocumentList(data)
    };

    const getDocument = (type) => {
        let findDoc = _.find(documentList, (item) => {
            return item._id == type
        })
        if (findDoc && findDoc._id) {
            return findDoc.document
        } else {
            return null
        }
    }
    const getDocuments = () => {
        const existingTypes = new Set(documentTypes.map(item => item.name));
        const nonMatchingDocs = documentList.filter(item => !existingTypes.has(item._id));
        setOtherDoc(nonMatchingDocs)
        return nonMatchingDocs;
    };
    useEffect(() => {
        apiRequest()

    }, [])
    useEffect(() => {
        getDocuments()
    }, [documentList, documentTypes])
    return (
        <>
            <div>
                <div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5 className="card-action-title mb-0 fontsize-s">Student Documents</h5>
                        <a className="btn btn-primary btn-sm" onClick={openDocumentModal}>Add</a>
                    </div>
                    <hr className={'mb-2'}/>
                    <form encType="multipart/form-data">
                        {documentTypes.map((item, key) => {

                            const document = getDocument(item.name);

                            let lastIndex = documentTypes.length - 1;
                            let borderClass = lastIndex == key ? "" : " border-bottom border-1 border-light"
                            return (
                                <>
                                    <div
                                        className={`row student-document mb-1 ml-0 mr-0 border-bottom border-1 border-light`}>
                                        <div className="col-md-4">
                                            <div className="document-item text-primary d-flex align-items-center">
                                                <i className={item.icon}></i>
                                                <div className="left-document-section ml-1">
                                                    <div className="heading mt-1 text-dark">{item?.name}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            {document && document.createdAt ?
                                                <span className="badge bg-label-primary fontsize-xxs">
                                                   Last Updated : {displayDate(document?.createdAt)}
                                                </span>
                                                : null}
                                        </div>
                                        <div className="col-md-4 text-end">
                                            <div className="right-section text-end mb-2">
                                                {item.uploadEnable ?
                                                    <label htmlFor={`file-upload-${item.name}`}
                                                           className="btn rounded-pill btn-label-secondary mr-2 btn-xs">
                                                        <i className="bx bxs-cloud-upload opacity-75"/>
                                                        <input
                                                            id={`file-upload-${item.name}`}
                                                            type="file"
                                                            style={{display: 'none'}}
                                                            onChange={(e) => handleFileChange(e, item)}
                                                        />
                                                    </label> : null}
                                                {document && document.path ? (
                                                    <a className={'btn rounded-pill download-path btn-primary btn-xs'}
                                                       href={document.path} target={'_blank'}>
                                                        <i className={'bx bxs-file-pdf'}/>
                                                    </a>
                                                ) : <>
                                                    <label className="btn rounded-pill btn-label-secondary btn-xs">
                                                        <i className='bx bxs-file-pdf opacity-75'></i>
                                                    </label>
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                        {otherDoc && otherDoc.map((i, index) => {

                            return (
                                <>
                                    <div
                                        className={`row student-document mb-1 ml-0 mr-0 border-bottom border-1 border-light`}>
                                        <div className="col-md-4">
                                            <div className="document-item text-primary d-flex align-items-center">
                                                <i className='bx bxs-badge-check opacity-75'></i>
                                                <div className="left-document-section ml-1">
                                                    <div className="heading mt-1 text-dark">{i?._id}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            {i?.document && i?.document.createdAt ?
                                                <span className="badge bg-label-primary fontsize-xs">
                                                   Last Updated : {displayDate(i?.document?.createdAt)}
                                                </span>
                                                : null}
                                        </div>
                                        <div className="col-md-4 text-end">
                                            <div className="right-section text-end mb-2">

                                                {i.document && i.document.path ? (
                                                    <a className={'btn rounded-pill download-path btn-primary btn-xs'}
                                                       href={i.document.path} target={'_blank'}>
                                                        <i className={'bx bxs-file-pdf'}/>
                                                    </a>
                                                ) : <>
                                                    <label className="btn rounded-pill btn-label-secondary btn-xs">
                                                        <i className='bx bxs-file-pdf opacity-75'></i>
                                                    </label>
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </form>

                    <Modal
                        className="custom-ant-modal"
                        visible={showModal}
                        onClose={handleCancel}
                        width={'30%'}
                        placement='right'
                        title={(
                            <h5 className="card-action-title mb-0">
                                Are you sure you want to upload document ?
                            </h5>
                        )}>
                        <div className={'btn-box'}>
                            <button type="button" className="btn btn-label-danger btn-sm"
                                    onClick={handleCancel}
                            ><span>No</span></button>
                            {' '}
                            <button type="button" className="btn btn-label-primary btn-sm"
                                    onClick={handleSubmit}><span>Yes</span>
                            </button>
                        </div>
                    </Modal>
                    <Modal
                        className="custom-ant-modal"
                        visible={addDocumentModal}
                        onClose={closeDocumentModal}
                        width={'35%'}
                        placement='right'
                        title={(
                            <h5 className="card-action-title mb-0">
                                Upload Document
                            </h5>
                        )}
                    >
                        <div className={'row'}>
                            {inputSchema.map((item, key) => {
                                return !item.hidden ? (
                                    <div className={`${item.span ? item.span : "col-md-6"}`}
                                         key={key}>
                                        <GetEachFormFields
                                            getFieldDecorator={getFieldDecorator}
                                            getFieldError={getFieldError}
                                            {...props.form}
                                            item={item}
                                            keyAccessor={item.keyAccessor}
                                            valueAccessor={item.valueAccessor}
                                        />
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    )
}
export default Form.create()(Documents);
