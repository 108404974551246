import React, {useEffect, useState} from "react"
import 'react-phone-input-2/lib/style.css'
import {useDispatch} from "react-redux";
import {addMarketingManagerFxn} from "../action";
import {Card, Form, notification} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import _ from "lodash"
import {initRights} from "../../../components/_utils/_utils";

const initState = {
    contactPersonName: "",
    email: "",
    password: "",
    postName: "",
    joiningDate: "",
    mobile: "",
    address: "",
    countryCode: "",
    profilePic: null,
};


const AddMarketingManager = (props) => {
    let {form: {getFieldDecorator, getFieldError, setFieldsValue, getFieldValue}} = props
    const dispatch = useDispatch();
    const [state, setState] = useState(initState);
    const [rights, setRights] = useState(initRights);
    const events = {
        _updateState: (data) => {
            setState((prevState) => ({
                ...prevState,
                ...data,
            }));
        },
    };

    let inputTypes = [
        {
            key: 'userType',
            label: 'Type',
            type: 'select',
            required: true,
            options: [{value: 'marketingManager', name: "Marketing Manager"}, {value: 'team', name: "Team"}],
            keyAccessor: x => x.value,
            valueAccessor: x => `${x.name}`,
            onChange: x => {
                setFieldsValue({
                    userType: x,
                })
            }
        },
        {
            key: "name",
            label: "Name",
            placeholder: "Enter Name",
            required: true
        },
        {
            key: "mobile",
            type: "phone",
            label: "Phone no",
            required: true,
            placeholder: "Enter mobile no",
            span: 'col-md-6',
            onChange: (value) => {
                setFieldsValue({mobile: value});
                events._updateState({mobile: value});
            }
        },
        {
            key: "email",
            label: "Email",
            type: "email",
            span: "col-md-6",
            required: true,
            placeholder: "Enter Email",
        },
        {
            key: "password",
            label: "Password",
            type: "text",
            span: "col-md-6",
            required: true,
            placeholder: "Enter Password",
        },
        {
            key: "postName",
            label: "Post Name",
            type: "text",
            span: "col-md-6",
            placeholder: "Enter Post Name",
        },
        {
            key: "joiningDate",
            label: "Joining Date",
            type: "date",
            span: "col-md-6",
            placeholder: "Choose Joining Date",
            onChange: (value) => {
                setFieldsValue({"joiningDate": value});
            }
        },
        {
            key: "profilePic",
            label: "Profile Pic",
            type: "file",
            span: "col-md-6",
            placeholder: "Choose Joining Date",
            onChange: (files) => {
                if (files && files.length) {
                    setFieldsValue({"profilePic": files[0]});
                }
            }
        },
        {
            key: "address",
            label: "Address",
            type: "textarea",
            span: "col-md-12",
            placeholder: "Enter Address",
        },
    ]
    if (getFieldValue("userType") === 'team') {
        inputTypes.splice(1, 0, {
            key: "department",
            label: "Department",
            span: "col-md-6",
            placeholder: "Enter Department",
            required: true
        },)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const {form} = props
        form.validateFields(async (err, valData) => {
            // valData.userType= "marketingManager"
            if (!err) {
                let fd = new FormData();
                if (valData.userType === 'team')
                    valData = {...valData, rights}
                fd.append('obj', JSON.stringify(valData));
                if (valData.profilePic && valData.profilePic.name) {
                    fd.append('profilePic', valData.profilePic);
                }
                let resp = await dispatch(addMarketingManagerFxn(fd))
                if (resp && resp.success) {
                    setRights(initRights)
                    setFieldsValue({
                        userType: undefined,
                        name: "",
                        mobile: "",
                        email: "",
                        postName: "",
                        joiningDate: null,
                        profilePic: null,
                        address: "",
                        department: "",
                        password: "",
                    });
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        });
    };
    const handleRightChange = (e, idx) => {
        const {name, checked} = e.target;
        let updatedRight = {...rights[idx]};
        updatedRight = {...updatedRight, [name]: checked};
        rights.splice(idx, 1, updatedRight);
        setRights([...rights]);
    }
    return (
        <>
            <div className="row">
                <div className="col-xl">
                    <Card title={'Add User'}>
                        <h6 className={'form-title'}>Basic Details</h6>
                        <Form onSubmit={handleSubmit}>
                            <div className={'row'}>
                                {inputTypes.map((item, key) => {
                                    return !item.hidden ? (
                                        <div className={`${item.span ? item.span : "col-md-6"}`}
                                             key={key}>
                                            <GetEachFormFields
                                                getFieldDecorator={getFieldDecorator}
                                                getFieldError={getFieldError}
                                                {...props.form}
                                                item={item}
                                                keyAccessor={item.keyAccessor}
                                                valueAccessor={item.valueAccessor}
                                            />
                                        </div>
                                    ) : null;
                                })}
                            </div>
                            {getFieldValue("userType") === 'team' && (
                                <div className="card mb-6" style={{marginTop: "19px", maxWidth: "700px"}}>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead className="border-top">
                                            <tr>
                                                <th className="text-nowrap " id={"rightsTable"}>Type</th>
                                                <th className="text-nowrap text-center">ADD</th>
                                                <th className="text-nowrap text-center">EDIT</th>
                                                <th className="text-nowrap text-center">VIEW</th>
                                                <th className="text-nowrap text-center">GENERATE</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {rights.map((right, index) => (
                                                <tr key={index}>
                                                    <td className="text-nowrap text-heading">{_.capitalize(right.type)}</td>
                                                    <td>
                                                        <div className="form-check d-flex justify-content-center">
                                                            {right.add !== undefined && <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id={`defaultCheckAdd${index}`}
                                                                name={"add"}
                                                                checked={right.add}
                                                                onChange={(e) => handleRightChange(e, index)}
                                                            />}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check d-flex justify-content-center">
                                                            {right.edit !== undefined && <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id={`defaultCheckEdit${index}`}
                                                                name={"edit"}
                                                                checked={right.edit}
                                                                onChange={(e) => handleRightChange(e, index)}
                                                            />}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check d-flex justify-content-center">
                                                            {right.view !== undefined && <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id={`defaultCheckView${index}`}
                                                                name={"view"}
                                                                checked={right.view}
                                                                onChange={(e) => handleRightChange(e, index)}
                                                            />}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check d-flex justify-content-center">
                                                            {right.generate !== undefined &&
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id={`defaultCheckGenerate${index}`}
                                                                name={"generate"}
                                                                checked={right.generate}
                                                                onChange={(e) => handleRightChange(e, index)}
                                                            />}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>)}

                            <button type="submit" className="btn btn-primary mt-5">
                                Submit
                            </button>
                        </Form>
                    </Card>
                </div>
            </div>

        </>
    )
}
export default Form.create()(AddMarketingManager);

