import Login from "../containers/auths/login";
import Registration from "../containers/auths/signup";

export let authMenuData = [
    {
        name: "Login",
        path: "/login",
        key: "login",
        component: Login,
    },
    {
        name: "Register",
        path: "/register",
        key: "register",
        component: Registration,
    }
]
