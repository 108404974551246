import {apiUrl} from "../../../settings"

export const addCourseUrl = () => {
    return apiUrl + '/course/addCourse'
}
export const courseListUrl = () => {
    return apiUrl + '/course/getAllCourse'
}
export const singleCourseUrl = () => {
    return apiUrl + '/course/getSingleCourse'
}
export const updateIntakeCourseUrl = () => {
    return apiUrl + '/course/addIntake'
}
export const updateCourseUrl = () => {
    return apiUrl + '/course/updateCourse'
}
export const updateIntakeUrl = () => {
    return apiUrl + '/course/updateIntake'
}

export const intakeListUrl = () => {
    return apiUrl + '/course/intake/list'
}
export const deleteIntakeUrl = () => {
    return apiUrl + '/course/deleteIntake'
}

export const addIntakeToCourseUrl = () => {
    return apiUrl + '/course/addIntakeToCourse'
}
