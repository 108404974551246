import React from "react"
import {universityId} from "../../settings";
import {universityLayouts} from "../../components/_utils/directUniversities";

const Layout = React.lazy(() => import('./layouts').then(module => {
        return ({
            default: module[universityLayouts[universityId]]
        })
    })
)
export default Layout
