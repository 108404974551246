import {apiUrl} from "../../../settings";

export const addAgentUrl = () => {
    return apiUrl + '/api/agent'
}

export const getAgentsUrl = () => {
    return apiUrl + '/api/getAgent'
}

export const updateAgentUrl = () => {
    return apiUrl + '/api/updateAgent'
}
export const changePasswordUserUrl = () => {
    return apiUrl + '/changePasswordUser'
}
