import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import "../../assests/backApp/css/style.css"
import "../../assests/backApp/vendor/fonts/boxicons.css"
import "../../assests/backApp/vendor/css/core.css"
import "../../assests/backApp/vendor/css/theme-default.css"
import "../../assests/backApp/css/demo.css"
import "../../assests/backApp/css/spinkit.css"
import {Helmet} from "react-helmet";

const $ = window.$;
const TopMenu = (props) => {
    let dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {
    }, []);


    const menuItems = [
        {
            name: "Edit Profile",
            icon: "fa-user",
        },
        {
            name: "Change Password",
            icon: "fa-key",
        },
        {
            name: "Feedback",
            icon: "fa-user",
        },
        {
            name: "Request for Training",
            icon: "fa-user",
        },
        {
            name: "Logout",
            icon: "fa-sign-out-alt",
        },
    ];
    const logoutFxn = () => {
        localStorage.clear();
        setTimeout(() => {
            dispatch({
                type: "SET_CURRENT_USER",
                user: {},
            });
            window.location.href = "/login";
        }, 100);
    };

    const menuEvents = (eventName) => {
        if (eventName == "Logout") {
            logoutFxn()
        } else if (eventName == 'Edit Profile') {
            navigate(`/edit-my-profile`);

        } else if (eventName == 'Change Password') {
            navigate('/change-password')
        } else if (eventName == 'Feedback') {
            navigate('/add-feedback')
        } else if (eventName == 'Request for Training') {
            navigate('/training-request')
        }
    }

    return (
        <>
            <Helmet>
                <link
                    href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;display=swap"
                    rel="stylesheet"/>

            </Helmet>
        </>
    );
};
export default TopMenu;
