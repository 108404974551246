import React, {useRef, useState} from 'react';
import { Table } from '../../components/Elements/appUtils';
import { useDispatch } from "react-redux";
import { appInvoiceListFxn } from "../../containers/student/actions";

import {displayDate} from "../../components/Elements/appUtils";
import RegenerateInvoiceDrawer from "../../containers/Invoices/drawer/regenerateInvoice";
import InvoicePaymentDrawer from "../../containers/Invoices/drawer/InvoicePayment";

const Invoice = (props) => {
    const [total, setTotal] = useState(0);
    const [visible, setVisible] = useState(false);
    const [regenerateVisible, setRegenerateVisible] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [payNowState, setPayNowState] = useState({
        invoiceId: '',
        studentId: '',
        visible: false
    });
    const tableRef = useRef()
    const dispatch = useDispatch();

    const events = {
        showPayNowDrawer: async (record) => {
            setPayNowState({
                invoiceId: record.invoices._id,
                studentId: record.studentId,
                visible: true
            });
            await apiRequest();
        },
        hidePayNowDrawer: async () => {
            setPayNowState({
                invoiceId: '',
                studentId: '',
                visible: false
            });
            tableRef.current.reload()
        },
    };
    const columns = [
        {
            title: 'Sr. No.',
            key: 'serialNumber',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Invoice Date',
            dataIndex: ['invoices', 'invoiceDate'],
            key: 'invoiceDate',
            render: (invoiceDate) => displayDate(invoiceDate),
        },
        {
            title: 'Due Date',
            dataIndex: ['invoices', 'dueDate'],
            key: 'dueDate',
            render: (dueDate) => displayDate(dueDate),
        },
        {
            title: 'Invoice Amount',
            dataIndex: ['invoices', 'invoiceAmount'],
            key: 'invoiceAmount'
        },
        {
            title: 'Remaining Balance',
            dataIndex: ['invoices', 'remainingBalance'],
            key: 'remainingBalance'
        },
        {
            title: 'Total Owing',
            dataIndex: ['invoices', 'totalOwing'],
            key: 'totalOwing'
        },
        {
            title: 'Total Owing Net',
            dataIndex: ['invoices', 'totalOwingNet'],
            key: 'totalOwingNet'
        },

        {
            title: 'Invoice',
            dataIndex: ['invoices', 'invoice', 'path'],
            key: 'invoicePath',
            render: (path, record) => (
                <>
                    <a className={'btn rounded-pill btn-primary'} href={path} target={'_blank'}>
                        <i className={'bx bxs-file-pdf'}/>
                    </a>
                    <div className={'mt-2'}>
                        <a
                            className={'btn rounded-pill btn-outline-primary btn-xs'}
                            onClick={() => handleRegenerate(record)}
                        >
                            Re-Generate
                        </a>
                    </div>
                </>
            )
        },
        {
            title: "Status", key: "action",
            render: (text, record) => (
                <>
                    {record.invoices.paymentStatus !== 'Paid' ? (
                        <div className={'mt-2'}>
                            <a className={'btn rounded-pill btn-outline-primary btn-xs'} onClick={() => events.showPayNowDrawer(record)}>Pay Now</a>
                        </div>
                    ) : (
                        <div className={'mt-2'}>
                            <label className="badge bg-label-primary"> Paid </label>
                        </div>
                    )}
                </>
            ),
        },
        {
            title: "Receipt", key: "action",
            render: (text, record) => (
                <>
                    {record.invoices.paymentStatus == 'Paid' && (
                        <div className={'mt-2'}>
                            <a className={'btn rounded-pill btn-outline-primary btn-xs'}
                               href={record.invoices.receipt.path} target={'_blank'}>
                                <i className={'bx bxs-file-pdf'}/> Receipt
                            </a>
                        </div>
                    )
                    }
                </>
            )

        }
    ];

    const handleRegenerate = (invoice) => {
        setSelectedInvoice(invoice);
        setRegenerateVisible(true);
    };

    const apiRequest = (params = {}) => {

        return new Promise(async (resolve) => {
            params.applicationId = props.applicationId;
            let resp = await appInvoiceListFxn(params);
            setTotal(resp.total);
            resolve(resp);
        });
    };


    const handleClose = () => {
        setVisible(false);
        setRegenerateVisible(false);
        setSelectedInvoice(null);
        apiRequest()
    };

    return (
        <>
            <Table apiRequest={apiRequest} columns={columns} bordered showView={false} showPagination={false} ref={tableRef}/>


            {regenerateVisible &&
            <RegenerateInvoiceDrawer
                visible={regenerateVisible}
                onClose={handleClose}
                applicationId={props.applicationId}
                existingInvoice={selectedInvoice.invoices}
            />
            }
            {payNowState.visible ? (
                <InvoicePaymentDrawer
                    {...payNowState}
                    invoiceId={payNowState.invoiceId}
                    studentId={payNowState.studentId}
                    onClose={() => events.hidePayNowDrawer()}
                    visible={payNowState.visible}
                    applicationId={props.applicationId}
                />
            ) : null}
        </>
    );
};

export default Invoice;
