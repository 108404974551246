import React, {Suspense, useEffect, useRef, useState} from "react";
import "react-phone-number-input/style.css";
import "react-phone-input-2/lib/style.css";
import {useDispatch} from "react-redux";
import {addCourseFxn, courseListFxn} from "../action";
import {Card, displayDate, Form, notification, Table} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import IntakeDrawer from "../../intake/intakeDrawer";
import {academicStatusList, conditionOptions, programLevel, typeOfProgramList} from "../../../components/_utils/_utils";
import {CKEditor} from 'ckeditor4-react';

let {FormTitle} = Card;

    const initState = {
        courseName: "",
        campusName: "",
        courseDuration: "",
        courseCategory: "",
        tuitionFee: '',
        payable: "",
        additionalRequirement: "",
        scholarship: "",
        courseCode: "",
        levelOfStudy: "",
        hoursOfInstructionPerWeek: "",
        hoursOfInstructionPerContract: "",
        programDuration: "",
        applicationFee: "",
        materialFee: "",
        studentArchivingFee:"",
        courseContent: `<table class="table table-bordered">
                        <thead>
                            <tr class="table-bg">
                                <th>Course</th>
                                <th>Method(s) of Evaluation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Fundamentals 1</td>
                                <td>Written examination</td>
                            </tr>
                            <tr>
                                <td>Essential Business</td>
                                <td>Interview Skills Assessment</td>
                            </tr>
                            <tr>
                                <td>Hospitality Fundamentals</td>
                                <td>Assignment (Worksheet) Submission & Written examination</td>
                            </tr>
                            <tr>
                                <td>(Pre-recorded) Advanced Communications</td>
                                <td>Assignment (Worksheet) Submission</td>
                            </tr>
                        </tbody>
                    </table>`,
        courseTable: `  <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th style="width: 40%; align-content: center;">Title of Course</th>
                        <th># of Hours of Instruction</th>
                        <th>Delivery Method</th>
                        <th>Distance/Combined Delivery Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Fundamentals 1</td>
                        <td>80</td>
                        <td>In-class</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Essential Business</td>
                        <td>120</td>
                        <td>In-class</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Hospitality Fundamentals + Co-op placement skills & preparation</td>
                        <td>120</td>
                        <td>Combined</td>
                        <td>Asynchronous</td>
                    </tr>
                    <tr>
                        <td>(Pre-recorded) Advanced Communications</td>
                        <td>60</td>
                        <td>Distance</td>
                        <td>Asynchronous</td>
                    </tr>
                    <tr>
                        <td>Co-op Work Experience</td>
                        <td>320</td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>`,
        programCosts: `<table class="table table-bordered">
        <tbody>
        <tr>
            <td>Total tuition payable during contract term</td>
            <td>$10395</td>
        </tr>
        <tr>
            <td>Application fee</td>
            <td>$200</td>
        </tr>
        <tr>
            <td>Assessment fee</td>
            <td>$</td>
        </tr>
        <tr>
            <td>Administrative fee</td>
            <td>$</td>
        </tr>
        <tr>
            <td>Student Record Archiving Fee</td>
            <td>$4.30</td>
        </tr>
        <tr>
            <td>Fees for textbooks or other course materials, including equipment and uniforms</td>
            <td>$400</td>
        </tr>
        <tr>
            <td>Other mandatory fees (fees students must pay that are <u>not</u> in relation to an approved program)</td>
            <td>$</td>
        </tr>
        </tbody>
    </table>`
    };




const AddCourse = (props) => {
    let tableRef = useRef()
    let {form: {getFieldDecorator, getFieldError, setFieldsValue, getFieldValue}} = props
    const dispatch = useDispatch()
    const [state, setState] = useState(initState);
    const [pathwayVal, setPathwayVal] = useState();
    const [intakeList, setIntakeList] = useState([]);
    let [intakeState, setIntakeState] = useState({
        visible: false,
        // pathway: false
    })

    const events = {
        _updateState: (data) => {
            setState((prevState) => ({
                ...prevState,
                ...data,
            }));
        },
        showIntakeDrawer: async () => {
            setIntakeState({
                visible: true,
                // pathway: true,
            })
        },
        hideIntakeDrawer: async () => {
            setIntakeState({
                visible: false,
                // pathway: false,
            })
        },
        addIntake: (data) => {
            setIntakeList((prevIntake) => {
                return [
                    ...prevIntake,
                    data
                ]
            })
            events.hideIntakeDrawer()
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        let {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.intakeList = intakeList;
                valData.courseContent = state.courseContent;
                valData.courseTable = state.courseTable;
                valData.programCosts = state.programCosts;
                console.log(valData, "valdata")
                let resp = await dispatch(addCourseFxn(valData));
                if (resp && resp.success) {
                    form.resetFields()
                    form.setFieldsValue(initState)
                    setState(initState)
                    setIntakeList([])
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        });
    };

    useEffect(() => {

            setFieldsValue({ courseContent: state.courseContent });
            setFieldsValue({ courseTable: state.courseTable })
            setFieldsValue({ programCosts: state.programCosts })

    }, []);
    let inputTypes = [
        {
            key: "courseName",
            label: "Course Name",
            span: "col-md-6",
            placeholder: "Enter Course Name",
            required: true,

        },
        {
            key: "campusName",
            label: "Campus Name",
            type: "text",
            span: "col-md-6",
            placeholder: "Enter Campus Name",
        },
        {
            key: "courseDuration",
            label: "Course Duration",
            type: "text",
            span: "col-md-4",
            placeholder: "Enter Course Duration",
            required: true
        },
        {
            key: "courseLevel",
            label: "Course Level",
            type: "select",
            span: "col-md-4",
            placeholder: "Enter Course Level",
            options: programLevel,
            onChange: (date) => {
                setFieldsValue({courseLevel: date})
            },
            required: true

        },
        {
            key: "courseCategory",
            label: "Course Category",
            type: "text",
            span: "col-md-4",
            placeholder: "Enter Course Category",
        },
        {
            key: "tuitionFee",
            label: "Tuition Fees",
            type: "number",
            span: "col-md-4",
            placeholder: "Enter Tuition Fees",
            required: true
        },
        {
            key: "payable",
            label: "Payable",
            type: "text",
            span: "col-md-4",
            placeholder: "Enter Payable",
        },
        {
            key: "additionalRequirement",
            label: "Additional Requirements",
            type: "text",
            span: "col-md-4",
            placeholder: "Additional Requirements",
        },
        {
            key: "waiver",
            label: "Waiver",
            type: "select",
            required: true,
            placeholder: "Select waiver",
            span: 'col-md-4',
            options: conditionOptions,
            keyAccessor: x => x.name,
            valueAccessor: x => `${x.name}`,
            onChange: (date) => {
                setFieldsValue({waiver: date})
            }
        },
        {
            key: "scholarship",
            label: "Scholarship",
            type: "text",
            span: "col-md-4",
            placeholder: "Scholarship",
        },
        {
            key: "courseCode",
            label: "Course Code",
            type: "text",
            required: true,
            span: "col-md-4",
            placeholder: "Course Code",
            onChange: (data) => {
                setFieldsValue({courseCode: data})
            }
        },
        {
            key: "levelOfStudy",
            label: "Level Of Study",
            type: "text",
            span: "col-md-4",
            placeholder: "Level Of Study",
        },
        {
            key: "typeOfProgram",
            label: "Type Of Program",
            type: "select",
            span: "col-md-4",
            placeholder: "Type Of Program",
            options: typeOfProgramList,
            keyAccessor: x => x.value,
            valueAccessor: x => `${x.name}`,
            onChange: (date) => {
                setFieldsValue({typeOfProgram: date})
            },
            required: true

        },

        {
            key: "academicStatus",
            label: "Academic Status",
            type: "select",
            span: "col-md-4",
            placeholder: "Academic Status",
            options: academicStatusList,
            keyAccessor: x => x.value,
            valueAccessor: x => `${x.name}`,
            onChange: (date) => {
                setFieldsValue({academicStatus: date})
            }
        },
        {
            key: "hoursOfInstructionPerWeek",
            label: "Hours Of Instruction Per Week",
            type: "text",
            span: "col-md-4",
            placeholder: "Hours Of Instruction Per Week",
        },
        {
            key: "hoursOfInstructionContract",
            label: "Hours Of Instruction During Contract",
            type: "text",
            span: "col-md-4",
            placeholder: "Hours Of Instruction During Contract",
        },
        {
            key: "programDuration",
            label: "Program Duration",
            type: "text",
            span: "col-md-4",
            placeholder: "Program Duration",
        },
        {
            key: "applicationFee",
            label: "Application Fee",
            type: "text",
            span: "col-md-4",
            placeholder: "Application Fee",
        },
        {
            key: "materialFee",
            label: "Material Fee",
            type: "text",
            span: "col-md-4",
            placeholder: "Material Fee",
        },
        {
            key: "studentArchivingFee",
            label: "Student Archiving Fee",
            type: "text",
            span: "col-md-4",
            placeholder: "Student Archiving Fee",
        },
        {
            key: "pathway",
            label: "Pathway",
            type: "select",
            span: "col-md-4",
            options: conditionOptions,
            keyAccessor: x => x.value,
            valueAccessor: x => `${x.name}`,
            onChange: (date) => {
                setPathwayVal(date)
                setFieldsValue({pathway: date})
            }
        },
        // {
        //     key: "courseContent",
        //     label: "Course Content",
        //     span: "col-md-12",
        //     render: () => (
        //         <CKEditor
        //             data={getFieldValue('courseContent')}
        //             config={{ height: 500 }}
        //             onChange={(event) => {
        //                 const data = event.editor.getData();
        //                 setState((prevState) => ({
        //                     ...prevState,
        //                     courseContent: data,
        //                 }));
        //                 setFieldsValue({ courseContent: data }); // Update the form value
        //             }}
        //         />
        //     )
        // }
    ]
    const columns = [
        {
            title: 'Sr. No.',
            key: 'serialNumber',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Month',
            dataIndex: 'month',
            key: 'month',
        },
        {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (data) => {
                return data ? displayDate(data) : null
            }
        },

        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (data) => {
                return data ? displayDate(data) : null
            }
        },
        {
            title: '2nd Year StartDate',
            dataIndex: 'secondYearStartDate',
            key: 'secondYearStartDate',
            render: (data) => {
                return data ? displayDate(data) : null
            }
        },
        {
            title: '2nd Year endDate',
            dataIndex: 'secondYearEndDate',
            key: 'secondYearEndDate',
            render: (data) => {
                return data ? displayDate(data) : null
            }
        },


    ];


    return (
        <div>
            <div className="row">
                <div className="col-xl">
                    <Form onSubmit={handleSubmit}>
                        <Card title={'Add Course'}>
                            <FormTitle name={'Course Details'}/>

                            <div className="row">
                                {inputTypes.map((item, key) => (
                                    <div className={`${item.span ? item.span : "col-md-6"}`} key={key}>
                                        <GetEachFormFields
                                            getFieldDecorator={getFieldDecorator}
                                            getFieldError={getFieldError}
                                            {...props.form}
                                            item={item}
                                        />
                                    </div>
                                ))}
                            </div>
                            <FormTitle name={'Course Content'} />
                            <CKEditor
                                initData={getFieldValue('courseContent') || state.courseContent}
                                onChange={(event) => {
                                    const data = event.editor.getData();
                                    setState((prevState) => ({
                                        ...prevState,
                                        courseContent: data,
                                    }));
                                    setFieldsValue({ courseContent: data });
                                }}
                            />
                            <hr className="my-6 mx-n6"/>
                            <FormTitle name={'Course Table'} />
                            <CKEditor
                                initData={getFieldValue('courseTable') || state.courseTable}
                                onChange={(event) => {
                                    const data = event.editor.getData();
                                    const cleanedData = data.replace(/&nbsp;/g, '');
                                    setState((prevState) => ({
                                        ...prevState,
                                        courseTable: cleanedData,
                                    }));
                                    setFieldsValue({ courseTable: cleanedData });
                                }}
                            />
                            <hr className="my-6 mx-n6"/>
                            <FormTitle name={'Program Costs'} />
                            <CKEditor
                                initData={getFieldValue('programCosts') || state.programCosts}
                                onChange={(event) => {
                                    const data = event.editor.getData();
                                    const cleanedData = data.replace(/&nbsp;/g, '');
                                    setState((prevState) => ({
                                        ...prevState,
                                        programCosts: cleanedData,
                                    }));
                                    setFieldsValue({ programCosts: cleanedData });
                                }}
                            />

                            <hr className="my-6 mx-n6"/>

                            <FormTitle name={'Intakes'} btn={(
                                <a className="btn btn-label-primary" onClick={() => {
                                    events.showIntakeDrawer()
                                }}>Manage
                                </a>
                            )}/>
                            <Table dataSource={intakeList} columns={columns} bordered ref={tableRef}/>
                            <button type="submit" className="btn btn-primary mt-5">
                                Submit
                            </button>
                        </Card>
                    </Form>


                </div>
            </div>

            {intakeState.visible ?
                <IntakeDrawer
                    {...intakeState}
                    pathway={pathwayVal}
                    onClose={() => {
                        events.hideIntakeDrawer()
                    }}
                    onSubmit={events.addIntake}
                /> : null}

        </div>
    );
};
export default Form.create()(AddCourse);
