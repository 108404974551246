import React, {useState} from 'react';
import {Form, Button, notification} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {Card} from "../../components/Elements/appUtils";
import {useDispatch} from "react-redux";
import {changePasswordUserFxn} from "../auths/actions";

const ChangePassword = (props) => {
    const {form: {getFieldDecorator, validateFields, getFieldError, getFieldValue, setFieldsValue}} = props;
    let dispatch = useDispatch()
    const inputTypes = [
        {
            key: "currentPassword",
            label: "Current Password",
            type: "password",
            required: true,
            placeholder: "Enter Current Password",
        },
        {
            key: "password",
            label: "New Password",
            type: "password",
            required: true,
            placeholder: "Enter New Password",
        },
        {
            key: "confirmPassword",
            label: "Confirm Password",
            type: "password",
            required: true,
            placeholder: "Confirm New Password",
        }
    ];

    const handleSubmit = e => {
        e.preventDefault();
        validateFields(async (err, values) => {
            if (!err) {
                if (!values.currentPassword) {
                    notification.warning({
                        message: 'Enter your current password.'
                    });
                    return
                }
                if (!values.password) {
                    notification.warning({
                        message: 'Enter your new password.'
                    });
                    return
                }
                if (values.password.length < 6) {
                    notification.warning({
                        message: 'Please enter minimum 6 digit password.'
                    });
                    return
                }
                if (values.password !== values.confirmPassword) {
                    notification.error({
                        message: 'Error',
                        description: 'New password and confirm password do not match!',
                    });
                    return
                }
                let resp = await dispatch(changePasswordUserFxn({
                    password: values.password,
                    currentPassword: values.currentPassword
                }));
                if (resp && resp.success) {
                    setFieldsValue({password: "", confirmPassword: "", currentPassword: ""});
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                });
            }
        });
    };

    return (
        <Card title="Change Password">
            <Form onSubmit={handleSubmit} className="change-password-form">
                <div className={'row'}>
                    <div className={'col-md-4'}>
                        {inputTypes.map((item, key) => (
                            <div key={key}>
                                <GetEachFormFields
                                    getFieldDecorator={getFieldDecorator}
                                    getFieldError={getFieldError}
                                    item={item}
                                    keyAccessor={item.keyAccessor}
                                    valueAccessor={item.valueAccessor}
                                    onChange={item.onChange}
                                />
                            </div>
                        ))}
                        <div className={'mt-5'}>
                            <Button type="primary" htmlType="submit">
                                Change Password
                            </Button>
                        </div>

                    </div>
                </div>

            </Form>
        </Card>
    );
};

export default Form.create()(ChangePassword);
