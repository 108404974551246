import React, {useRef, useState} from 'react';
import {Table, Card, displayDate, Avatar} from '../../components/Elements/appUtils'
import {applicationListFxn} from "./actions";
import {useDispatch} from "react-redux";
import GenerateInvoiceDrawer from "../Invoices/add";

let {TableCard} = Card;
const AccountsApplicationList = (props) => {
    let {currentUser} = props;
    const tableRef = useRef();
    const dispatch = useDispatch();
    const [total, setTotal] = useState(0)
    const [invoiceDrawer, setInvoiceDrawer] = useState({
        applicationId: '',
        visible: false
    })
    const events = {
        showInvoiceDrawer: (applicationId) => {
            setInvoiceDrawer({
                applicationId: applicationId,
                visible: true
            })
        },
        hideInvoiceDrawer: () => {
            setInvoiceDrawer({
                applicationId: '',
                visible: false
            })
            tableRef.current.reload()
        },
    }
    const columns = [
        {
            title: 'Sr. No.',
            key: 'serialNumber',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'firstName',
            render: (name, record) => {
                return (
                    <>
                        <div className="d-flex justify-content-start align-items-center user-name">
                            <Avatar name={name} size={'sm'}/>
                            <div className="d-flex flex-column">
                                <span className={'align-left'}> {name} {record.lastName ? record.lastName : ""}</span>
                                <div className="student_icons">
                                    <a>
                                        <i className="bx bx-comment-dots bx-sm text-primary"/>
                                    </a>
                                    <a>
                                        <i className="bx bx-history bx-sm text-danger"/>
                                    </a>
                                    <a>
                                        <i className="bx bx-copy bx-sm text-secondary"/>
                                    </a>

                                    {/*<a className="badge badge-center bg-label-info rounded-pill"></a>*/}
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        },
        {
            title: 'Student Id', dataIndex: 'studentIdNo',
            key: 'studentIdNo'
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
        },


        {
            title: 'Course',
            dataIndex: 'courseName',
            key: 'courseName',
        },
        {
            title: 'Intake',
            dataIndex: 'intake',
            key: 'intake',
            render: (item) => {
                return (
                    <>
                        {item && item.month && item.year
                            ? `${item.month}, ${item.year}`
                            : ''}
                    </>
                )
            }
        },
        {
            title: 'ESL',
            dataIndex: 'isEsl',
            key: 'isEsl',
            render: (isEsl) => isEsl ? 'Yes' : 'No',
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (startDate) => displayDate(startDate),
        },
        {
            title: 'Completion Date',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (completionDate) => displayDate(completionDate),
        },
        {
            title: 'Expiration Date',
            dataIndex: 'expirationDate',
            key: 'expirationDate',
            render: (expirationDate) => displayDate(expirationDate),
        },
        {
            title: 'Issue Date',
            dataIndex: 'issueDate',
            key: 'issueDate',
            render: (issueDate) => displayDate(issueDate),
        },
        {
            title: 'Marketing Manager',
            dataIndex: 'marketingManagerName',
            key: 'marketingManagerName',
        },
        {
            title: "Added on", dataIndex: "createdAt", key: "createdAt",
            render: (item) => {
                return item ? displayDate(item) : null
            }
        },
        {
            title: 'Invoice', dataIndex: 'invoice', key: 'invoice', render: (data, record) => {
                return (
                    <>
                        {data && data.path ?
                            <a className={'btn rounded-pill btn-primary'} href={data.path} target={'_blank'}>
                                <i className={'bx bxs-file-pdf'}/>
                            </a> :
                            <a className={'btn rounded-pill btn-label-secondary'}>
                                <i className={'bx bxs-file-pdf opacity-75'}/>
                            </a>}

                        {currentUser.userType == "university" ?
                            <div className={'mt-2'}>
                                <a className={'btn rounded-pill btn-outline-primary btn-xs'}
                                   onClick={() => events.showInvoiceDrawer(record._id)}>Generate</a>
                            </div>
                            : null}
                    </>
                )
            }
        },
    ];


    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.invoiceId = true
            let resp = await dispatch(applicationListFxn(params));
            setTotal(resp.total)
            resolve(resp)
        })
    }

    let initialColumns = ['serialNumber', 'companyName', 'firstName', 'intake', 'startDate', 'endDate', 'issueDate', 'courseName', 'invoice', 'createdAt', 'studentIdNo']
    return (
        <>
            <TableCard title={`Account Applications (Total :${total})`}>
                <Table
                    initialColumns={initialColumns}
                    apiRequest={apiRequest}
                    ref={tableRef}
                    columns={columns} bordered/>
            </TableCard>
            {invoiceDrawer.visible ? (
                <GenerateInvoiceDrawer
                    {...invoiceDrawer}
                    applicationId={invoiceDrawer.applicationId}
                    onClose={() => events.hideInvoiceDrawer()}
                    visible={invoiceDrawer.visible}
                />
            ) : null}
        </>
    );
};

export default AccountsApplicationList;
