import React, {Suspense, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {Avatar, displayDate, Form, notification} from "../../../components/Elements/appUtils";
import {addNoteFxn, noteListFxn} from "../actions";
import {GetEachFormFields} from "../../../components/_utils/formUtils";

const initState = {
    note: ""
}
const Note = (props) => {
    let {form: {getFieldDecorator, getFieldError}} = props
    let {applicationId, studentId} = props
    const [noteList, setNoteList] = useState([]);

    const dispatch = useDispatch()
    const inputSchema = [
        {
            title: "Add Note",
            key: "note",
            required: true,
            label: "Add Note",
            placeholder: "Enter note here.......",
            type: 'textarea',
            span: "col-md-12",
        }
    ]
    const handleSubmit = async (e) => {
        e.preventDefault();
        const {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.applicationId = applicationId
                valData.studentId = studentId
                let resp = await dispatch(addNoteFxn(valData));
                if (resp && resp.success) {
                    form.setFieldsValue(initState)
                    await apiRequest()
                }
            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };


    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let params = {applicationId: applicationId}
            let {data} = await noteListFxn(params);

            setNoteList(data.data)
        });
    };
    useEffect(() => {
        apiRequest()
    }, [applicationId])

    return (

        <>
            <div className="row g-6">
                <div className="col-xl-12 col-lg-6 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">

                                    {inputSchema.map((field, key) => (

                                        <div
                                            className={`${field.span ? field.span : "col-md-6"}`}
                                            key={key}
                                        >
                                            <GetEachFormFields
                                                getFieldDecorator={getFieldDecorator}
                                                getFieldError={getFieldError}
                                                {...props.form}
                                                item={field}
                                                keyAccessor={field.keyAccessor}
                                                valueAccessor={field.valueAccessor}
                                            />
                                        </div>
                                    ))}


                                </div>

                                <button type="submit" className="btn btn-primary">Add Note</button>
                            </form>


                        </div>
                    </div>
                </div>

                <div className="col-xl-12 col-lg-6 col-md-6">
                    {noteList && noteList.map((item) => {
                        let {addedBy} = item

                        return (
                            <>
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="d-flex justify-content-between align-items-center mb-1">
                                                <div className="d-flex justify-content-between flex-wrap gap-2 mb-2">
                                                    <div className="d-flex flex-wrap align-items-center mb-50 lh-1">
                                                        <div className="avatar avatar-sm me-3">
                                                            <Avatar name={addedBy.name} size={'sm'}/>
                                                        </div>
                                                        <div>
                                                            <p className="mb-0 small fw-medium text-capitalize">{addedBy.name} ({addedBy.userType})</p>
                                                            {addedBy && addedBy.email ?
                                                                <small className={'text-body'}>
                                                                    {addedBy.email}</small> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span
                                                        className="badge bg-label-primary rounded-pill">Added on - {displayDate(item.createdAt)}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-between align-items-end mt-1">
                                            <div className="role-heading">
                                                <p className="note-section lh-12 fontsize-xs">{item.note}</p>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </>
                        )
                    })}


                </div>
            </div>
        </>
    )
}
export default Form.create()(Note);
