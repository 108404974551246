import React, {useEffect, useState} from 'react';
import {Table, Card, displayDate} from '../../components/Elements/appUtils'
import {useDispatch} from "react-redux";
import {courseListFxn, intakeListFxn} from "./action";

let {TableCard} = Card;
const ApplicationIntakeList = () => {
    const dispatch = useDispatch();
    const [total, setTotal] = useState(0)
    const [courseList ,setCourseList] = useState([]);
    const listEvents = {
        loadCourseList: async () => {
            try {
                let params = {
                    count: 50,
                    results: 50
                }
                const {data} = await courseListFxn({params});
                setCourseList(data.data);
            } catch (error) {
                console.error("Failed to load course list:", error);
            }
        },
    }
    useEffect(()=>{
        listEvents.loadCourseList();
    },[])
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const filterInput = [

        {
            key: "active",
            label: " Status",
            span: "col-md-3",
            type: "select",
            options:
                ['open','close']
            ,
        },
        {
            key: "courseId",
            label: "Course Name",
            span: "col-md-3",
            type: "select",
            options: courseList.map((course) => ({
                label: course.courseName,
                value: course._id,
            })),
            keyAccessor: (option) => option.value,
            valueAccessor: (option) => option.label,
        },
        {
            key: "intake",
            label: "Intake",
            span: "col-md-3",
            type: "select",
            options: months,
        }
    ];
    let columns = [
        {title: "Sr. No.", key: "serialNumber", render: (text, record, index) => index + 1,},
        {
            key: 'courseName',
            title: 'Program',
            dataIndex: 'courseName'
        },
        {
            key: 'intakeList',
            title: 'intakeList',
            dataIndex: 'intakeList',
            render: (intakeList, record) => {
                return (
                    <>
                        {intakeList.month ? intakeList.month : ''}
                        {intakeList.year ? `, ${intakeList.year}` : ''}
                    </>
                )
            }
        },
        {
            key: 'status',
            title: 'status',
            dataIndex: 'status',
            render: (item, record) => {
                let {intakeList} = record;
                return (
                    <div>
                        {intakeList.active ? <label className="badge bg-label-success"> Opened </label> :
                            <label className="badge bg-label-danger"> Closed </label>}
                    </div>
                )
            }
        },

    ]


    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            if(!params.active ||params.active==='open')
                params.active = true;

            if(params.active==='close')
                params.active=false;


            let resp = await dispatch(intakeListFxn(params));
            setTotal(resp.total)
            resolve(resp)
        })
    }


    return (
        <>
            <TableCard title={`Intake List`} >
                <Table
                    filterFields={filterInput}
                    apiRequest={apiRequest}
                    columns={columns}
                    bordered
                />
            </TableCard>
        </>
    );
};

export default ApplicationIntakeList;
