import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import CountryJson from '../../assests/jsonFiles/countries.json';
import StatesJson from "../../assests/jsonFiles/states.json";
import {Card, Form, notification} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import _ from "lodash";
import {addStudentFxn, singleStudentDetailFxn, updateStudentDetailFxn} from "./actions";
import moment from "moment";
import {conditionOptions} from "../../components/_utils/_utils";
import {useParams} from "react-router-dom";



const EditStudent = (props) => {
    const {
        form: {getFieldDecorator, getFieldError, setFieldsValue , getFieldValue}, currentUser: user
    } = props;
    const dispatch = useDispatch();
    const [allStates, setAllStates] = useState([]);
    const [reload, setReload] = useState(false);
    let [intake, setIntake] = useState([])
    const params = useParams();
    const listEvents = {

        initialDataLoad : async () => {
            try {
                let { data } = await dispatch(singleStudentDetailFxn({ _id: params.id }));
                console.log('data', data);
                 setFieldsValue({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    dateOfBirth: moment(data.dateOfBirth).format('DD/MM/YYYY'),
                    email: data.email,
                    nationality: data.nationality,
                    studentType: data.studentType,
                    mobile: data.mobile,
                    address: data.address,
                    countryName: data.countryName,
                    stateName: data.stateName,
                    cityName: data.cityName,
                    postalCode: data.postalCode,
                    'education.qualification': data.education?.qualification,

                    'englishRequirement.examType': data.englishRequirement?.examType,
                    'englishRequirement.english12ThMarks': data.englishRequirement?.english12ThMarks,
                    'englishRequirement.examinationDate': data.englishRequirement?moment(data.englishRequirement.examinationDate):"",
                    'englishRequirement.overall': data.englishRequirement?.overall,
                    'englishRequirement.listening': data.englishRequirement?.listening,
                    'englishRequirement.reading': data.englishRequirement?.reading,
                    'englishRequirement.writing': data.englishRequirement?.writing,
                    'englishRequirement.speaking': data.englishRequirement?.speaking,
                });
                 setTimeout(()=>{
                     setFieldsValue({
                         'education.board': data.education?.board,
                         'education.stream': data.education?.stream,
                         'education.passingYear': data.education?.passingYear,
                         'education.percentage': data.education?.percentage,
                         'education.maths': data.education?.maths,
                         'education.english': data.education?.english,
                     })
                 },[])
                 events.chooseCountry(data.countryName);
            } catch (error) {
                console.error("Failed to load student data:", error);
            }
        }

    }



    const events = {
        loadState: (countryId) => {
            if (countryId) {
                let filterArr = _.filter(StatesJson, (item) => item.country_id === countryId);
                return filterArr;
            }
            return [];
        },
        chooseCountry: (value) => {
            let country = _.find(CountryJson, (item) => item.name === value);
                if (country) {
                    setFieldsValue({countryName: value})
                    setAllStates(country.id ? events.loadState(country.id) : []);
                }

        },
        chooseState: (value) => {
            setFieldsValue({stateName: value})
        },
    };
    const ExamConditions = (!getFieldValue('englishRequirement.examType') )
    const Exam12ThConditions = (!getFieldValue('englishRequirement.examType') || (getFieldValue('englishRequirments.examType') && getFieldValue('englishRequirments.examType') !== 'I don\'t have this'))
    const EnglishExamTypes = [
        'IELTS',
        'PTE',
        'TOEFL',
        'Duolingo English Test',
    ]
    const qualificationOptions = [
        'X + 3 Year Diploma',
        'XII',
        'Bachelor Degree',
        'Post Graduation',
        'XII + Diploma'
    ]
    const showStreamField = ((getFieldValue('education.qualification') && (getFieldValue('education.qualification') == 'XII' || getFieldValue('education.qualification') == 'XII + Diploma')))

    const sections = [
        {
            title: "Personal Information",
            fields: [
                {
                    key: "firstName",
                    label: "First Name",
                    span: "col-md-6",
                    placeholder: "Enter First Name",
                    required: true
                },
                {
                    key: "lastName",
                    label: "Last Name",
                    span: "col-md-6",
                    placeholder: "Enter Last Name",
                },
                {
                    key: "dateOfBirth",
                    label: "Date of Birth (DD/MM/YYYY)",
                    type: "dateOfBirth",
                    span: "col-md-6",
                    placeholder: "Enter Date of Birth (DD/MM/YYYY)",
                    required: true,
                    onChange: (value) => {
                        setFieldsValue({
                            dateOfBirth: value
                        })
                    },
                },
                {
                    key: "email",
                    label: "Email",
                    type: "email",
                    span: "col-md-6",
                    placeholder: "Enter Email",
                    required: true
                },
                {
                    key: "nationality",
                    label: "Nationality",
                    span: "col-md-6",
                    placeholder: "Enter Nationality",
                    required: true
                },
                {
                    key: "studentType",
                    label: "Student Type",
                    type: "select",
                    required: true,
                    options: [
                        {label: "International", value: "international"},
                        {label: "Onshore", value: "onshore"},
                    ],
                    span: "col-md-6",
                    placeholder: "Select Student Type",
                    keyAccessor: (option) => option.value,
                    valueAccessor: (option) => option.label,
                    onChange: (value) => {
                        setFieldsValue({
                            studentType: value
                        })
                    },
                },
                {
                    key: "mobile",
                    type: "phone",
                    label: "Phone no",
                    required: true,
                    placeholder: "Enter mobile no",
                    span: 'col-md-6',
                    onChange: (value) => {
                        setFieldsValue({mobile: value});
                    }
                },
                {
                    key: "address",
                    label: "Address",
                    span: "col-md-6",
                    placeholder: "Enter Address",
                },
                {
                    key: "countryName",
                    type: "select",
                    label: "Country",
                    placeholder: "Select Country",
                    span: "col-md-4",
                    required: true,
                    showSearch: true,
                    options: CountryJson.map((country) => country.name),
                    onChange: (value) => {
                        events.chooseCountry(value);
                        setFieldsValue({stateName:undefined});
                    },
                },
                {
                    key: "stateName",
                    type: "select",
                    label: "State",
                    placeholder: "Select State",
                    span: "col-md-4",
                    showSearch: true,
                    required: true,
                    options: allStates.map((state) => state.name),
                    onChange: (value) => {
                        setFieldsValue({
                            stateName: value
                        })
                    },
                },
                {
                    key: "cityName",
                    label: "City",
                    span: "col-md-4",
                    placeholder: "Enter City",
                },
                {
                    key: "postalCode",
                    label: "Postal Code",
                    span: "col-md-4",
                    placeholder: "Enter PostalCode"
                }
            ],
        },
        {
            title: "Education",
            fields: [
                {
                    key: 'education.qualification',
                    label: 'Qualification',
                    type: 'select',
                    allowClear: true,
                    required: getFieldValue('countryName') == 'India',
                    options: qualificationOptions,
                    onChange: x => {
                        let obj = {
                            qualification: x,
                            passingYear: '',
                            percentage: '',
                            maths: '',
                            english: '',
                            board: ''
                        }
                        props.form.setFieldsValue({
                            education: obj
                        })
                    },
                    span: "col-md-2",
                },
                {
                    key: 'education.board',
                    label: 'Board/University',
                    showSearch: true,
                    type: 'text',
                    required: getFieldValue('education.qualification') && getFieldValue('education.qualification') !== 'X + 3 Year Diploma',
                    hidden: !(getFieldValue('education.qualification') && getFieldValue('education.qualification') !== 'X + 3 Year Diploma'),

                    span: "col-md-2",
                },
                {
                    key: 'education.stream',
                    span: "col-md-2",
                    label: 'Streams',
                    type: 'text',
                    required: showStreamField,
                    hidden: !showStreamField,
                },
                {
                    key: 'education.passingYear',
                    label: 'Passing Year',
                    type: 'number',
                    span: "col-md-2",
                    required: getFieldValue('education.qualification'),
                    minimum: 0
                },
                {
                    key: 'education.percentage',
                    label: 'Percentage',
                    type: 'number',
                    span: "col-md-2",
                    required: getFieldValue('education.qualification')
                },
                {
                    key: 'education.maths',
                    label: 'Math Score',
                    type: 'number',
                    span: "col-md-2",
                    hidden: props.form.getFieldValue('education.qualification') !== 'XII',
                    required: props.form.getFieldValue('education.qualification') == 'XII'
                },
                {
                    key: 'education.english',
                    label: 'English Score',
                    type: 'number',
                    span: "col-md-2",
                    hidden: props.form.getFieldValue('education.qualification') !== 'XII',
                    required: props.form.getFieldValue('education.qualification') == 'XII'
                }
            ]
        },
        {
            title: "Test Scores",
            fields: [
                {
                    key: 'englishRequirement.examType',
                    label: 'English Exam Type',
                    type: 'select',
                    allowClear: true,
                    required: getFieldValue('countryName') == 'India',
                    showSearch: true,
                    options: EnglishExamTypes,
                    onChange: x => {
                        let obj = {
                            examType: x,
                            overall: '',
                            listening: '',
                            reading: '',
                            writing: '',
                            speaking: '',
                        }
                        props.form.setFieldsValue({
                            englishRequirement: obj
                        })
                    },
                    span: "col-md-2",
                },
                {
                    key: 'englishRequirement.examinationDate',
                    label: 'Examination Date',
                    placeholder: 'Examination Date',
                    type: 'date',
                    required: !ExamConditions,
                    span: "col-md-2",
                    onChange: (value) => {
                        setFieldsValue({
                            'englishRequirement.examinationDate': value
                        })
                    },
                },
                {
                    key: 'englishRequirement.overall',
                    label: 'Overall',
                    type: 'number',
                    span: "col-md-2",
                    required: !ExamConditions,
                    placeholder: 'Overall'
                },
                {
                    key: 'englishRequirement.listening',
                    label: 'Listening',
                    type: 'number',
                    span: "col-md-2",
                    required: !ExamConditions,
                    placeholder: 'Listening'
                },
                {
                    key: 'englishRequirement.reading',
                    label: 'Reading',
                    type: 'number',
                    span: "col-md-2",
                    required: !ExamConditions,
                    placeholder: 'Reading'
                },
                {
                    key: 'englishRequirement.writing',
                    label: 'Writing',
                    type: 'number',
                    span: "col-md-2",
                    required: !ExamConditions,
                    placeholder: 'Writing'
                },
                {
                    key: 'englishRequirement.speaking',
                    label: 'Speaking',
                    type: 'number',
                    span: "col-md-2",
                    required: !ExamConditions,
                    placeholder: 'Speaking'
                }
            ]
        },
    ];


    useEffect(() => {
        listEvents.initialDataLoad();
        }, [reload]);
    console.log('all',allStates)
    const handleSubmit = async (e) => {
        e.preventDefault();
        const {form} = props;
        console.log(form.validateFields)
        form.validateFields(async (err, valData) => {
            console.log(err, valData)
            if (!err) {
                valData.intake = intake;
                valData = {...valData , _id : params.id}
                let resp = await dispatch(updateStudentDetailFxn(valData));

                if (resp && resp.success) {
                    setReload(!reload)
                }
            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-xl">
                    <Card title={"Edit Student"}>
                        <Form onSubmit={handleSubmit}>
                            {sections.map((section, sectionIndex) => (
                                <div className="form-section" key={sectionIndex}>
                                    <h6 className="form-title">{section.title}</h6>
                                    <div className="row">
                                        {section.fields.map((item, key) => (
                                            !item.hidden &&
                                            <div className={`${item.span ? item.span : "col-md-6"}`} key={key}>
                                                <GetEachFormFields
                                                    getFieldDecorator={getFieldDecorator}
                                                    getFieldError={getFieldError}
                                                    {...props.form}
                                                    item={item}
                                                    keyAccessor={item.keyAccessor}
                                                    valueAccessor={item.valueAccessor}
                                                    onChange={item.onChange}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <hr className="my-6 mx-n6"/>

                                </div>
                            ))}
                            <button type="submit" className="btn btn-primary">
                                Submit
                            </button>
                        </Form>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default Form.create()(EditStudent);
