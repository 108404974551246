import React from "react";
import {Modal} from "../../../components/Elements/appUtils";
import InvoicesComponent from "../components/invoices";

const InvoiceModal = (props) => {
    const {visible, onClose, application} = props;
    return (
        <Modal
            visible={visible}
            onClose={onClose}
            width={'65%'}
            title={(
                <h5 className="card-action-title mb-0">
                    <i className='bx bx-bar-chart-alt-2 bx-lg text-body me-4'></i>
                    <b>Invoices</b>
                </h5>
            )}>
            {application && (
                <div className="card mb-6">
                    <div className="card-body p-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-start align-items-center">
                                <div className="avatar me-3">
                                    {/*<img src="../../assets/img/studIcon.png" alt="Avatar" className="rounded-circle"/>*/}

                                    {application.result.firstName && application.result.lastName ?
                                        <div className={`avatar avatar me-2`}>
                                        <span className={`avatar-initial rounded-circle bg-label-primary`}
                                              style={{border: "none"}}>
                                           {application.result.firstName.charAt(0)}
                                            {application.result.lastName.charAt(0)}
                                        </span>
                                        </div> : null}
                                </div>
                                <div className="d-flex flex-column">
                                    <a className="text-body text-nowrap">
                                        <h6 className="mb-0">{application.result.firstName} {application.result.lastName}</h6>
                                    </a>
                                    <span className={'fontsize-15'}>Email: {application.result.email}</span></div>
                            </div>
                            <div className={'lh-15 fontsize-15'}>
                                <p className="mb-0">Mobile: {application.result.mobile}</p>
                                <p className="mb-0">Address: {application.result.stateName}, {application.result.countryName}</p>
                            </div>
                        </div>

                    </div>

                </div>
            )}

            <InvoicesComponent {...props}/>
        </Modal>
    );
}

export default InvoiceModal;
