import React, {useEffect} from "react"
import "../../../assests/frontApp/css/styles.css"
import "../../../assests/frontApp/css/responsive.css"
import "../../../assests/frontApp/css/shortcodes.css"
import "../../../assests/frontApp/css/js_composer.min.css"
import "../../../assests/frontApp/css/style2.css"

const Header = () => {
    const addScript = (src, onLoad) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = onLoad;
        document.body.appendChild(script);
    };


    const addMultipleScripts = (srcArray, callback) => {
        let loadedCount = 0;
        const scriptLoaded = () => {
            loadedCount++;
            if (loadedCount === srcArray.length) {
                callback();
            }
        };
        srcArray.forEach(src => addScript(src, scriptLoaded));
    };

    useEffect(() => {
        const scripts = [
            `${process.env.PUBLIC_URL}/appAssets/js/jquery.easing.min.js`,
            `${process.env.PUBLIC_URL}/appAssets/js/waypoints/waypoints.min0147.js`,
            `${process.env.PUBLIC_URL}/appAssets/js/waypoints-sticky.min.js`,
            // `${process.env.PUBLIC_URL}/appAssets/js/prettyPhoto.js`,
            `${process.env.PUBLIC_URL}/appAssets/js/isotope.pkgd.min.js`,
            `${process.env.PUBLIC_URL}/appAssets/js/functions.js`
        ];
        addMultipleScripts(scripts, () => {
            console.log('All scripts loaded!');
            appendCls()
        });
    }, []);


    let appendCls = () => {
        const script = document.createElement("script");
        script.src = `${process.env.PUBLIC_URL}/appAssets/js/menu.js`;
        document.body.appendChild(script);
    }

    let openGastown = (path) => {
        let rootUrl = "https://gastownbusinesscollege.ca"
        window.open(rootUrl + path)
    }


    return (
        <>

            <header id="header" className="header header-v1 header-v1-only clearfix  ">
                <div className="container">
                    <div id="logo-navigation" className="sixteen columns">
                        <div id="logo" className="logo">
                            <a href="/"><img src="../appAssets/images/gbc_logo.png"
                                             alt="Gastown Business College (GBC)"
                                             className="logo_standard"/></a>
                            <a href="/"><img src="../appAssets/images/gbc_logo.png"
                                             alt="Gastown Business College (GBC)"
                                             className="logo_transparent"/></a> <a
                            href="/"><img src="../appAssets/images/gbc_logo.png"
                                          width={229} height={55}
                                          alt="Gastown Business College (GBC)"
                                          className="logo_retina"/></a>
                            <a href="/"><img src="../appAssets/images/gbc_logo.png"
                                             width={229} height={55}
                                             alt="Gastown Business College (GBC)"
                                             className="logo_retina_transparent"/></a></div>
                        <div id="navigation" className="clearfix">
                            <div className="header-icons">
                            </div>
                            <ul id="nav" className="menu">
                                <li id="menu-item-17957"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-17957">
                                    <a href="/">Home</a></li>
                                <li id="menu-item-17958"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17958">
                                    <a onClick={() => {
                                        openGastown('/why-gbc')
                                    }}>WHY GBC?</a></li>
                                <li id="menu-item-17961"
                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-17961">
                                    <a href="#">PROGRAMS</a>
                                    <ul className="sub-menu">
                                        <li id="menu-item-17964"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17964">
                                            <a onClick={() => {
                                                openGastown('/incist24')
                                            }}>INCIST (PRACTICUM) PROGRAM</a></li>
                                        <li id="menu-item-17965"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17965">
                                            <a onClick={() => {
                                                openGastown('/ibm')
                                            }}>iBM (PRACTICUM) PROGRAM</a></li>
                                        <li id="menu-item-17963"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17963">
                                            <a onClick={() => {
                                                openGastown('/ibf')
                                            }}>iBF (CO-OP) PROGRAM</a></li>
                                        <li id="menu-item-17984"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17984">
                                            <a onClick={() => {
                                                openGastown('/ibf-2')
                                            }}>iBH (CO-OP) PROGRAM</a></li>
                                        <li id="menu-item-18029"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-18029">
                                            <a onClick={() => {
                                                openGastown('/bfpc')
                                            }}>BFPC (PRACTICUM &amp; CO-OP)
                                                PROGRAM</a></li>
                                        <li id="menu-item-17966"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17966">
                                            <a onClick={() => {
                                                openGastown('/our-practicum')
                                            }}>OUR PRACTICUM/CO-OP</a></li>
                                    </ul>
                                </li>
                                <li id="menu-item-17962"
                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-17962">
                                    <a href="#">ADMISSIONS</a>
                                    <ul className="sub-menu">
                                        <li id="menu-item-17969"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17969">
                                            <a onClick={() => {
                                                openGastown('/requirements')
                                            }}>REQUIRMENTS</a></li>
                                        <li id="menu-item-17968"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17968">
                                            <a onClick={() => {
                                                openGastown('/schedule')
                                            }}>SCHEDULE &amp; FEES</a></li>
                                        <li id="menu-item-17967"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17967">
                                            <a onClick={() => {
                                                openGastown('/downloads')
                                            }}>DOWNLOADS</a></li>
                                        <li id="menu-item-18004"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-18004">
                                            <a onClick={() => {
                                                openGastown('/pay-my-tuition')
                                            }}>PAY MY TUITION</a></li>
                                    </ul>
                                </li>
                                <li id="menu-item-18121"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-18121">
                                    <a onClick={() => {
                                        openGastown('/student-statement-of-rights')
                                    }}>Student Statement of
                                        Rights</a></li>
                                <li id="menu-item-17960"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17960">
                                    <a onClick={() => {
                                        openGastown('/testimonials')
                                    }}>TESTIMONIALS</a></li>
                                <li id="menu-item-17959"
                                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-16160 current_page_item menu-item-17959">
                                    <a href="/" aria-current="page">CONTACT US</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <div id="mobile-header" className="mobile-header-v1">
                <div className="container">
                    <div className="sixteen columns">
                        <div id="mobile-logo" className="logo">
                            <a href="/"><img src="../appAssets/images/gbc_logo.png"
                                             alt="Gastown Business College (GBC)"
                                             className="logo_standard"/></a>
                            <a href="/"><img src="../appAssets/images/gbc_logo.png"
                                             width={229} height={55}
                                             alt="Gastown Business College (GBC)"
                                             className="logo_retina"/></a>
                        </div>
                        <a href="#" id="mobile-navigation-btn"><i className="fa fa-bars"/></a>
                    </div>
                </div>
            </div>
            <div id="mobile-navigation">
                <div className="container">
                    <div className="sixteen columns">
                        <div className="menu-main-2-container">
                            <ul id="mobile-nav" className="menu mobile-menu">
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-17957">
                                    <a href="/">Home</a></li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17958">
                                    <a onClick={() => {
                                        openGastown('/why-gbc')
                                    }}>WHY GBC?</a></li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-17961">
                                    <a href="#">PROGRAMS</a>
                                    <ul className="sub-menu">
                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17964">
                                            <a onClick={() => {
                                                openGastown('/incist24')
                                            }}>INCIST (PRACTICUM) PROGRAM</a></li>
                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17965">
                                            <a onClick={() => {
                                                openGastown('/ibm')
                                            }}>iBM (PRACTICUM) PROGRAM</a></li>
                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17963">
                                            <a onClick={() => {
                                                openGastown('/ibf')
                                            }}>iBF (CO-OP) PROGRAM</a></li>
                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17984">
                                            <a onClick={() => {
                                                openGastown('/ibf-2')
                                            }}>iBH (CO-OP) PROGRAM</a></li>
                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-18029">
                                            <a onClick={() => {
                                                openGastown('/bfpc')
                                            }}>BFPC (PRACTICUM &amp; CO-OP)
                                                PROGRAM</a></li>
                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17966">
                                            <a onClick={() => {
                                                openGastown('/our-practicum')
                                            }}>OUR PRACTICUM/CO-OP</a></li>
                                    </ul>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-17962">
                                    <a href="#">ADMISSIONS</a>
                                    <ul className="sub-menu">
                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17969">
                                            <a onClick={() => {
                                                openGastown('/requirements')
                                            }}>REQUIRMENTS</a></li>
                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17968">
                                            <a onClick={() => {
                                                openGastown('/schedule')
                                            }}>SCHEDULE &amp; FEES</a></li>
                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17967">
                                            <a onClick={() => {
                                                openGastown('/downloads')
                                            }}>DOWNLOADS</a></li>
                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-18004">
                                            <a onClick={() => {
                                                openGastown('/pay-my-tuition')
                                            }}>PAY MY TUITION</a></li>
                                    </ul>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-18121">
                                    <a onClick={() => {
                                        openGastown('/student-statement-of-rights')
                                    }}>Student Statement of
                                        Rights</a></li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17960">
                                    <a onClick={() => {
                                        openGastown('/testimonials')
                                    }}>TESTIMONIALS</a></li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-16160 current_page_item menu-item-17959">
                                    <a href="/" aria-current="page">CONTACT US</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div id="notitlebar"/>

        </>
    )
}
export default Header
