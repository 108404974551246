import React, {useEffect, useRef, useState} from 'react';
import {Table, Card, Checkbox} from '../../components/Elements/appUtils';
import {courseListFxn} from "./action";
import EditCourse from "./view/edit";
import {Link, useNavigate} from "react-router-dom";
import UserRightComponent from "../../userRights";

let {TableCard} = Card;

const CourseList = () => {
    const navigate = useNavigate();
    let tableRef = useRef();
    const [total, setTotal] = useState(0);
    const [courseId, setCourseId] = useState('');
    let [intakeState, setIntakeState] = useState({
        visible: false,
    });
    const user = localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):{};



    const filterInput = [
        {
            key: 'courseName',
            label: 'Course Name',
            span: 'col-md-3',
            type: 'text',
        },
        {
            key: 'courseLevel',
            label: 'Course Level',
            span: 'col-md-3',
            type: 'text',

        },
        {
            key: 'courseCategory',
            label: 'Course Category',
            span: 'col-md-3',
            type: 'text',

        },
        {
            key: 'scholarship',
            label: 'Scholarship',
            span: 'col-md-3',
            type: 'text',

        },
        {
            key: 'courseCode',
            label: 'Course Code',
            span: 'col-md-3',
            type: 'text',

        },
        {
            key: 'typeOfProgram',
            label: 'Type Of Program',
            span: 'col-md-3',
            type: 'text',

        },
        {
            key: 'hoursOfInstructionPerWeek',
            label: 'Hours Of Instruction Per Week',
            span: 'col-md-3',
            type: 'text',
        },
    ];
    const columns = [
        {title: 'Sr. No.', key: 'serialNumber', render: (text, record, index) => index + 1},
        {title: 'Course Name', dataIndex: 'courseName', key: 'courseName'},
        {title: 'Course Code', dataIndex: 'courseCode', key: 'courseCode'},
        {title: 'Campus Name', dataIndex: 'campusName', key: 'campusName'},
        {title: 'Course Duration', dataIndex: 'courseDuration', key: 'courseDuration'},
        {title: 'Course Level', dataIndex: 'courseLevel', key: 'courseLevel'},
        {title: 'Course Category', dataIndex: 'courseCategory', key: 'courseCategory'},
        {title: 'Tuition Fees', dataIndex: 'tuitionFee', key: 'tuitionFee'},
        {title: 'Payable', dataIndex: 'payable', key: 'payable'},
        {title: 'Additional Requirement', dataIndex: 'additionalRequirement', key: 'additionalRequirement'},
        {title: 'Scholarship', dataIndex: 'scholarship', key: 'scholarship'},
        {title: 'Level Of Study', dataIndex: 'levelOfStudy', key: 'levelOfStudy'},
        {title: 'Type Of Program', dataIndex: 'typeOfProgram', key: 'typeOfProgram'},
        {title: 'Academic Status', dataIndex: 'academicStatus', key: 'academicStatus'},
        {
            title: 'Hours Of Instruction Per Week',
            dataIndex: 'hoursOfInstructionPerWeek',
            key: 'hoursOfInstructionPerWeek'
        },
        {
            title: 'Action',
            key: 'actions',
            render: (text, record) => (<><UserRightComponent route={{right: "courses", rightType: "edit"}} name={'edit-course'}>
                <Link to={`/edit-course/${record._id}`}>
                    <i className={`menu-icon tf-icons bx bx-edit-alt`}/>
                </Link>
            </UserRightComponent></>)
        }
    ];


    const initialColumns = ['serialNumber', 'courseName', 'courseDuration', 'courseLevel', 'courseCode', 'courseCategory', 'tuitionFee', 'actions'];


    const hideIntakeDrawer = async () => {
        setIntakeState({visible: false});
    };

    const showIntakeDrawer = async (id) => {
        setCourseId(id);
        setIntakeState({visible: true});
    };

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            const regExFilters = ["courseName", "courseDuration", 'courseLevel',
                'courseCategory', "scholarship", "courseCode", "typeOfProgram", "hoursOfInstructionPerWeek"];
            params = {...params, regExFilters}
            let data = await courseListFxn(params);
            setTotal(data.data.total);
            resolve(data.data);
        });
    };


    return (
        <>
            <TableCard title={`Course List (Total: ${total})`} actions= {<> <UserRightComponent route={{right: "courses", rightType: "add"}} name={'add-course'}>
                <a className="btn btn-label-primary" onClick={() => navigate(`/add-course`)}>
                    <span className={'tf-icons bx bx-plus bx-18px me-2'}></span>
                    Add Course
                </a>
            </UserRightComponent></>
            }>
                <Table
                    apiRequest={apiRequest}
                    filterFields={filterInput}
                    columns={columns}
                    initialColumns={initialColumns}
                    bordered ref={tableRef}/>
            </TableCard>
            {intakeState.visible ? (
                <EditCourse
                    {...intakeState}
                    courseId={courseId}
                    onClose={hideIntakeDrawer}
                    onSubmit={hideIntakeDrawer}
                />
            ) : null}
        </>
    );
};
export default CourseList;
