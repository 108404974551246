import React from "react";
import Logo from '../../logo-6.png';
import Header from '../header/index'
import {useNavigate} from "react-router-dom";

const Dashboard = (props) => {
    let {currentUser} = props;
    const navigate = useNavigate();
    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <>
            <div className="row">

                <div className="col-xxl-12 mb-6 order-0">
                    <div className="card">
                        <div className="d-flex align-items-start row">
                            <div className="col-sm-7">
                                <div className="card-body">
                                    <h5 className="card-title text-primary mb-3">Welcome {currentUser.name}! 🎉</h5>
                                    <a onClick={() => handleNavigation("/update-profile")}
                                       className="btn btn-sm btn-label-primary">View Profile</a>
                                </div>
                            </div>
                            <div className="col-sm-5 text-center text-sm-left">
                                <div className="card-body pb-0 px-0 px-md-6">
                                    <img src="../../assets/img/man-with-laptop.png" height="150"
                                         className="scaleX-n1-rtl" alt="View Badge User"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="col-xxl-4 col-lg-12 col-md-4 order-1">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-6 mb-6">
                            <div className="card h-100">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between mb-4">
                                        <div className="avatar flex-shrink-0">
                                            <img src="../../assets/img/unicons/wallet-round.png" alt="wallet info"
                                                 className="rounded"/>
                                        </div>
                                    </div>
                                    <p className="mb-1">Students</p>
                                    <h4 className="card-title mb-3">100</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-6 mb-6">
                            <div className="card h-100">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between mb-4">
                                        <div className="avatar flex-shrink-0">
                                            <img src="../../assets/img/unicons/wallet-round.png" alt="wallet info"
                                                 className="rounded"/>
                                        </div>
                                    </div>
                                    <p className="mb-1">Agents</p>
                                    <h4 className="card-title mb-3">10</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}

            </div>
        </>
    )
}

export default Dashboard;
