import React, {useEffect, useRef, useState} from "react"
import {useDispatch} from "react-redux";
import {addMarketingManagerFxn, editUserFxn} from "../action";
import {Card, Form, notification} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import _ from "lodash"
import {customAxios as axios, getToken} from "../../../request";
import {useParams} from "react-router-dom";
import {addMarketingManagerUrl} from "../api";
import {apiUrl} from "../../../settings";
import moment from "moment";
import {initRights} from "../../../components/_utils/_utils";
import async from "async"

const initState = {
    contactPersonName: "",
    email: "",
    password: "",
    postName: "",
    joiningDate: "",
    mobile: "",
    address: "",
    countryCode: "",
    profilePic: null,
};


const EditUser = (props) => {
    let {form: {getFieldDecorator, getFieldError, setFieldsValue, getFieldValue}} = props
    const dispatch = useDispatch();
    const [state, setState] = useState(initState);
    const [isHovered, setIsHovered] = useState(false);
    const [rights, setRights] = useState(initRights);
    // const [dataUpdated, setDataUpdated] = useState(false);
    const [department, setDepartment] = useState('');
    const [profilePic, setProfilePic] = useState({})
    const [profileSendPic, setProfileSendPic] = useState({});


    let inputTypes = [
        {
            key: 'userType',
            label: 'Type',
            type: 'select',
            required: true,
            disabled: true,
            options: [{value: 'team', name: "Team"}, {value: 'marketingManager', name: "Marketing Manager"}],
            keyAccessor: x => x.value,
            valueAccessor: x => `${x.name}`,
            onChange: x => {
                setFieldsValue({
                    userType: x
                })
            }
        },
        {
            key: "department",
            label: "Department",
            span: "col-md-6",
            placeholder: "Enter Department",
            required: getFieldValue('userType') === 'team',
            hidden: getFieldValue('userType') !== 'team'
        },
        {
            key: "name",
            label: "Name",
            span: "col-md-6",
            placeholder: "Enter Name",
            required: true
        },
        {
            key: "mobile",
            type: "phone",
            label: "Phone no",
            required: true,
            placeholder: "Enter mobile no",
            span: 'col-md-6',
            // onChange: (value) => {
            //     setFieldsValue({mobile: value});
            //     events._updateState({mobile: value});
            // }
        },
        {
            key: "email",
            label: "Email",
            type: "email",
            disabled: true,
            span: "col-md-6",
            placeholder: "Enter Email",
        },
        {
            key: "postName",
            label: "Post Name",
            type: "text",
            span: "col-md-6",
            placeholder: "Enter Post Name",
        },
        {
            key: "joiningDate",
            label: "Joining Date",
            type: "date",
            span: "col-md-6",
            placeholder: "Choose Joining Date",
            onChange: (value) => {
                setFieldsValue({joiningDate: value});
            }
        },
        // {
        //     key: "profilePic",
        //     label: "Profile Pic",
        //     type: "file",
        //     span: "col-md-6",
        //     placeholder: "Choose file",
        //     onChange: (files) => {
        //         if (files && files.length) {
        //             setFieldsValue({"profilePic": files[0]});
        //         }
        //     }
        // },
        {
            key: "address",
            label: "Address",
            type: "textarea",
            span: "col-md-12",
            placeholder: "Enter Address",
        },
    ]


    const params = useParams();
    const handleSubmit = async (e) => {
        e.preventDefault()
        const {form} = props
        form.validateFields(async (err, valData) => {
            if (!err) {

                let fd = new FormData();
                let obj = {...valData, _id: params.id};
                if (valData.userType === 'team')
                    obj = {...obj, rights};
                else
                    obj = {...obj};
                fd.append('obj', JSON.stringify(obj));
                let resp = await dispatch(editUserFxn(fd))
                let data = resp.data;
                if (resp && resp.success) {
                    form.setFieldsValue({
                        userType: data.userType,
                        department: data.department,
                        name: data.name,
                        mobile: data.mobile,
                        email: data.email,
                        password: data.password,
                        postName: data.postName,
                        joiningDate: data.joiningDate ? moment(data.joiningDate) : '',
                        address: data.address,
                    });

                    setRights(data.rights);
                    setDepartment(data.department);

                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        });
    };

    const handleRightChange = (e, idx) => {
        const {name, checked} = e.target;
        let updatedRight = {...rights[idx]};
        updatedRight = {...updatedRight, [name]: checked};
        rights.splice(idx, 1, updatedRight);
        setRights([...rights]);
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const {id} = params;
                let {data: result} = await axios.get(apiUrl + `/getSingleUser/${id}`, getToken());
                const data = result.user;
                console.log('data', data)
                setFieldsValue({
                    userType: data.userType,
                    name: data.name,
                    mobile: data.mobile,
                    email: data.email,
                    postName: data.postName,
                    joiningDate: data.joiningDate ? moment(data.joiningDate) : '',
                    profilePic: data.profilePic?.filename,
                    address: data.address,
                    department: data.department ? data.department : ""
                });
                let cloneRights = [];
                async.each(initRights, (item, cb) => {
                    let findD = _.find(data.rights, (dItem) => {
                        return dItem.type == item.type;
                    })
                    if (findD) {
                        item = {...item, ...findD};
                        cloneRights.push(item);
                        cb()
                    } else {
                        cloneRights.push(item);
                        cb()
                    }
                }, () => {
                    setRights(cloneRights);
                })


                setDepartment(data.department);
                setProfilePic(data.profilePic);
                // setState(prevState => ({
                //     ...prevState,
                //     ...data,
                // }));
            } catch (error) {
                console.error('Error fetching data:', error);
                notification.error({
                    message: 'Error',
                    description: 'There was an error fetching the initial data.'
                });
            }
        };

        fetchData();
    }, []);
    console.log('state', state);
    useEffect(() => {
        if (getFieldValue("userType") === 'team') {
            setFieldsValue({department: department});
        }
    }, [getFieldValue("userType")]);
    const fileInputRef = useRef(null);

    const handleImageClick = () => {
        fileInputRef.current.click();
    };
    const onFileChange = (e) => {
        const file = e.target.files[0];
        setProfileSendPic(file);

    }

    useEffect(() => {
        const updateProfilePic = async () => {
            if (profileSendPic && profileSendPic.name) {
                const {form} = props;
                try {
                    let fd = new FormData();
                    fd.append('obj', JSON.stringify({_id: params.id}));
                    if (profileSendPic && profileSendPic.name) {
                        fd.append('profilePic', profileSendPic);
                    }
                    let resp = await dispatch(editUserFxn(fd))
                    let data = resp.data;
                    if (resp && resp.success) {

                        setProfileSendPic({});
                        setProfilePic(data.profilePic);
                    }
                } catch (e) {
                    notification.warning({
                        message: 'error in updating'
                    })
                }
            }
        };
        updateProfilePic();
    }, [profileSendPic])

    return (
        <>
            <div className="row">
                <div className="col-xl">
                    <Card title={'Edit User'}>
                        <div className={'row '}><h6 className={'form-title col-3'}>Basic Details</h6>
                            <div className={'col-2 d-flex justify-content-center align-content-center flex-column mb-3'}
                                 style={{position: "absolute", right: "0px", top: '66px'}}>
                                <div style={{'textAlign': 'center'}}><img
                                    src={profilePic?.path}
                                    width={"60px"}
                                    height={"55px"}
                                    style={{
                                        borderRadius: "50%",
                                        border: "4px solid #696cffc7",
                                    }}

                                /></div>
                                <div style={{'textAlign': 'center'}}>
                                    <button
                                        onClick={handleImageClick}
                                        style={{
                                            cursor: 'pointer',
                                            height: '13px',
                                            width: '90px',
                                            fontSize: '10px',
                                            borderRadius: '10px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            margin: 'auto',
                                            backgroundColor: isHovered ? '#dcdcdc' : '#fff',
                                            color: isHovered ? '#000' : '#333',
                                            border: '1px solid #ccc',
                                            padding: '10px 20px',
                                            transition: 'background-color 0.3s, color 0.3s'
                                        }}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                    >
                                        Upload
                                    </button>
                                </div>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{display: 'none'}}
                                    onChange={onFileChange}
                                /></div>
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <div className={'row d-flex'}>
                                {inputTypes.map((item, key) => {
                                    return !item.hidden ? (
                                        <div className={`${item.span ? item.span : "col-md-6"}`}
                                             key={key}>
                                            <GetEachFormFields
                                                getFieldDecorator={getFieldDecorator}
                                                getFieldError={getFieldError}
                                                {...props.form}
                                                item={item}
                                                keyAccessor={item.keyAccessor}
                                                valueAccessor={item.valueAccessor}
                                                onChange={item.onChange}
                                            />
                                        </div>
                                    ) : null;
                                })}
                            </div>
                            {getFieldValue("userType") === 'team' &&
                            <div className="card mb-6" style={{marginTop: "19px", maxWidth: "700px"}}>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className="border-top">
                                        <tr>
                                            <th className="text-nowrap " id={"rightsTable"}>Type</th>
                                            <th className="text-nowrap text-center">ADD</th>
                                            <th className="text-nowrap text-center">EDIT</th>
                                            <th className="text-nowrap text-center">VIEW</th>
                                            <th className="text-nowrap text-center">GENERATE</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {rights.map((right, index) => (
                                            <tr key={index}>
                                                <td className="text-nowrap text-heading">{_.capitalize(right.type)}</td>
                                                <td>
                                                    <div className="form-check d-flex justify-content-center">
                                                        {right.add !== undefined && <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`defaultCheckAdd${index}`}
                                                            name={"add"}
                                                            checked={right.add}
                                                            onChange={(e) => handleRightChange(e, index)}
                                                        />}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-check d-flex justify-content-center">
                                                        {right.edit !== undefined && <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`defaultCheckEdit${index}`}
                                                            name={"edit"}
                                                            checked={right.edit}
                                                            onChange={(e) => handleRightChange(e, index)}
                                                        />}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-check d-flex justify-content-center">
                                                        {right.view !== undefined && <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`defaultCheckView${index}`}
                                                            name={"view"}
                                                            checked={right.view}
                                                            onChange={(e) => handleRightChange(e, index)}
                                                        />}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-check d-flex justify-content-center">
                                                        {right.generate !== undefined &&
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`defaultCheckGenerate${index}`}
                                                            name={"generate"}
                                                            checked={right.generate}
                                                            onChange={(e) => handleRightChange(e, index)}
                                                        />}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>}

                            <button type="submit" className="btn btn-primary mt-5">
                                Update
                            </button>
                        </Form>
                    </Card>
                </div>
            </div>

        </>
    )
}
export default Form.create()(EditUser);

