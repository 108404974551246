import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import "../../assests/backApp/css/style.css"
import "../../assests/backApp/vendor/css/core.css"
import "../../assests/backApp/vendor/fonts/boxicons.css"
import "../../assests/backApp/vendor/css/theme-default.css"
import "../../assests/backApp/css/demo.css"
import "./common.css"
import {Helmet} from "react-helmet";
import {menuData} from "../../routes/menu";
import _ from "lodash"
import UserRightComponent from "../../userRights";

const $ = window.$;
const NotificationComponent = () => {
    return (
        <>
            <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-2">
                <a className="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);"
                   data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                <span className="position-relative">
                  <i className="bx bx-bell bx-md"/>
                  <span className="badge rounded-pill bg-danger badge-dot badge-notifications border"/>
                </span>
                </a>
                <ul className="dropdown-menu dropdown-menu-end p-0" style={{height: '200px', overflowY: "scroll"}}>
                    <li className="dropdown-menu-header border-bottom">
                        <div className="dropdown-header d-flex align-items-center py-3">
                            <h6 className="mb-0 me-auto">Notification</h6>
                            <div className="d-flex align-items-center h6 mb-0">
                                <span className="badge bg-label-primary me-2">0 New</span>
                                <a href="javascript:void(0)" className="dropdown-notifications-all p-2"
                                   data-bs-toggle="tooltip" data-bs-placement="top"
                                   title="Mark all as read"><i
                                    className="bx bx-envelope-open text-heading"/></a>
                            </div>
                        </div>
                    </li>
                    <li className="dropdown-notifications-list scrollable-container">

                    </li>

                </ul>
            </li>

        </>
    )
}
const ShortcutsComponent = ({currentUser: user, rights}) => {
    const navigate = useNavigate();
    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <li className="nav-item dropdown-shortcuts navbar-dropdown dropdown me-2 me-xl-0">
            <a
                className="nav-link dropdown-toggle hide-arrow"
                href="javascript:void(0);"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false">
                <i className="bx bx-grid-alt bx-md"/>
            </a>
            <div className="dropdown-menu dropdown-menu-end p-0">
                <div className="dropdown-menu-header border-bottom">
                    <div className="dropdown-header d-flex align-items-center py-3">
                        <h6 className="mb-3 me-auto">Quick Access</h6>
                    </div>
                </div>
                <div className="dropdown-shortcuts-list scrollable-container">
                    <div className="row-bordered overflow-visible g-0"
                         style={{display: "grid", gridTemplateColumns: "repeat(2, 1fr)"}}>
                        {menuData.map((item) => {
                            return (
                                item.shortcut ? <UserRightComponent route={{...item, rightType: "view"}}>
                                    <div className="dropdown-shortcuts-item col"
                                         onClick={() => handleNavigation(item.path)}>
                                            <span className="dropdown-shortcuts-icon rounded-circle mb-3">
                                                <i className={`bx ${item.icon} bx-26px text-heading`}/>
                                            </span>
                                        <span className="stretched-link">{item.name}</span>
                                    </div>
                                </UserRightComponent> : null
                            )
                        })}
                    </div>
                </div>
            </div>
        </li>
    );
};

const UserInfoComponent = (props) => {
    let {currentUser, menuItems} = props;
    return (
        <>
            <li className="nav-item navbar-dropdown dropdown-user dropdown">
                <a className="nav-link dropdown-toggle hide-arrow p-0" href="javascript:void(0);"
                   data-bs-toggle="dropdown">

                    <div className="d-flex justify-content-start align-items-center user-name ml-2">
                         <span className="position-relative">
                            <i className="bx bx-user bx-md"/>
                            <span className="badge rounded-pill bg-danger border"/>
                         </span>

                        <div className="d-flex flex-column text-capitalize ml-2" style={{lineHeight: 1.1}}>
                            <span className={'align-left'}> {currentUser.name}</span>
                            <small className="text-muted">&nbsp;{currentUser.userType}</small>
                        </div>
                    </div>


                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                    {currentUser.userType === 'marketingManager' && <li>
                        <a className="dropdown-item">
                            <div className="d-flex">
                                <div className="flex-shrink-0 me-3"></div>
                                <div className="flex-grow-1 lh-12">
                                    <div className="mb-0 text-capitalize fontsize-xs">{currentUser.email}</div>
                                    <small className="text-muted text-primary">
                                        Refer Code : {currentUser.referCode}
                                    </small>
                                </div>
                            </div>
                        </a>
                    </li>}
                    <li>
                        <div className="dropdown-divider my-1"/>
                    </li>
                    {menuItems.map((item, idx) => {
                        return (
                            (!item.authority || item.authority.includes(currentUser.userType)) ? <li key={idx}>
                                <a className="dropdown-item cursor-pointer" onClick={item.onClick}>
                                    <i className={`bx bx-${item.icon} bx-md me-3`}/><span>{item.name}</span>
                                </a>
                            </li> : null
                        )
                    })}
                </ul>
            </li>

        </>
    )
}
const TopMenu = (props) => {
    let dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {
    }, []);


    const menuItems = [
        {
            name: "Edit Profile",
            icon: "user",
            onClick: () => {
                navigate(`/update-profile`);
            }
        },
        {
            name: "Change Password",
            icon: "hide",
            onClick: () => {
                navigate('/change-password')
            }
        },
        {
            name: "Complaint",
            icon: "list-ul",
            authority: ["university", 'agent'],
            onClick: () => {
                navigate('/raise-a-complaint')
            }
        },
        {
            name: "Logout",
            icon: "log-out-circle",
            onClick: () => {
                logoutFxn()
            }
        },
    ];
    const logoutFxn = () => {
        localStorage.clear();
        setTimeout(() => {
            dispatch({
                type: "SET_CURRENT_USER",
                user: {},
            });
            window.location.href = "/login";
        }, 100);
    };


    return (
        <>
            <Helmet>
                {/*<link
                    href='https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700&display=swap'
                    rel='preload'
                />*/}
                <link
                    href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;display=swap"
                    rel="stylesheet"/>

            </Helmet>
            <nav
                className="layout-navbar navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme container-fluid"
                id="layout-navbar">
                <div className="layout-menu-toggle navbar-nav align-items-xl-center me-4 me-xl-0   d-xl-none ">
                    <a className="nav-item nav-link px-0 me-xl-6" href="javascript:void(0)">
                        <i className="bx bx-menu bx-md"/>
                    </a>
                </div>
                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">

                    <ul className="navbar-nav flex-row align-items-center ms-auto">
                        <ShortcutsComponent {...props}/>
                        <NotificationComponent/>
                        <UserInfoComponent
                            {...props} logoutFxn={logoutFxn}
                            menuItems={menuItems}/>
                    </ul>
                </div>
                <div className="navbar-search-wrapper search-input-wrapper  d-none">
                    <input type="text" className="form-control search-input container-xxl border-0"
                           placeholder="Search..." aria-label="Search..."/>
                    <i className="bx bx-x bx-md search-toggler cursor-pointer"/>
                </div>
            </nav>

        </>
    );
};
export default TopMenu;
