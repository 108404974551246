import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Modal, notification, Button } from "../../../components/Elements/appUtils";
import { GetEachFormFields } from "../../../components/_utils/formUtils";
import { verifyInstallmentFxn } from "../actions";

const VerifyInstallmentModal = (props) => {
    const {
        form: { getFieldDecorator, getFieldError, setFieldsValue },
        visible,
        onClose,
        installment,

    } = props;

    const [status, setStatus] = useState("verified");
    const [details, setDetails] = useState("");
    const dispatch = useDispatch();


   console.log(installment, "sele")

    const inputSchema = [
        {
            key: "status",
            label: "Status",
            type: "select",
            options: [
                { value: "verified", name: "Verified" },
                { value: "not_verified", name: "Not Verified" }
            ],
            required: true,
            keyAccessor: x => x.value,
            valueAccessor: x => `${x.name}`,
            onChange: x => {
                setStatus(x);
                setFieldsValue({ status: x });
            },
        },
        {
            key: "details",
            label: "Details",
            type: "textarea",
            placeholder: "Enter details",
            span: "col-md-12",
            hidden: status !== "not_verified",
            required: status == "not_verified",
            onChange: (e) => {
                setDetails(e.target.value);
                setFieldsValue({details: e.target.value});
            },
        },
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        const { form } = props;
        form.validateFields(async (err) => {
            if (!err) {
                const resp = await dispatch(verifyInstallmentFxn({
                    installmentId: installment._id,
                    status,
                    details
                }));

                if (resp.success) {
                    notification.success({
                        message: resp.message || "Installment Verified Successfully.",
                    });

                    onClose();
                } else {
                    notification.error({
                        message: resp.message || "Error verifying installment.",
                    });
                }
            } else {
                notification.warning({ message: "Fill All Required Fields" });
            }
        });
    };

    return (
        <Modal
            className="custom-ant-modal"
            visible={visible}
            width={'30%'}
            onClose={onClose}
            title={'Verify Installment'}
        >
            <div className="row g-6">
                <div className="col-12">
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-3">
                    {inputSchema.map((field, key) => (
                        <div
                            className={`${field.span ? field.span : "col-md-12"} mb-3`}
                            key={key}
                        >
                            <GetEachFormFields
                                getFieldDecorator={getFieldDecorator}
                                getFieldError={getFieldError}
                                {...props.form}
                                item={field}
                                keyAccessor={field.keyAccessor}
                                valueAccessor={field.valueAccessor}
                            />
                        </div>
                    ))}
                </div>
                <div className="d-flex justify-content-center mt-3">
                    <button type="submit" className="btn btn-primary mt-3">
                        Save
                    </button>
                </div>
            </form>
                </div>
            </div>
        </Modal>
    );
};

export default Form.create()(VerifyInstallmentModal);
