import React, {useState, useEffect} from "react";
import {
    DatePicker,
    Drawer,
    Card,
    Form,
    notification,
    Table,
    Button,
    displayDate
} from "../../../components/Elements/appUtils";
import {useDispatch} from "react-redux";
import _ from "lodash";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {addCourseFxn} from "../../course/action";
import {resolveComplaintFxn} from "../action";

const initState = {
    detail: "",
};

const ResolveDrawer = (props) => {
    let dispatch = useDispatch();
    let {form: {getFieldDecorator, getFieldError, setFieldsValue}} = props
    let {visible, onClose, complaintId, reloadTable} = props;
    const inputSchema = [

        {
            title: "Details",
            key: "detail",
            type: "textarea",
            label: "Details",
            required:true,
            placeholder: "Enter Details",
            span: "col-md-12",

        }

    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        let {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.complaintId = complaintId
                console.log(valData, "dsfsrwerewrw")
                let resp = await dispatch(resolveComplaintFxn(valData));
                if (resp && resp.success) {
                    form.resetFields()
                    onClose()
                    form.setFieldsValue(initState)
                    reloadTable()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        });
    };

    return (
        <>
            <Drawer visible={visible} onClose={onClose} title={'Resolve Complaint'}>


                <form onSubmit={handleSubmit}>
                    <div className="row">
                        {inputSchema.map((field,key) => (

                            <div
                                className={`${field.span ? field.span : "col-md-6"}`}
                                key={key}
                            >
                                <GetEachFormFields
                                    getFieldDecorator={getFieldDecorator}
                                    getFieldError={getFieldError}
                                    {...props.form}
                                    item={field}
                                    keyAccessor={field.keyAccessor}
                                    valueAccessor={field.valueAccessor}
                                />
                            </div>
                        ))}
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </Drawer>
        </>
    );
};
export default Form.create()(ResolveDrawer);
