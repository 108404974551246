import React, {useEffect, useState} from "react";
import {Drawer, Form, notification} from "../../components/Elements/appUtils";
import {useDispatch} from "react-redux";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {generateInvoiceFxn} from "./actions";
import {singleApplicationFxn} from "../student/actions";
import moment from "moment";

const initState = {
    invoiceAmount: "",
    invoiceDate: undefined,
    dueDate: undefined,
    applicationFee: "",
    materialFee: "",
    studentArchivingFee: "",
    promotion: "",
    commission: "",
    scholarship: "",
    totalOwingNet: "",
    totalOwing: ""
};

const GenerateInvoiceDrawer = (props) => {
    const {
        form: {getFieldDecorator, getFieldError, setFieldsValue, getFieldsValue},
        onClose,
        visible,
        applicationId
    } = props;
    const dispatch = useDispatch();
    const [state, setState] = useState(initState);

    const apiRequest = async () => {
        let resp = await dispatch(singleApplicationFxn({applicationId}));
        if (resp) {
            const {tuitionFee} = resp;
            const {applicationFee, materialFee, studentArchivingFee} = resp.courseId;
            setState(prevState => ({
                ...prevState,
                applicationFee,
                materialFee,
                studentArchivingFee,
                invoiceAmount: tuitionFee,
                scholarship: props.scholarship,
                studentId: resp.studentId._id,
                courseId: resp.courseId._id
            }));
            setFieldsValue({
                applicationFee,
                materialFee,
                studentArchivingFee,
                invoiceAmount: tuitionFee,
                scholarshipAmount: props.scholarship && props.scholarshipAmount ? props.scholarshipAmount : undefined,
            });
        }
    };

    useEffect(() => {
        if (applicationId) {
            apiRequest();
        }
    }, [applicationId]);

    useEffect(() => {
        const values = getFieldsValue([
            'invoiceAmount',
            'materialFee',
            'applicationFee',
            'studentArchivingFee',
            'promotion',
            'commission',
            'scholarshipAmount'
        ]);

        // Calculate totalOwingNet and totalOwing
        const totalOwingNet = (
            parseFloat(values.invoiceAmount || 0) +
            parseFloat(values.materialFee || 0) +
            parseFloat(values.applicationFee || 0) +
            parseFloat(values.studentArchivingFee || 0) -
            parseFloat(values.promotion || 0) -
            parseFloat(values.scholarshipAmount || 0) -
            parseFloat(values.commission || 0)
        ).toFixed(2);

        const totalOwing = (
            parseFloat(values.invoiceAmount || 0) +
            parseFloat(values.materialFee || 0) +
            parseFloat(values.applicationFee || 0) +
            parseFloat(values.studentArchivingFee || 0) -
            parseFloat(values.promotion || 0) -
            parseFloat(values.scholarshipAmount || 0)
        ).toFixed(2);

        setFieldsValue({totalOwingNet, totalOwing});
        setState(prevState => ({
            ...prevState,
            totalOwing
        }));
    }, [getFieldsValue()]);

    const handleInvoiceDateChange = (value) => {
        const dueDate = moment(value).add(10, 'days');
        setFieldsValue({invoiceDate: value, dueDate});
    };

    const inputSchema = [
        {
            key: "invoiceAmount",
            type: "text",
            label: "Invoice Amount",
            required: true,
            placeholder: "Enter Amount",
            span: 'col-md-6'
        },
        {
            key: "invoiceDate",
            type: "date",
            label: "Invoice Date",
            required: true,
            placeholder: "Select Invoice Date",
            span: 'col-md-6',
            onChange: handleInvoiceDateChange
        },
        {
            key: "dueDate",
            type: "date",
            label: "Due Date",
            required: true,
            placeholder: "Select Due Date",
            span: 'col-md-6',
            disabled: true
        },
        {
            key: "applicationFee",
            type: "text",
            label: "Application Fee",
            required: true,
            placeholder: "Enter Application Fee",
            span: 'col-md-6'
        },
        {
            key: "materialFee",
            type: "text",
            label: "Material Fee",
            required: true,
            placeholder: "Enter Material Fee",
            span: 'col-md-6'
        },
        {
            key: "studentArchivingFee",
            type: "text",
            label: "Student Archiving Fee",
            required: true,
            placeholder: "Enter Student Archiving Fee",
            span: 'col-md-6'
        },
        {
            key: "promotion",
            type: "text",
            label: "Promotion",
            required: true,
            placeholder: "Enter Promotion Amount",
            span: 'col-md-6'
        },
        {
            key: "scholarshipAmount",
            type: "text",
            label: "Scholarship",
            required: true,
            placeholder: "Enter Scholarship",
            span: 'col-md-6',
            hidden: !props.scholarship
        },
        {
            key: "commission",
            type: "text",
            label: "Commission",
            required: true,
            placeholder: "Enter Commission Amount",
            span: 'col-md-6'
        },

        {
            key: "totalOwingNet",
            type: "text",
            label: "Total Owing",
            required: true,
            placeholder: "Enter Total Owing Amount",
            span: 'col-md-6',
            disabled: true
        }
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        const {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.applicationId = applicationId;
                valData.totalOwing = state.totalOwing;
                valData.scholarship = state.scholarship;
                let resp = await dispatch(generateInvoiceFxn(valData));
                if (resp && resp.success) {
                    form.resetFields();
                    setState(initState);
                    onClose();
                }
            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    return (
        <>
            <Drawer onClose={onClose} visible={visible} title={'Generate Invoice'}>
                <Form onSubmit={handleSubmit}>
                    <div className="row">
                        {inputSchema.map((item) => (
                            !item.hidden ? <div className={item.span} key={item.key}>
                                <GetEachFormFields
                                    getFieldDecorator={getFieldDecorator}
                                    getFieldError={getFieldError}
                                    item={item}
                                    onChange={item.onChange}
                                />
                            </div> : null
                        ))}
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </Form>
            </Drawer>
        </>
    );
};

export default Form.create()(GenerateInvoiceDrawer);
