import {customAxios as axios, getToken} from "../../../request";
import {
    addMarketingManagerUrl,
    editUserUrl,
    getAllAgentUrl,
    getAllUsersUrl,
    getMarketingManagerUrl, getSingleUserUrl,
    getUserRightUrl
} from "../api";
import {hideLoader, showLoader} from "../../../actions/loader";
import {notification} from "../../../components/Elements/appUtils";

export const addMarketingManagerFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(addMarketingManagerUrl(), valData, getToken());
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    } else {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data;
};
export const editUserFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(editUserUrl(), valData, getToken());
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    } else {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data;
};

export const getMarketingManagerFxn = async (filters) => {

    let config = {
        params: {...filters}
    }
    let {data} = await axios.get(getMarketingManagerUrl(), config);
    return data;
};

export const getAllUsersFxn = async (filters) => {
    let config = {
        params: {...filters}
    }
    let {data} = await axios.get(getAllUsersUrl(), config);
    return data.data;
};

export const getAllAgentFxn = async (filters) => {
    let config = {
        params: {...filters}
    }
    let {data} = await axios.get(getAllAgentUrl(), config);
    return data.data;
};
export const getUserRightFxn = async () => {
    let {data} = await axios.post(getUserRightUrl());
    return data;
};

export const getSingleUserFxn = (id) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.get(getSingleUserUrl(id));
    dispatch(hideLoader())
    return data;
};
