import React, { useState, useEffect } from "react";
import { Button, notification, Card } from "../../../components/Elements/appUtils";
import { useDispatch } from "react-redux";
import { requiredDocumentsFxn, getRequiredDocsFxn } from "../actions/index";
import { documentChecklistTypes } from "../../../components/_utils/_utils";
let { TableCard } = Card;

const DocumentChecklist = () => {
    const [checkedDocuments, setCheckedDocuments] = useState([]);
    const dispatch = useDispatch();

    const fetchRequiredDocs = async () => {
        try {
            const response = await dispatch(getRequiredDocsFxn());
            if (response.success) {
                const docs = response.data;

                const checkedDocs = docs.filter(doc => doc.required).map(doc => doc.name);
                setCheckedDocuments(checkedDocs);
            }
        } catch (error) {
            // notification.error({ message: 'An error occurred while fetching required documents' });
        }
    };

    useEffect(() => {
        fetchRequiredDocs();
    }, [dispatch]);

    const handleCheckboxChange = (documentName) => {
        setCheckedDocuments((prev) => {
            if (prev.includes(documentName)) {
                return prev.filter((doc) => doc !== documentName);
            } else {
                return [...prev, documentName];
            }
        });
    };

    const handleSave = async () => {
        const payload = documentChecklistTypes.map(doc => ({
            name: doc.name,
            required: checkedDocuments.includes(doc.name)
        }));

        try {
            const response = await dispatch(requiredDocumentsFxn({ requiredDocuments: payload }));

        } catch (error) {
            notification.error({ message: 'An error occurred while saving documents' });
        }
    };

    return (
        <div>
            <TableCard title={`Select Required Documents`}>
                <div className="document-checklist-table">
                    <table className="table">
                        <thead>
                        <tr>
                            <th style={{ width: '5%' }}>Select</th>
                            <th>Document Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        {documentChecklistTypes.map((doc, index) => (
                            <tr key={index}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={checkedDocuments.includes(doc.name)}
                                        onChange={() => handleCheckboxChange(doc.name)}
                                    />
                                </td>
                                <td>{doc.name}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className="button-group mt-3 ml-3 mb-2">
                    <Button onClick={handleSave} type="primary">
                        Save
                    </Button>
                </div>
            </TableCard>
        </div>
    );
};

export default DocumentChecklist;
