import React, {Suspense, useEffect} from "react";
import TopNavComponent from "./topMenu";
import SideNavComponent from "./sideMenu";
import {Navigate, Outlet} from "react-router-dom";

const FooterComponent = React.lazy(() => import("./footer"));

const InnerLayout = (props) => {
    let {currentUser} = props;
    const addScript = (src, onLoad) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.onload = onLoad;
        document.body.appendChild(script);
    };

    const addMultipleScripts = (srcArray, callback) => {
        let loadedCount = 0;
        const scriptLoaded = () => {
            loadedCount++;
            if (loadedCount === srcArray.length) {
                callback();
            }
        };
        srcArray.forEach((src) => addScript(src, scriptLoaded));
    };

    useEffect(() => {
        if (currentUser && currentUser._id) {
            const scripts = [
                `${process.env.PUBLIC_URL}/assets/vendor/libs/jquery/jquery.js`,
                `${process.env.PUBLIC_URL}/assets/vendor/libs/popper/popper.js`,
                `${process.env.PUBLIC_URL}/assets/vendor/js/bootstrap.js`,
                `${process.env.PUBLIC_URL}/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js`,
                `${process.env.PUBLIC_URL}/assets/vendor/js/helpers.js`,
                `${process.env.PUBLIC_URL}/assets/vendor/js/menu.js`,
                // `${process.env.PUBLIC_URL}/assets/js/main.js`,
            ];

            addMultipleScripts(scripts, () => {
                console.log("All scripts loaded!");
                appendCls()
            });
        }
    }, []);


    let appendCls = () => {
        const script = document.createElement("script");
        script.src = `${process.env.PUBLIC_URL}/assets/js/main.js`;
        document.body.appendChild(script);
    }


    return (
        currentUser && currentUser._id ? <div>
                <div className="layout-wrapper layout-content-navbar layout-main-inner">
                    <div className="layout-container">
                        <SideNavComponent {...props}/>
                        <div className="layout-page">
                            <TopNavComponent {...props}/>
                            <div class="content-wrapper">
                                <div class="flex-grow-1 container-p-y container-fluid">
                                    <Outlet/>
                                </div>

                                <div class="content-backdrop fade"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> :
            <Navigate replace to="/login"/>
    );
};
export default InnerLayout;
