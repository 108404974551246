import React, {useEffect, useState} from "react"
import Pdf from "../../../assests/pdfDoc.png";
import Avatar1 from "../../../assests/avatar1.png";
import {activityListFxn} from "../actions";
import {useDispatch} from "react-redux";
import {Avatar, displayDate} from "../../../components/Elements/appUtils";
import {FilterEachFields} from "../../../components/_utils/filterUtils";
import {documentSubName, documentTypes} from "../../../components/_utils/_utils";

const ActivitiesComponent = (props) => {
    let {applicationId, studentId} = props;
    const [activities, setActivities] = useState([]);
    const [activityUsers, setActivityUsers] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [documentType, setDocumentType] = useState("");
    const [userId, setUserId] = useState(undefined);
    let dispatch = useDispatch()

    const loadActivity = async () => {
        let params = {
            applicationId, studentId, results: 500, count: 500,
            sortField: "_id", sortOrder: "descend"
        }
        if (userId) {
            params.userId = userId;
        }
        if (documentType) {
            params.documentType = documentType;
        }
        const {data, userList, documentList} = await dispatch(activityListFxn(params))

        setDocumentList(documentList)
        setActivityUsers(userList)
        setActivities(data.data);
    }

    const filterInput = [
        {
            key: "userId",
            label: "User",
            span: "col-md-5",
            type: "select",
            value: userId,
            allowClear: true,
            options: activityUsers,
            placeholder: "Select User",
            keyAccessor: (option) => option._id,
            valueAccessor: (option) => option.name,
            onChange: (value) => {
                setUserId(value)
            }
        },
        {
            key: "documentType",
            label: "Document",
            span: "col-md-5",
            type: "select",
            value: documentType,
            allowClear: true,
            options: documentList,
            placeholder: "Select Document Type",
            keyAccessor: (option) => option,
            valueAccessor: (option) => option,
            onChange: (value) => {
                setDocumentType(value)
            }
        }
    ];


    const getTimelineCls = (data) => {
        if (data.documentType) {
            if (data.documentType == "Invoice") {
                return "timeline-point-success"
            } else {
                return "timeline-point-primary"
            }
        } else {
            return "timeline-point-info"
        }
    }

    const events = {
        searchData: () => {
            loadActivity()
        },
        clearData: () => {
            setUserId("")
            setDocumentType("")
            setTimeout(() => {
                loadActivity()
            }, 500)
        }
    }


    useEffect(() => {
        loadActivity()
    }, [applicationId, documentType, userId])
    return (
        <>
            <div className={'row mb-3'}>
                {filterInput.map((item) => (
                    <div className={item.span} key={item.key}>
                        <FilterEachFields
                            item={{
                                ...item,
                            }}
                        />
                    </div>
                ))}
            </div>


            <ul className="timeline mb-0 ml-0">
                {activities.map((activity) => (
                    <li key={activity._id} className="timeline-item timeline-item1 timeline-item-transparent">
                        <span
                            className={`timeline-point ${getTimelineCls(activity)}`}></span>
                        <div className="timeline-event lh-1">
                            <div className="timeline-header mb-0">
                                <h6 className="mb-1 fontsize-xs text-primary">
                                    {activity.title}
                                </h6>
                                <small className="text-muted fontsize-xs">{displayDate(activity.createdAt)}</small>
                            </div>
                            <small className="lh-1 fontsize-xs" style={{color: "#646e78"}}>
                                {activity.message}
                            </small>


                            <div className={'d-flex justify-content-between align-items-center mt-3 border-gray'}>
                                {activity.addedBy && (
                                    <div className="d-flex justify-content-between flex-wrap gap-2 mb-2">
                                        <div className="d-flex flex-wrap align-items-center mb-50 lh-1">
                                            <Avatar name={activity.addedBy.name} size={'xs'}/>
                                            <div className={'lh-1'}>
                                                <p className="mb-0 small fw-medium text-capitalize fontsize-xs text-gray">{activity.addedBy.name}</p>
                                                {activity.addedBy && activity.addedBy.userId && activity.addedBy.userId.email ?
                                                    <small className={'text-body'}>
                                                        {activity.addedBy.userId.email}
                                                    </small> : null}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {activity.document && activity.document.path && (
                                    <div className="d-flex align-items-center ">
                                        <div className="badge bg-lighter rounded d-flex align-items-center p-1">
                                            <a href={activity.document.path} target={'_blank'}>
                                                <i className={'bx bxs-file-pdf bx-xs mr-1'}/>
                                                <span className="h6 mb-0 text-body fontsize-xxs">
                                                    {documentSubName(activity.document.fileName)}
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    )
}
export default ActivitiesComponent
