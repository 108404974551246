import React from "react";
import Logo from '../../logo-6.png';
import Header from '../header/index'
import Footer from '../../layout/MainLayout/footer'

const ContactUs = (props) => {
    return (
        <>
            <div id="content" className="page-section nopadding">
                <div
                    className="wpb_row vc_row-fluid full-width-section section  section-no-parallax  stretch"
                    data-speed={1} style={{}}>
                    <div className="col span_12 color-dark left">
                        <div className="vc_col-sm-12 wpb_column column_container col no-padding color-dark"
                             style={{}} data-animation data-delay>
                            <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper">
                                        <p>

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="wpb_row vc_row-fluid standard-section section  section-no-parallax  stretch   "
                    data-speed={1} style={{paddingTop: '60px', paddingBottom: '60px'}}>
                    <div className="col span_12 color-dark text-align-center">
                        <div className="vc_col-sm-4 wpb_column column_container col no-padding color-dark"
                             style={{}} data-animation data-delay>
                            <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper">
                                        <p><img decoding="async" className="alignnone wp-image-16800"
                                                src="../wp-content/uploads/2016/12/icon1-5.png" width={70}
                                                height={70}
                                                srcSet="https://gastownbusinesscollege.ca/wp-content/uploads/2016/12/icon1-5.png 140w, https://gastownbusinesscollege.ca/wp-content/uploads/2016/12/icon1-5-80x80.png 80w"
                                                sizes="(max-width: 70px) 100vw, 70px"/><br/>
                                            <strong>Phone</strong><br/>
                                            604-642-0411</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vc_col-sm-4 wpb_column column_container col no-padding color-dark"
                             style={{}} data-animation data-delay>
                            <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper">
                                        <p><img decoding="async" className="alignnone wp-image-16696"
                                                src="../wp-content/uploads/2016/12/icon2-5.png" width={70}
                                                height={70}
                                                srcSet="https://gastownbusinesscollege.ca/wp-content/uploads/2016/12/icon2-5.png 140w, https://gastownbusinesscollege.ca/wp-content/uploads/2016/12/icon2-5-80x80.png 80w"
                                                sizes="(max-width: 70px) 100vw, 70px"/><br/>
                                            <strong>Email</strong><br/>
                                            info@gbcol.ca</p>
                                        <h5><img loading="lazy" decoding="async"
                                                 className="alignnone wp-image-17127"
                                                 src="../wp-content/uploads/2016/12/flag_ca.jpg" alt=""
                                                 width={20} height={14}/> English&nbsp;&nbsp;&nbsp;<img
                                            loading="lazy" decoding="async"
                                            className="alignnone wp-image-17119"
                                            src="../wp-content/uploads/2016/12/flag_ko-1.jpg" alt=""
                                            width={20} height={14}/> 한국어&nbsp; &nbsp;<img loading="lazy"
                                                                                          decoding="async"
                                                                                          className="alignnone wp-image-17118"
                                                                                          src="../wp-content/uploads/2016/12/flag_jp-1.jpg"
                                                                                          alt="" width={21}
                                                                                          height={15}/> 日本語&nbsp; &nbsp;
                                            <img loading="lazy" decoding="async" className
                                                 src="../wp-content/uploads/2023/09/mexico-flag-11530974388wrwxt0hsqu.png"
                                                 width={23} height={13}/>&nbsp;Spanish</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vc_col-sm-4 wpb_column column_container col no-padding color-dark"
                             style={{}} data-animation data-delay>
                            <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper">
                                        <p><img loading="lazy" decoding="async"
                                                className="alignnone wp-image-16697"
                                                src="../wp-content/uploads/2016/12/icon3-3.png" width={70}
                                                height={70}
                                                srcSet="https://gastownbusinesscollege.ca/wp-content/uploads/2016/12/icon3-3.png 140w, https://gastownbusinesscollege.ca/wp-content/uploads/2016/12/icon3-3-80x80.png 80w"
                                                sizes="(max-width: 70px) 100vw, 70px"/><br/>
                                            Suite 200-73 Water Street,<br/>
                                            Vancouver, BC,<br/>
                                            Canada, V6B 1A1</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
}

export default ContactUs;




