import React, {useEffect, useState} from "react";
import {Drawer, Form, notification, Button} from "../../../components/Elements/appUtils";
import {useDispatch} from "react-redux";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {regenerateInvoiceFxn, singleInvoiceFxn} from "../actions/index";
import moment from "moment";

const {Item: FormItem} = Form;

const RegenerateInvoiceDrawer = (props) => {
    const {
        form: {getFieldDecorator, getFieldError, setFieldsValue, getFieldsValue},
        onClose,
        visible,
        existingInvoice,
        applicationId,
        onSubmit
    } = props;
    const dispatch = useDispatch();
    const [state, setState] = useState({
        invoiceAmount: "",
        invoiceDate: undefined,
        dueDate: undefined,
        applicationFee: "",
        materialFee: "",
        studentArchivingFee: "",
        promotion: "",
        commission: "",
        scholarship: "",
        totalOwingNet: "",
        totalOwing: ""
    });

    // Fetch invoice data on component mount or when applicationId changes
    const apiRequest = async () => {
        let resp = await dispatch(singleInvoiceFxn({applicationId: applicationId, invoiceId: existingInvoice._id}));
        if (resp) {
            const {
                applicationFee,
                materialFee,
                studentArchivingFee,
                promotion,
                commission,
                totalOwingNet,
                totalOwing,
                invoiceAmount,
                invoiceDate,
                dueDate
            } = resp;
            setState(prevState => ({
                ...prevState,
                applicationFee,
                materialFee,
                studentArchivingFee,
                promotion,
                commission,
                totalOwingNet,
                totalOwing,
                invoiceAmount,
                scholarship: props.scholarship,
                invoiceDate: moment(invoiceDate),
                dueDate: moment(dueDate)
            }));
            setFieldsValue({
                applicationFee,
                materialFee,
                studentArchivingFee,
                promotion,
                commission,
                totalOwingNet,
                totalOwing,
                invoiceAmount,
                scholarshipAmount: resp.scholarshipAmount,
                invoiceDate: moment(invoiceDate),
                dueDate: moment(dueDate)
            });
        }
    };

    useEffect(() => {
        if (applicationId && existingInvoice?._id) {
            apiRequest();
        }
    }, [applicationId, existingInvoice]);

    // Update totalOwing calculations when form fields change
    useEffect(() => {
        const values = getFieldsValue([
            'invoiceAmount',
            'applicationFee',
            'materialFee',
            'studentArchivingFee',
            'promotion',
            'scholarshipAmount',
            'commission'
        ]);

        const totalOwingNet = (
            parseFloat(values.invoiceAmount || 0) +
            parseFloat(values.materialFee || 0) +
            parseFloat(values.applicationFee || 0) +
            parseFloat(values.studentArchivingFee || 0) -
            parseFloat(values.promotion || 0) -
            parseFloat(values.scholarshipAmount || 0) -
            parseFloat(values.commission || 0)
        ).toFixed(2);

        const totalOwing = (
            parseFloat(values.invoiceAmount || 0) +
            parseFloat(values.materialFee || 0) +
            parseFloat(values.applicationFee || 0) +
            parseFloat(values.studentArchivingFee || 0) -
            parseFloat(values.promotion || 0) -
            parseFloat(values.scholarshipAmount || 0)
        ).toFixed(2);

        setFieldsValue({totalOwingNet, totalOwing});
        setState(prevState => ({
            ...prevState,
            totalOwing
        }));
    }, [getFieldsValue()]);

    const handleInvoiceDateChange = (value) => {
        const dueDate = moment(value).add(10, 'days');
        setFieldsValue({invoiceDate: value, dueDate});
    };

    const inputSchema = [
        {
            key: "invoiceAmount",
            type: "text",
            label: "Invoice Amount",
            required: true,
            placeholder: "Enter Amount",
            span: 'col-md-6'
        },
        {
            key: "invoiceDate",
            type: "date",
            label: "Invoice Date",
            required: true,
            placeholder: "Select Invoice Date",
            span: 'col-md-6',
            onChange: handleInvoiceDateChange
        },
        {
            key: "dueDate",
            type: "date",
            label: "Due Date",
            required: true,
            placeholder: "Select Due Date",
            span: 'col-md-6',
            disabled: true
        },
        {
            key: "applicationFee",
            type: "text",
            label: "Application Fee",
            required: true,
            placeholder: "Enter Application Fee",
            span: 'col-md-6'
        },
        {
            key: "materialFee",
            type: "text",
            label: "Material Fee",
            required: true,
            placeholder: "Enter Material Fee",
            span: 'col-md-6'
        },
        {
            key: "studentArchivingFee",
            type: "text",
            label: "Student Archiving Fee",
            required: true,
            placeholder: "Enter Student Archiving Fee",
            span: 'col-md-6'
        },
        {
            key: "promotion",
            type: "text",
            label: "Promotion",
            required: true,
            placeholder: "Enter Promotion Amount",
            span: 'col-md-6'
        },
        {
            key: "scholarshipAmount",
            type: "text",
            label: "Scholarship",
            required: true,
            placeholder: "Enter Scholarship",
            span: 'col-md-6',
            hidden: !props.scholarship
        },
        {
            key: "commission",
            type: "text",
            label: "Commission",
            required: true,
            placeholder: "Enter Commission Amount",
            span: 'col-md-6'
        },

        {
            key: "totalOwingNet",
            type: "text",
            label: "Total Owing",
            required: true,
            placeholder: "Enter Total Owing Amount",
            span: 'col-md-6',
            disabled: true
        }
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        const {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.applicationId = applicationId;
                valData.invoiceId = existingInvoice._id;
                valData.totalOwing = state.totalOwing;
                valData.scholarship = state.scholarship;
                let resp = await dispatch(regenerateInvoiceFxn(valData));
                if (resp && resp.success) {
                    form.resetFields();
                    onSubmit();
                } else {
                    notification.error({
                        message: resp.message || "Error regenerating invoice.",
                    });
                }
            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    return (
        <Drawer onClose={onClose} visible={visible} title={'Regenerate Invoice'}>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    {inputSchema.map((item) => (
                        <div className={item.span} key={item.key}>
                            <GetEachFormFields
                                getFieldDecorator={getFieldDecorator}
                                getFieldError={getFieldError}
                                item={item}
                                onChange={item.onChange}
                            />
                        </div>
                    ))}
                </div>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form>
        </Drawer>
    );
};

export default Form.create()(RegenerateInvoiceDrawer);
