import React from "react"

const Footer = () => {
    return (
        <>
            <footer id="footer">
                <div className="container">
                    <div className="eight columns">
                        <div id="text-2" className="widget widget_text">
                            <div className="textwidget"><p><a href="/" target="_self"
                                                              rel="noopener"><img
                                src="../appAssets/images/footer_logo-2.png" width="200px"
                                height="auto"/></a><br/>
                                © 2017 GASTOWN BUSINESS COLLEGE. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                    <div className="eight columns">
                        <div id="text-3" className="widget widget_text">
                            <div className="textwidget"><p><i className="fa fa-globe"
                                                              aria-hidden="true"/>&nbsp; Suite 200-73 Water
                                Street, Vancouver, BC, Canada, V6B 1A1<br/>
                                <i className="fa fa-phone-square" aria-hidden="true"/>&nbsp; Phone: (604)
                                642-0411<br/>
                                <i className="fa fa-file" aria-hidden="true"/>&nbsp; Fax: (604) 642-0415<br/>
                                <i className="fa fa-envelope" aria-hidden="true"/>&nbsp; Email:
                                info@gbcol.ca<br/>
                                <a href="https://www.linkedin.com/company/gastown-business-college"
                                   target="_blank" rel="noopener"><img
                                    src="../appAssets/images/linkdinIcon.png" width="46px"
                                    height="auto"/></a> &nbsp;<a
                                    href="https://www.instagram.com/gastownbusinesscollege/" target="_blank"
                                    rel="noopener"><img src="../appAssets/images/instaIcon.png"
                                                        width="46px" height="auto"/></a>&nbsp;
                                Follow us <i>!</i></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer
