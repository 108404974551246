import {connect} from 'react-redux'
import _ from "lodash";

let withRightUsers = ['university', 'marketingManager', 'agent']
const checkRightInner = (data) => {
    let {route, rights, type} = data;
    let correspondingRight = _.find(rights, (item) => {
        return item.type === type
    })
    if (correspondingRight) {
        return correspondingRight[route.rightType]
    } else {
        return true
    }
}

export const checkUserRight = (data) => {
    let {user, rights, route} = data;
    if (withRightUsers.includes(user.userType)) {
        return true
    } else {
        if (rights && rights.length) {
            return checkRightInner({...data, type: route.right})
        } else {
            return false
        }
    }
}

const UserRightComponent = (props) => {
    let {currentUser: user, rights, route, children, userTypes = []} = props;
    const checkRightInner = (type) => {
        let correspondingRight = _.find(rights, (item) => {
            return item.type === type
        })
        if (correspondingRight) {
            return correspondingRight[route.rightType]
        } else {
            return true
        }
    }

    const checkFxn = () => {
        let condition = true;
        if (withRightUsers.includes(user.userType)) {
            if ((userTypes && userTypes.length)) {
                return (userTypes.includes(user.userType) || user.userType == 'university') ? condition : null
            } else {
                return condition
            }
        } else {
            if (rights && rights.length) {
                condition = checkRightInner(route.right)
            } else {
                condition = false;
            }
            return condition
        }
    }

    return (
        <>
            {checkFxn() ? children : null}
        </>
    )
}
const mapStateToProps = ({global}) => ({
    currentUser: global.currentUser,
    rights: global.rights
})
export default connect(
    mapStateToProps,
    null
)(UserRightComponent)
