import React from "react"
import {useNavigate} from "react-router-dom";
import {menuData} from "../../routes/menu";
import {useDispatch} from "react-redux";
import UserRightComponent from "../../userRights";

const SingleMenu = (props) => {
    let {menu, events, pathname} = props;
    return !menu.dontShowOnMenu ? (
        <>
            <UserRightComponent route={menu}>
                <li className={`menu-item ${pathname == menu.path ? 'active' : ""}`}>
                    <a onClick={() => events.openLink(menu.path)} className="menu-link">
                        <i className={`menu-icon tf-icons bx ${menu.icon}`}/>
                        <div className="text-truncate">{menu.name}</div>
                    </a>
                </li>
            </UserRightComponent>
        </>
    ) : null;
};
const NestedMenu = (props) => {
    let {menu, events} = props;
    let menuKey = `ui-${menu.key}`;
    return (
        !menu.dontShowOnMenu ? <>
            <UserRightComponent route={menu}>

                <li className="menu-item" key={menuKey}>
                    <a href="javascript:void(0);" className="menu-link menu-toggle">
                        <i className="menu-icon tf-icons bx bx-home-smile"/>
                        <div className="text-truncate" data-i18n="Dashboards">{menu.name}</div>
                    </a>
                    <ul className="menu-sub">

                        {menu.subMenu.map((child) => {
                            return !child.dontShowOnMenu ? (
                                events.checkAuth(child) ? <div key={child.key}>
                                    <UserRightComponent route={child}>
                                        {child.type == 'link' ?
                                            <li className="menu-item active" key={child.key}>
                                                <a href={child.path} className="menu-link"
                                                   onClick={() => events.openLink(child.path)}>
                                                    <div className="text-truncate"> {child.name}</div>
                                                </a>
                                            </li> :
                                            <li className="menu-item active" key={child.key}>
                                                <a className="menu-link" onClick={() => events.openLink(child.path)}>
                                                    <div className="text-truncate"> {child.name}</div>
                                                </a>
                                            </li>}
                                    </UserRightComponent>
                                </div> : null
                            ) : null;
                        })}
                    </ul>
                </li>
            </UserRightComponent>
        </> : null
    );
};


const SideMenu = (props) => {
    let {currentUser: user} = props;
    let dispatch = useDispatch()
    const navigate = useNavigate();
    let {pathname} = window.location;
    let events = {
        openLink: (path) => {
            navigate(path);
        },
        checkAuth: (data) => {
            return data && data.authority && data.authority.length
                ? data.authority.includes(user.userType)
                    ? true
                    : false
                : true;
        },
    };

    const logoutFxn = () => {
        localStorage.clear();
        setTimeout(() => {
            dispatch({
                type: "SET_CURRENT_USER",
                user: {},
            });
            window.location.href = "/login";
        }, 100);
    };
    return (
        <>
            <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
                <div className="app-brand demo ">
                    <a href="#" className="app-brand-link">
                        <span className="app-brand-logo demo">

                        </span>
                        <span className="app-brand-text demo menu-text fw-bold ms-2">GBC</span>
                    </a>
                    <a href="javascript:void(0);" className="layout-menu-toggle menu-link text-large ms-auto">
                        <i className="bx bx-chevron-left bx-sm d-flex align-items-center justify-content-center"/>
                    </a>
                </div>
                <div className="menu-inner-shadow"/>
                <ul className="menu-inner py-1">
                    {/* Dashboards */}

                    {menuData.map((item) => {
                        let {subMenu = []} = item;
                        let checkM = ((subMenu && subMenu.length ? (
                            <NestedMenu menu={item} events={events} key={item.key} pathname={pathname}/>
                        ) : (
                            <SingleMenu menu={item} events={events} key={item.key} pathname={pathname}/>
                        )));
                        return events.checkAuth(item) ? checkM : null;
                    })}

                </ul>
            </aside>
        </>
    )
}
export default SideMenu
