import React, {useState, useRef, useEffect} from 'react';
import {
    Table,
    PopConfirm,
    Card,
    displayDate,
    Avatar,
    Tooltip
} from '../../../components/Elements/appUtils';
import resolveDrawer from "../drawer/resolveDrawer";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {complaintListFxn} from "../action";
import {DatePicker} from "../../../components/Elements/appUtils";

import ResolveDrawer from "../drawer/resolveDrawer";
import moment from "moment";

let {TableCard} = Card
const {RangePicker} = DatePicker
const ComplaintList = (props) => {
    let {user} = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [complaintId, setComplaintId] = useState('')
    const [total, setTotal] = useState(0)
    const [dateVal, setDateVal] = useState()
    const tableRef = useRef();
    let [resolveState, setResolveState] = useState({
        visible: false,

    })
    const events = {
        showResolveDrawer: async (id) => {
            setComplaintId(id)
            setResolveState({
                visible: true,
            })
        },
        hideResolveDrawer: async () => {
            setResolveState({
                visible: false,
            })
        },
        reloadTable: () => {
            if (tableRef && tableRef.current) {
                tableRef.current.reload()
            }
        }
    };
    const initialState = {
        companyName: "",
        firstName: "",
        courseName: "",
        intake: "",
        isEsl: "",
        startDate: "",
        endDate: "",
        expirationDate: "",
        issueDate: "",
        marketingManagerName: "",
        studentType: "",
        studentId: null,
        student: "",
        status: "",
    };


    const filterInput = [
        {
            key: "relatedTo",
            label: "Type",
            type: "select",
            options: [
                {label: 'Student', value: 'student'},
                {label: 'Complaint a Misbehaviour', value: 'Complaint a Misbehaviour'},
                {label: 'Others', value: 'others'}
            ],
            span: "col-md-3",
            keyAccessor: (option) => option.value,
            valueAccessor: (option) => option.label
        },
        {
            key: "status",
            label: "Status",
            type: "select",
            options: [
                {label: 'Pending', value: 'Pending'},
                {label: 'Resolved', value: 'Resolved'}
            ],
            span: "col-md-3",
            keyAccessor: (option) => option.value,
            valueAccessor: (option) => option.label
        },
        {
            key: "createdAt",
            label: "Date",
            type: "date",
            span: "col-md-3",
            placeholder: "Choose Date",
            getValue: (value) => {
                setDateVal(value._d)
            }
        },
    ];

    const columns = [
        {
            title: 'Sr. No.', key: 'serialNumber', render: (text, record, index) => {
                return (
                    <>
                        {index + 1}.
                    </>
                )
            }
        },
        {
            title: 'Type', dataIndex: 'relatedTo',
            key: 'relatedTo',
            width: 90
        },
        {
            title: 'Student/Person Name', dataIndex: 'studentId',
            key: 'studentId',

            render: (item, record) => {


                return (
                    <>

                        {record.personName && (
                            <>
                                <div className="d-flex justify-content-start align-items-center user-name mb-1">

                                    <div className={`avatar avatar-xs me-2 `}>
                <span className={`avatar-initial rounded-circle bg-label-primary`} style={{border: "none"}}>
                     <i className={'bx bx-user bx-sm me-2 ml-2 '}/>
                </span>
                                    </div>

                                    <div className="d-flex flex-column">
                                        <a className={'align-left cursor-pointer'}
                                        >
                                            {record.personName}</a>
                                    </div>
                                </div>
                            </>
                        )}
                        {record.studentId && (
                            <>
                                <div className="d-flex justify-content-start align-items-center user-name mb-1">

                                    <div className={`avatar avatar-xs me-2 `}>
                <span className={`avatar-initial rounded-circle bg-label-primary`} style={{border: "none"}}>
                     <i className={'bx bx-user bx-sm me-2 ml-2 '}/>
                </span>

                                    </div>
                                    <div className="d-flex flex-column">
                                        <a className={'align-left cursor-pointer'}
                                        >
                                            {record.studentId.firstName} {record.studentId.lastName}</a>
                                        <p className={'align-left cursor-pointer'}
                                        >
                                            {record.studentIdNo}</p>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )

            }
        },
        {
            title: 'Designation', dataIndex: 'designation',
            key: 'designation',

        },
        {
            title: 'Complaints',
            dataIndex: 'feedback',
            key: 'feedback',
            width: 250,

        },
        {
            title: 'Contact Person', dataIndex: 'name',
            key: 'name',

            render: (item, record) => {
                return (
                    <>
                        <div className="d-flex justify-content-start align-items-center user-name mb-1">

                            <div className={`avatar avatar-xs me-2 `}>
                <span className={`avatar-initial rounded-circle bg-label-primary`} style={{border: "none"}}>
                     <i className={'bx bx-user bx-sm me-2 ml-2 '}/>
                </span>
                            </div>

                            <div className="d-flex flex-column">
                                <a className={'align-left cursor-pointer'}
                                >
                                    {record.name}</a>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start align-items-center user-name ">

                            <div className={`avatar avatar-xs me-2 `}>
                <span className={`avatar-initial rounded-circle bg-label-primary`} style={{border: "none"}}>
                     <i className={'bx bx-phone bx-sm me-2 ml-2 '}/>
                </span>
                            </div>

                            <div className="d-flex flex-column">
                                <a className={'align-left cursor-pointer'}
                                >
                                    {record.contactNo}</a>
                            </div>
                        </div>
                    </>
                )
            }
        },
        {
            title: 'Complaint By',
            dataIndex: 'addedBy',
            key: 'addedBy',
            width: 250,
            render: (item) => {
                return (
                    <>
                        <div className="d-flex justify-content-start align-items-center user-name mb-1">

                            <div className={`avatar avatar-xs me-2 `}>
                <span className={`avatar-initial rounded-circle bg-label-primary`} style={{border: "none"}}>
                     <i className={'bx bx-user bx-sm me-2 ml-2 '}/>
                </span>
                            </div>

                            <div className="d-flex flex-column">
                                <a className={'align-left cursor-pointer'}
                                >
                                    {item.name}</a>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start align-items-center user-name mb-1">

                            <div className={`avatar avatar-xs me-2 `}>
                <span className={`avatar-initial rounded-circle bg-label-primary`} style={{border: "none"}}>
                     <i className={'bx bx-comment-dots email-icon bx-sm text-primary'}/>
                </span>
                            </div>

                            <div className="d-flex flex-column">
                                <a className={'align-left cursor-pointer'}
                                >
                                    {item.email}</a>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start align-items-center user-name mb-1">

                            <div className={`avatar avatar-xs me-2 `}>
                <span className={`avatar-initial rounded-circle bg-label-primary`} style={{border: "none"}}>
                     <i className={'bx bx-phone bx-sm me-2 ml-2 '}/>
                </span>
                            </div>

                            <div className="d-flex flex-column">
                                <a className={'align-left cursor-pointer'}
                                >
                                    {item.mobile}</a>
                            </div>
                        </div>
                        {/*<div className="table-alignment"><strong>Name:</strong> {item?.name}</div>*/}
                        {/*<div className="table-alignment"><strong>Email:</strong>{item?.email}</div>*/}
                        {/*<div className="table-alignment"><strong>Mobile:</strong> {item?.mobile}</div>*/}


                    </>
                );
            }
        },
        {
            title: 'Date', dataIndex: 'createdAt',
            key: 'createdAt',

            render: (item) => {
                return item ? displayDate(item) : null
            }
        },
        {
            key: 'status',
            title: 'status',
            dataIndex: 'status',

            render: (item, record) => {

                return (
                    <div>
                        <label
                            className={`badge ${record.status === 'Pending' ? 'bg-label-warning' : 'bg-label-success'}`}
                        > {record.status} </label>
                    </div>
                )
            }
        },
        {
            title: 'Action', dataIndex: 'action', key: 'action', render: (data, record) => {
                const showResolveButton = record.status !== 'resolved';
                return (
                    <>
                        {showResolveButton && (
                            <label
                                className="badge bg-label-primary"
                                onClick={() => {
                                    events.showResolveDrawer(record._id);
                                }}
                            >
                                Resolve
                            </label>
                        )}
                    </>
                )
            }
        },

    ];

    const initialColumns = [
        'serialNumber', 'feedback', 'name', 'createdAt', 'studentId', 'addedBy'
    ];

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            if (dateVal) {
                let date = {}
                date.$gte = moment(dateVal).startOf('day')
                date.$lte = moment(dateVal).endOf('day')
                params.createdAt = date
                delete params.dateVal
            }
            const regExFilters = ["relatedTo", "status"];
            let resp = await dispatch(complaintListFxn({...params, regExFilters}));
            setTotal(resp.total)
            setDateVal('')
            resolve(resp);
        });
    };
    return (
        <>
            <TableCard title={`Complaint List (Total: ${total})`} actions={(
                <>
                    {user && user.userType == "agent" ? <a className="btn btn-label-primary" onClick={() => {
                        navigate(`/add-complaint`)
                    }}>
                        <span className={'tf-icons bx bx-plus bx-18px me-2'}></span>
                        Add Complaint
                    </a> : null}
                </>
            )}>

                <Table
                    filterFields={filterInput}
                    apiRequest={apiRequest}
                    columns={columns} bordered ref={tableRef}/>
            </TableCard>


            {resolveState.visible ?
                <ResolveDrawer
                    {...resolveState}
                    complaintId={complaintId}
                    reloadTable={events.reloadTable}
                    onClose={() => {
                        events.hideResolveDrawer()
                    }}

                /> : null}

        </>
    );
};

export default ComplaintList;
