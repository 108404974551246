import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Form, notification} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {courseListFxn} from "../../course/action";
import axios from "axios";
import {apiUrl} from "../../../settings";
import {getToken} from "../../../request";
import moment from "moment";
import _ from "lodash";
import {conditionOptions} from "../../../components/_utils/_utils";
import {getAllAgentFxn} from "../../manager/action";
import {addActivityFxn, updateCourseFxn} from "../actions";

const initState = {
    courseName: "",
    courseCode: "",
    duration: "",
    intake: [],
    startDate: undefined,
    endDate: undefined,
    description: "",
};

const CourseEdit = (props) => {
    const {form: {getFieldDecorator, getFieldError, setFieldsValue}} = props;
    let {courseId, applicationId, onSubmit} = props;
    const [activityCourse, setActivityCourse] = useState({initialCourseName: "", finalCourseName: ""});
    const [courseList, setCourseList] = useState([]);
    const [intakeList, setIntakeList] = useState([]);
    const [courseData, setCourseData] = useState(initState);
    let [intake, setIntake] = useState([])
    const [courseInfo, setCourseInfo] = useState({courseName: "", courseCode: "", courseId: ''})
    const user = JSON.parse(localStorage.getItem('user'));
    const events = {
        loadCourseData: async (courseId) => {
            try {
                let params = {courseId};
                const {data} = await axios.get(`${apiUrl}/course/get`, {params, headers: getToken()});
                if (data && data.course) {
                    setCourseData(data.course);
                    setFieldsValue({
                        courseName: data.course.courseName,
                        courseCode: data.course.courseCode,
                        duration: data.course.duration,
                        description: data.course.description,
                        intake: data.course.intake.map(intake => `${intake.month}-${intake.year}`),
                        startDate: moment(data.course.startDate),
                        endDate: moment(data.course.endDate),
                    });
                    setIntakeList(data.course.intake);
                }
            } catch (error) {
                console.error("Failed to load course data:", error);
            }
        },

        chooseIntake: (value) => {
            let findIntake = _.find(intakeList, (item) => {
                return item && item.value == value
            })
            if (findIntake) {
                setFieldsValue({
                    intake: value,
                    startDate: findIntake.startDate ? moment(findIntake.startDate) : undefined,
                    endDate: findIntake.endDate ? moment(findIntake.endDate) : undefined,
                    expirationDate: findIntake.startDate ? moment(findIntake.startDate).add(15, 'day') : undefined,
                })
            }
            if (value) {
                let intakeVal = value.split('-')
                let intake = {
                    month: intakeVal[0],
                    year: intakeVal[1]
                }
                setIntake(intake)
            } else {
                setIntake({})
            }
        },
        chooseCourse: (courseId) => {
            let findCourse = _.find(courseList, (item) => {
                return item && item._id == courseId
            })
            setFieldsValue({
                intake: "",
                startDate: undefined,
                endDate: undefined,
                expirationDate: undefined,
            });
            if (findCourse) {
                let data = []
                setCourseInfo({
                    courseName: findCourse.courseName,
                    courseCode: findCourse.courseCode,
                    courseId: courseId
                })
                setActivityCourse({...activityCourse, finalCourseName: findCourse.courseName});
                _.each(findCourse.intakeList, (item) => {
                    if (item) {
                        item.value = `${item.month}-${item.year}`
                        item.label = `${item.month}, ${item.year}`
                        data.push(item)
                    }
                })
                setIntakeList(data)
            }
            setFieldsValue({courseId: courseId})
        },
    };
    const listEvents = {
        loadCourseList: async () => {
            try {
                let params = {
                    count: 50,
                    results: 50
                }
                const {data} = await courseListFxn({params});
                const courseData = data.data.filter((item) => item._id === courseId);
                console.log(courseData[0]);
                setActivityCourse({...activityCourse, initialCourseName: courseData[0].courseName});
                setCourseList(data.data);
            } catch (error) {
                console.error("Failed to load course list:", error);
            }
        },
    }

    useEffect(() => {
        if (courseId) {
            events.loadCourseData(courseId);
        }
    }, [courseId]);
    useEffect(() => {
        listEvents.loadCourseList();
    }, [])

    const dispatch = useDispatch();

    const inputSchema = [
        {
            key: "courseId",
            label: "Course",
            type: "select",
            required: true,
            options: courseList.map((course) => ({
                label: course.courseName,
                value: course._id,
            })),
            span: "col-md-6",
            placeholder: "Select Course",
            keyAccessor: (option) => option.value,
            valueAccessor: (option) => option.label,
            onChange: (value) => {
                events.chooseCourse(value)
            },
        },
        {
            key: "intake",
            label: "Intake",
            type: "select",
            options: intakeList,
            span: "col-md-6",
            placeholder: "Select Intake",
            required: true,
            keyAccessor: x => x.value,
            valueAccessor: x => `${x.label}`,
            onChange: (x) => {
                events.chooseIntake(x)
            }
        },
        {
            key: "isEsl",
            label: "ESL",
            type: "radio",
            required: true,
            options: conditionOptions,
            span: "col-md-6",
            keyAccessor: (option) => option.value,
            valueAccessor: (option) => option.name,
            onChange: (value) => {
                setFieldsValue({
                    isEsl: value
                })
            },
        },
        {
            key: "issueDate",
            label: "Issue Date",
            required: true,
            type: "date",
            span: "col-md-6",
            placeholder: "Choose Issue Date",
            onChange: (value) => {
                setFieldsValue({
                    issueDate: value
                })
            },
        },
        {
            key: "startDate",
            label: "Start Date",
            type: "date",
            span: "col-md-4",
            placeholder: "Start Date",
            disabled: true,
        },
        {
            key: "endDate",
            label: "Completion Date",
            type: "date",
            span: "col-md-4",
            placeholder: "Completion Date",
            disabled: true,
        },
        {
            key: "expirationDate",
            label: "Expiration Date",
            type: "date",
            span: "col-md-4",
            placeholder: "Choose Expiration Date",
            disabled: true,
            onChange: (value) => {
                setFieldsValue({
                    expirationDate: value
                })
            },
        },
    ];
    useEffect(() => {
        if (props.record) {
            const {
                courseName,
                intake,
                startDate,
                endDate,
                isEsl,
                issueDate,
                expirationDate,
            } = props.record;

            setFieldsValue({
                courseId: courseName,
                intake: `${intake.month},${intake.year}`,
                startDate: moment(startDate),
                endDate: moment(endDate),
                isEsl,
                issueDate: moment(issueDate),
                expirationDate: moment(expirationDate),
            });
            setIntake(intake);
            setCourseInfo({
                courseName: props.record.courseName,
                courseCode: props.record.courseCode,
                courseId: props.record.courseId
            })
        }
    }, [props.record]);
    useEffect(() => {
        const intakeLoad = (courseId) => {
            let findCourse = _.find(courseList, (item) => {
                return item && item._id == courseId
            })
            if (findCourse) {
                let data = []
                setCourseInfo({
                    courseName: findCourse.courseName,
                    courseCode: findCourse.courseCode,
                    courseId: courseId
                })
                _.each(findCourse.intakeList, (item) => {
                    if (item) {
                        item.value = `${item.month}-${item.year}`
                        item.label = `${item.month}, ${item.year}`
                        data.push(item)
                    }
                })
                setIntakeList(data)
            }
        }
        if (courseList.length) {
            intakeLoad(courseId);
        }

    }, [courseList])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                try {
                    valData = {
                        ...valData, intake: intake,
                        courseName: courseInfo.courseName,
                        courseCode: courseInfo.courseCode, applicationId,
                        courseId: courseInfo.courseId
                    }
                    let data = await dispatch(updateCourseFxn(valData));
                    if (data && data.message) {
                        onSubmit();
                    }
                } catch (error) {
                    notification.error({message: "Failed to update course"});
                }
            } else {
                notification.warning({message: "Fill All Required Fields"});
            }
        });
    };

    return (
        <div className="row g-6">
            <div className="col-12">
                <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        {inputSchema.map((field, key) => (
                            <div className={`${field.span ? field.span : "col-md-6"} mb-3`} key={key}>
                                <GetEachFormFields
                                    getFieldDecorator={getFieldDecorator}
                                    getFieldError={getFieldError}
                                    {...props.form}
                                    item={field}
                                    keyAccessor={field.keyAccessor}
                                    valueAccessor={field.valueAccessor}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>

            </div>
        </div>
    );
};

export default Form.create()(CourseEdit);
