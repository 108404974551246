import React, {useState} from 'react';
import {Table, Card, displayDate, Avatar} from '../../components/Elements/appUtils'
import {applicationListFxn} from "./actions";
import {useDispatch} from "react-redux";
import StudentAction from "./components/studentAction";

let {TableCard} = Card;
const EslApplicationList = () => {
    const dispatch = useDispatch();
    const [total, setTotal] = useState(0)
    const columns = [
        {
            title: 'Sr. No.',
            key: 'serialNumber',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'firstName',
            render: (name, record) => {
                return (
                    <>
                        <StudentAction record={record}/>
                    </>
                )
            }
        },
        {
            title: 'Student Id', dataIndex: 'studentIdNo',
            key: 'studentIdNo'
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
        },

        {
            title: 'Course',
            dataIndex: 'courseName',
            key: 'courseName',
        },
        {
            title: 'Intake',
            dataIndex: 'intake',
            key: 'intake',
            render: (item) => {
                return (
                    <>
                        {item && item.month && item.year
                            ? `${item.month}, ${item.year}`
                            : ''}
                    </>
                )
            }
        },
        {
            title: 'ESL',
            dataIndex: 'isEsl',
            key: 'isEsl',
            render: (isEsl) => isEsl ? 'Yes' : 'No',
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (startDate) => displayDate(startDate),
        },
        {
            title: 'Completion Date',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (completionDate) => displayDate(completionDate),
        },
        {
            title: 'Expiration Date',
            dataIndex: 'expirationDate',
            key: 'expirationDate',
            render: (expirationDate) => displayDate(expirationDate),
        },
        {
            title: 'Issue Date',
            dataIndex: 'issueDate',
            key: 'issueDate',
            render: (issueDate) => displayDate(issueDate),
        },
        {
            title: 'Marketing Manager',
            dataIndex: 'marketingManagerName',
            key: 'marketingManagerName',
        },
    ];


    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.isEsl = true;
            let resp = await dispatch(applicationListFxn(params));
            setTotal(resp.total)
            resolve(resp)
        })
    }


    return (
        <>
            <div>
                <TableCard title={`ESL Applications (Total :${total})`}>
                    <Table apiRequest={apiRequest} columns={columns} bordered/>
                </TableCard>
            </div>
        </>
    );
};

export default EslApplicationList;
