import React, {Suspense, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {Avatar, Card, displayDate, Form, notification} from "../../../components/Elements/appUtils";
import {addActivityFxn, addNoteFxn, applicationUpdateAgentFxn, noteListFxn, updateAgentFxn} from "../actions";
import _ from "lodash";
import {getAllAgentFxn} from "../../manager/action";
import axios from "axios";
import {apiUrl} from "../../../settings";
import {getToken} from "../../../request";
import {GetEachFormFields} from "../../../components/_utils/formUtils";


const initState = {
    note: ""
}
const AgentEdit = (props) => {
    let {form: {getFieldDecorator, getFieldError, setFieldsValue}} = props
    let {agentId, applicationId, onSubmit} = props
    const [agentList, setAgentList] = useState([]);
    const [activityAgent, setActivityAgent] = useState('');
    const [agentIdBackend, setAgentIdBackend] = useState(agentId);
    const [marketingManagerList, setMarketingManagerList] = useState({});
    const user = JSON.parse(localStorage.getItem('user'));
    const events = {
        chooseAgent: (data) => {
            let obj = {
                agentId: data
            }
            let findAgent = _.find(agentList, (item) => {
                return item && item._id == data
            })
            setAgentIdBackend(data);
            if (findAgent && findAgent.marketingManager) {
                setActivityAgent(findAgent.name);
                obj.marketingManagerId = findAgent.marketingManager.name
                setMarketingManagerList(findAgent.marketingManager)
            }
            setFieldsValue({
                ...obj
            })
        }
    };
    const listEvents = {
        loadAgentList: async () => {
            let obj = {
                results: 500,
                count: 500,
                userType: "agent"
            }
            let resp = await getAllAgentFxn(obj);
            let findDoc = _.find(resp.data, (item) => {
                return item._id === agentId
            })
            if (findDoc) {
                setFieldsValue({
                    agentId: `${findDoc.name} (${findDoc.companyName})`,
                    marketingManagerId: findDoc.marketingManager.name
                })
                setActivityAgent(findDoc.name);
                setMarketingManagerList({userId: findDoc.marketingManager.userId, name: findDoc.marketingManager.name})
            }
            setAgentList(resp.data);
        }
    }
    useEffect(() => {
        listEvents.loadAgentList();
    }, []);
    const dispatch = useDispatch()
    const inputSchema = [
        {
            key: "agentId",
            label: "Agent",
            type: "select",
            options: agentList,
            span: "col-md-6",
            placeholder: "Select Agent",
            required: true,
            keyAccessor: (option) => option._id,
            valueAccessor: (option) => `${option.name} (${option.companyName})`,
            onChange: (value) => {
                events.chooseAgent(value)
            },
        },
        {
            key: "marketingManagerId",
            label: "Marketing Manager",
            type: "select",
            span: "col-md-6",
            disabled: true,
            placeholder: "Select Marketing Manager",
        },
    ]
    const handleSubmit = async (e) => {
        e.preventDefault();
        const {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                const obj = {
                    agentId: agentIdBackend,
                    marketingManagerName: marketingManagerList.name,
                    marketingManagerId: marketingManagerList.userId,
                    applicationId: applicationId
                }
                let data = await dispatch(updateAgentFxn(obj));
                if (data && data.message) {
                    onSubmit()
                }
            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };


    return (

        <>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    {inputSchema.map((field, key) => (
                        <div
                            className={`${field.span ? field.span : "col-md-6"}`}
                            key={key}>
                            <GetEachFormFields
                                getFieldDecorator={getFieldDecorator}
                                getFieldError={getFieldError}
                                {...props.form}
                                item={field}
                            />
                        </div>
                    ))}
                    <div className={'col-md-12 mt-5 mb-3 align-right'}>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </div>

            </form>

        </>


    )
}
export default Form.create()(AgentEdit);
