import {customAxios as axios, getToken} from "../../../request";
import {
    courseListUrl,
    deleteIntakeUrl,
    singleCourseUrl,
    updateCourseUrl,
    updateIntakeCourseUrl,
    updateIntakeUrl,
    addIntakeToCourseUrl
} from "../api";
import {addCourseUrl, intakeListUrl} from "../api";
import {hideLoader, showLoader} from "../../../actions/loader";
import {notification} from "../../../components/Elements/appUtils";

export const addCourseFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(addCourseUrl(), valData, getToken());

    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};

export const courseListFxn = async (filters) => {
    let config = {
        params: {...filters}
    }
    let {data} = await axios.get(courseListUrl(), config);
    return data;
};
export const singleCourseFxn = (valData) => async (dispatch) => {

    dispatch(showLoader())
    let {data} = await axios.post(singleCourseUrl(), valData);
    dispatch(hideLoader())
    return data;
};
export const updateIntakeCourseFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())

    let {data} = await axios.post(updateIntakeCourseUrl(),
        valData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
};
export const updateCourseFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())

    let {data} = await axios.post(updateCourseUrl(),
        valData, {

        });

    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
};
export const updateIntakeFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())

    let {data} = await axios.post(updateIntakeUrl(),
        valData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
};

export const intakeListFxn = (params) => async (dispatch) => {
    // dispatch(showLoader())
    let {data} = await axios.get(intakeListUrl(), {params});
    dispatch(hideLoader())
    return data.data
};
export const deleteIntakeFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())

    let {data} = await axios.post(deleteIntakeUrl(),
        valData, {

        });

    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
};

export const addIntakeToCourse = (valData) => async (dispatch) => {
    dispatch(showLoader())

    let {data} = await axios.post(addIntakeToCourseUrl(),
        valData);
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
};