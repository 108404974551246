import React, {useState} from "react";
import {Form, notification} from "../../components/Elements/appUtils";
import CountryJson from '../../assests/jsonFiles/countries.json';
import StatesJson from "../../assests/jsonFiles/states.json";
import HeaderImage from '../../assests/frontApp/imgs/head19-6.jpg';
import {addAgentFxn} from "./actions";
import {useDispatch} from "react-redux";
import _ from "lodash";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {useNavigate} from "react-router-dom";

const initState = {
    countryName: undefined,
    certificate: "",
    stateName: undefined,
    cityName: "",
    companyName: "",
    name: "",
    countryCode: "",
    mobile: "",
    pincode: "",
    email: "",
    address: "",
    referredBy: "",
};

const Registration = (props) => {
    let {form: {getFieldDecorator, getFieldError, setFieldsValue}} = props
    const [allStates, setAllStates] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const events = {
        loadState: (countryId) => {
            if (countryId) {
                let filterArr = _.filter(StatesJson, (item) => item.country_id === countryId);
                return filterArr;
            }
            return [];
        },
        chooseCountry: (name) => {
            let country = _.find(CountryJson, (item) => item.name === name);
            if (country) {
                setAllStates(country.id ? events.loadState(country.id) : []);
                setFieldsValue({countryName: name})
            }
        },
        chooseState: (e) => {
            const name = e.target.value;
            events._updateState({stateName: name});
        },
        submitHandle: async (e) => {
            e.preventDefault();
            let {form} = props;

            form.validateFields(async (err, values) => {
                if (!err) {
                    let fd = new FormData();
                    if (values.certificate && values.certificate.name) {
                        fd.append('certificate', values.certificate);
                    }
                    delete values.certificate
                    fd.append('obj', JSON.stringify(values));
                    let resp = await dispatch(addAgentFxn(fd));
                    if (resp && resp.success) {
                        form.setFieldsValue(initState);
                    }
                } else {
                    notification.warning({message: "Please enter all required fields."})
                }
            })
        },

    };

    const inputSchema = [
        {
            title: "Company Name",
            key: "companyName",
            type: "text",
            label: "Company Name",
            required: true,
            placeholder: "Enter company name",
            span: 'col-md-6'
        },
        {
            key: "name",
            type: "text",
            label: "Person Name",
            required: true,
            placeholder: "Enter person name",
            span: 'col-md-6'
        },
        {
            key: "countryName",
            type: "select",
            label: "Country",
            required: true,
            placeholder: "Select country",
            showSearch: true,
            span: 'col-md-6',
            options: CountryJson.map(country => country.name),
            onChange: (item) => {
                events.chooseCountry(item)
            }
        },
        {
            key: "stateName",
            type: "select",
            label: "State",
            required: true,
            placeholder: "Select state",
            span: 'col-md-6',
            showSearch: true,
            options: allStates.map(state => state.name),
            onChange: (item) => {
                setFieldsValue({stateName: item})
            }
        },
        {
            key: "cityName",
            type: "text",
            label: "City",
            required: true,
            placeholder: "Enter city",
            span: 'col-md-6'
        },
        {
            key: "pincode",
            type: "text",
            label: "Pin/Postal Code",
            required: true,
            placeholder: "Enter pin/postal code",
            span: 'col-md-6'
        },
        {
            key: "mobile",
            type: "phone",
            label: "Phone no",
            required: true,
            placeholder: "Enter mobile no",
            span: 'col-md-6',
            onChange: (value) => {
                setFieldsValue({mobile: value});
            }
        },

        {
            key: "email",
            type: "email",
            label: "Email",
            required: true,
            placeholder: "Enter email",
            span: 'col-md-6'
        },
        {
            key: "certificate",
            type: "file",
            label: "Certificate",
            required: true,
            span: 'col-md-6',
            onChange: (files) => {
                if (files && files.length) {
                    setFieldsValue({"certificate": files[0]});
                }
            }
        },
        {
            key: "referredBy",
            type: "text",
            label: "Refer Code",
            placeholder: "Enter refer code",
            span: 'col-md-6',
            required: true
        },
        {
            key: "address",
            type: "textarea",
            label: "Address",
            required: true,
            placeholder: "Enter address",
            span: 'col-md-12'
        },
    ];

    return (
        <>
            <div className="content-wrapper app-form">
                <div className="wrap form-wrap">
                    <div className="wrap register-form" style={{backgroundImage: `url(${HeaderImage})`, height: 350}}>
                        {/*<h2 className="title">Registration</h2>*/}
                    </div>
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="py-3 mb-4 register-title">
                            Registration
                        </h4>
                        <div className="row">
                            <div className="col-xl">
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <form onSubmit={events.submitHandle} encType="multipart/form-data">
                                            <div className="row">
                                                {inputSchema.map((item) => (
                                                    <div className={item.span} key={item.key}>
                                                        <GetEachFormFields
                                                            getFieldDecorator={getFieldDecorator}
                                                            getFieldError={getFieldError}
                                                            item={item}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                            <div className={'align-center mt-2'}>
                                                <button type="submit" className="btn btn-primary btn-lg mt-3">
                                                    Register Now
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className={'align-center'}>
                                        Already have an account ?&nbsp;
                                        <a className={'hyperLink hover'} onClick={() => {
                                            navigate(`/login`);
                                        }}>Login Here</a>
                                    </div>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Form.create()(Registration);
