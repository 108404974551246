import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Card, Form, notification} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import _ from "lodash";
import {customAxios as axios, getToken} from "../../request";
import {useParams} from "react-router-dom";
import {apiUrl} from "../../settings";
import StatesJson from "../../assests/jsonFiles/states.json";
import CountryJson from "../../assests/jsonFiles/countries.json";

import moment from "moment";
import {editUserFxn, getSingleUserFxn} from "../manager/action";

const initState = {
    name: "",
    mobile: "",
    postName: "",
    profile: null,
    address: "",
    countryName: "India",
    stateName: "",
    cityName: "",
    pincode: "",
};

const UpdateProfile = (props) => {
    let {form: {getFieldDecorator, getFieldError, setFieldsValue, getFieldValue}} = props;
    const dispatch = useDispatch();
    const [state, setState] = useState(initState);
    const [dataUpdated, setDataUpdated] = useState(false);
    const [department, setDepartment] = useState('');
    const [allStates, setAllStates] = useState([]);

    const params = useParams();
    const events = {

        _updateState: (data) => {
            setState((prevState) => ({
                ...prevState,
                ...data,
            }));
        },
        loadState: (countryId) => {
            if (countryId) {
                let filterArr = _.filter(StatesJson, (item) => item.country_id === countryId);
                return filterArr;
            }
            return [];
        },
        chooseCountry: (value) => {
            let country = _.find(CountryJson, (item) => item.name === value);
            if (country) {
                setFieldsValue({countryName: value})
                setAllStates(country.id ? events.loadState(country.id) : []);
            }
        },
        chooseState: (value) => {
            setFieldsValue({stateName: value})
        },
    };
    const inputTypes = [
        {
            key: "name",
            label: "Name",
            span: "col-md-6",
            placeholder: "Enter Name",
            required: true
        },
        {
            key: "mobile",
            type: "phone",
            label: "Phone No",
            required: true,
            placeholder: "Enter mobile no",
            span: 'col-md-6',
            onChange: (value) => {
                setFieldsValue({
                    mobile: value
                })
            },
        },
        {
            key: "postName",
            label: "Post Name",
            type: "text",
            span: "col-md-6",
            placeholder: "Enter Post Name"
        },
        {
            key: "profile",
            label: "Profile Pic",
            type: "file",
            span: "col-md-6",
            placeholder: "Choose Profile Pic"
        },
        {
            key: "countryName",
            type: "select",
            label: "Country",
            placeholder: "Select Country",
            span: "col-md-6",
            required: true,
            showSearch: true,
            options: CountryJson.map((country) => country.name),
            onChange: (value) => {
                events.chooseCountry(value)
            },
        },
        {
            key: "stateName",
            type: "select",
            label: "State",
            placeholder: "Select State",
            span: "col-md-6",
            showSearch: true,
            options: allStates.map((state) => state.name),
            onChange: (value) => {
                setFieldsValue({
                    stateName: value
                })
            },
        },
        {
            key: "cityName",
            label: "City",
            type: "text",
            span: "col-md-6",
            placeholder: "Enter City"
        },
        {
            key: "pincode",
            label: "Postal Code",
            type: "text",
            span: "col-md-6",
            placeholder: "Enter Postal Code"
        },
        {
            key: "address",
            label: "Address",
            type: "textarea",
            span: "col-md-12",
            placeholder: "Enter Address"
        }
    ];
    const handleSubmit = async (e) => {
        e.preventDefault();
        const {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                let fd = new FormData();
                const user = await JSON.parse(localStorage.getItem('user'))
                const {_id} = user;
                fd.append('obj', JSON.stringify({...valData, _id: _id,}));
                if (state.profile) {
                    fd.append('profile', state.profile);
                }

                let resp = await dispatch(editUserFxn(fd));
                let data = resp.data;

                if (resp && resp.success) {
                    form.setFieldsValue({
                        name: data.name,
                        mobile: data.mobile,
                        postName: data.postName,
                        profile: data.profile,
                        address: data.address,
                        countryName: data.countryName,
                        stateName: data.stateName,
                        cityName: data.cityName,
                        pincode: data.pincode
                    });

                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                });
            }
        });
    };


    useEffect(() => {
        const fetchData = async () => {
            const loginUser = await JSON.parse(localStorage.getItem('user'))
            const {_id} = loginUser;

            let {user: data} = await dispatch(getSingleUserFxn(_id));
            setFieldsValue({
                name: data.name,
                mobile: data.mobile,
                postName: data.postName,
                profile: data.profile,
                address: data.address,
                countryName: data.countryName,
                stateName: data.stateName,
                cityName: data.cityName,
                pincode: data.pincode,
                countryCode: data.countryCode
            });
            setState(prevState => ({
                ...prevState,
                ...data,
            }));
        };

        fetchData();
    }, []);

    return (
        <div className="row">
            <div className="col-xl">
                <Card title={'Profile'}>
                    <h6 className={'form-title'}>Basic Details</h6>
                    <Form onSubmit={handleSubmit}>
                        <div className={'row d-flex'}>
                            {inputTypes.map((item, key) => {
                                return !item.hidden ? (
                                    <div className={`${item.span ? item.span : "col-md-6"}`} key={key}>
                                        <GetEachFormFields
                                            getFieldDecorator={getFieldDecorator}
                                            getFieldError={getFieldError}
                                            {...props.form}
                                            item={{
                                                ...item,
                                                disabled: !['name', 'mobile', 'postName', 'profile', 'address', 'countryName', 'stateName', 'cityName', 'pincode'].includes(item.key)
                                            }}
                                            keyAccessor={item.keyAccessor}
                                            valueAccessor={item.valueAccessor}
                                            onChange={item.onChange}
                                        />
                                    </div>
                                ) : null;
                            })}
                        </div>
                        <div className={'row d-flex mt-5'}>
                            <div className={'col-md-12'}>
                                <button type={'submit'} className={'btn btn-primary'}>Update</button>
                            </div>
                        </div>
                    </Form>
                </Card>
            </div>
        </div>
    );
};

export default Form.create()(UpdateProfile);
