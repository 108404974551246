import React, {Suspense, useEffect} from "react";
import TopNavComponent from "./topMenu";
import {Navigate, Outlet} from "react-router-dom";
import Header from "../FrontLayout/GBC/header";
import Footer from "../FrontLayout/GBC/footer";

const AuthLayout = (props) => {
    let {currentUser} = props;

    return currentUser && currentUser._id ? (
        <Navigate replace to="/dashboard"/>
    ) : (
        <>
            <div
                className="page-template-default page page-id-16160 smooth-scroll wpb-js-composer js-comp-ver-4.12 vc_responsive">
                <div className="site-wrapper wrapall">
                    <Header/>
                    <div id="page-wrap">
                        <Outlet/>
                    </div>
                    <Footer/>
                </div>
            </div>
            <div id="back-to-top"><a href="#"><i className="fa fa-chevron-up"></i></a></div>
        </>
    );
};
export default AuthLayout;
