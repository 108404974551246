import Dashboard from "../containers/dashboard";
import AddMarketingManager from "../containers/manager/view/add";
import MarketingManagerList from "../containers/manager/view/list";
import AddCourse from "../containers/course/view/add";
import CourseList from "../containers/course/courseList";

import AgentsList from "../containers/agents";
import AddStudent from "../containers/student";
import RaiseAComplaint from "../containers/complaint/view/AddComplaint";
// import EditCourse from "../containers/course/view/edit";
import StudentList from "../containers/student/list";
import TranscriptAppList from "../containers/student/transcriptAppList";
import EslAppList from "../containers/student/eslAppList";
import AccountsAppList from "../containers/student/accountsAppList";
import TestComponent from "../containers/student/indexTest";
import EditUser from "../containers/manager/view/edit";
import Profile from "../components/profileComponent/profile";
import Team from "../components/profileComponent/note";
import Projects from "../containers/student/components/documents";
import Connections from "../components/profileComponent/Invoice";
import ProfileTab from "../containers/userProfile/profileTab";
import ChangePassword from "../containers/passwordChange/passwordChange";
import UpdateProfile from "../containers/profileUpdate/profileUpdate";
import EditCourse from "../containers/course/view/edit";
import EditStudent from "../containers/student/edit";
import InvoiceList from "../containers/Invoices/list";
import AddResource from "../containers/resources/add";
import ResourceList from "../containers/resources/list";
import IntakeList from "../containers/course/intakeList";
import CollegeMaterial from "../containers/resources/collegeMaterial";
import AddComplaint from "../containers/complaint/view/AddComplaint";
import ComplaintList from "../containers/complaint/view/list";
import {useDispatch} from "react-redux";
import DocumentCheckList from "../containers/student/components/documentCheckList"

const EmptyComponent = () => {

    return null
}
const LogoutComponent = () => {
    const dispatch = useDispatch();
        const logoutFxn = () => {
            localStorage.clear();
            setTimeout(() => {
                dispatch({
                    type: 'SET_CURRENT_USER',
                    user: {},
                });
                window.location.href = "/login";
            }, 100);
        };

        logoutFxn();
    return null;
};
export let menuData = [
    {
        name: "Dashboard",
        path: "/dashboard",
        key: "dashboard",
        icon: "bxs-dashboard",
        component: Dashboard,
        shortcut: true,
        authority: ["university", "marketingManager", "team", "agent"]
    },
    {
        name: "Team/BDM",
        path: "/tram-and-bdm-list",
        key: "marketingManagerList",
        icon: "bx-list-plus",
        component: MarketingManagerList,
        authority: ["university", 'team'],
        rightType: "view"
    },
    {
        name: "Add Team/BDM",
        path: "/add-team-and-bdm",
        key: "addMarketingManager",
        icon: "bxs-user-plus",
        component: AddMarketingManager,
        dontShowOnMenu: true,
        authority: ["university", 'team'],
        rightType: "add"
    },
    {
        name: "Edit User",
        path: "/editUser/:id",
        key: "editUser",
        icon: "bxs-user-plus",
        component: EditUser,
        dontShowOnMenu: true,
        authority: ["university", 'team'],
        rightType: "edit"
    },
    {
        name: "Courses",
        path: "/course-list",
        key: "courseList",
        icon: "bx-food-menu",
        component: CourseList,
        shortcut: true,
        right: "courses",
        authority: ["university", 'team'],
        rightType: "view"
    },
    {
        name: "Resource",
        path: "/resource-list",
        key: "resource",
        icon: "bxs-data",
        component: ResourceList,
        authority: ["university"]
    },
    {
        name: "Resource",
        path: "/add-resource",
        key: "resource",
        icon: "bx-list-plus",
        component: AddResource,
        dontShowOnMenu: true,
        authority: ["university"]
    },

    {
        name: "edit-course",
        path: "/edit-course/:_id",
        key: "edit-course",
        icon: "bx-list-plus",
        component: EditCourse,
        dontShowOnMenu: true,
        authority: ["university"],
        rightType: "edit"
    },
    {
        name: "Add Course",
        path: "/add-course",
        key: "addCourse",
        icon: "bxs-user-plus",
        component: AddCourse,
        dontShowOnMenu: true,
        authority: ["university"],
        rightType: "add"
    },

    {
        name: "Add Student",
        path: "/add-student",
        key: "addStudent",
        icon: "bxs-user-plus",
        component: AddStudent,
        dontShowOnMenu: true,
        authority: ["university", "marketingManager", "team", "agent"],
        rightType: "add",
        right: "student",

    },

    {
        name: "Edit Student",
        path: "/edit-student/:id",
        key: "editStudent",
        icon: "bxs-user-plus",
        component: EditStudent,
        dontShowOnMenu: true,
        authority: ["university", "marketingManager", "team", "agent"],
        rightType: "edit"
    },
    {
        name: "Students",
        path: "/student-list",
        key: "studentList",
        icon: "bxs-user-detail",
        component: StudentList,
        shortcut: true,
        right: "student",
        authority: ["university", "marketingManager", "team", "agent"],
        rightType: "view"
    },
    {
        name: "ESL",
        path: "/esl-list",
        key: "eslList",
        icon: "bx-pie-chart-alt-2",
        component: EslAppList,
        shortcut: true,
        right: "esl",
        authority: ["university", "marketingManager", "team", "agent"],
        rightType: "view"
    },
    // {
    //     name: "Transcripts",
    //     path: "/transcripts-list",
    //     key: "transcriptsList",
    //     icon: "bxl-typescript",
    //     component: TranscriptAppList,
    //     authority: ["university", "marketingManager", "team", "agent"],
    //     rightType: "view"
    // },
    {
        name: "Accounts",
        path: "/account-list",
        key: "accountsList",
        icon: "bx-check-shield",
        component: InvoiceList,
        shortcut: true,
        right: "accounts",
        authority: ["team", "marketingManager", "university", "agent"],
        rightType: "view"
    },
    {
        name: "Agents",
        path: "/agent-list",
        key: "agentList",
        icon: "bx-user",
        shortcut: true,
        right: "agents",
        component: AgentsList,
        authority: ["university", "marketingManager", "team"],
        rightType: "view"
    },


    {
        name: "Intakes",
        path: "/intake-list",
        key: "intakeList",
        icon: "bxs-cube-alt",
        component: IntakeList,
        authority: ["university", "marketingManager", "team", "agent"]
    },

    {
        name: "Settings",
        path: "/settings",
        key: "settings",
        icon: "bx-list-ul",

        subMenu: [
            {
                path: '/update-profile',
                name: 'Update Profile',
                title: 'Update Profile',
                key: 'updateProfile',
                component: UpdateProfile
            },
            {

                name: "Profile",
                path: "/profile",
                key: "updateProfile",
                icon: "bx-list-ul",
                component: ProfileTab,
                dontShowOnMenu: true,
            },
            {
                path: '/change-password',
                name: 'Change Password',
                title: 'Change Password',
                key: 'changePassword',
                component: ChangePassword
            },
        ]

    },

    {
        name: "Help",
        path: "/help",
        key: "help",
        icon: "bx-help-circle",
        component: EmptyComponent,
    },
    {
        name: "Raise A Complaint",
        path: "/raise-a-complaint",
        key: "raiseAComplaint",
        icon: "bx-info-circle",
        component: ComplaintList,
        authority: ["university", "agent"]
    },
    {
        name: "Raise A Complaint",
        path: "/add-complaint",
        key: "addComplaint",
        icon: "bx-list-ul",
        component: AddComplaint,
        dontShowOnMenu: true,
        authority: ["agent"]
    },

    {
        name: "College Material",
        path: "/college-material",
        key: "collegeMaterial",
        icon: "bx-buildings",
        component: CollegeMaterial,
    },
    {
        name: "Document CheckList",
        path: "/document-checklist",
        key: "documentCheckList",
        icon: "bx-buildings",
        component: DocumentCheckList,
        authority: ["university"]
    },
    {
        name: "Logout",
        path: "/logout",
        key: "logout",
        icon: "bx-log-out-circle",
        component: LogoutComponent
    },
    {
        name: "Add Component",
        path: "/test-component",
        key: "addComponent",
        icon: "bxs-user-plus",
        component: TestComponent,
        dontShowOnMenu: true,
    },


];
