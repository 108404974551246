import React from "react"
import {Modal} from "../../../components/Elements/appUtils";
import NotesComponent from "../components/notes";

const NoteModal = (props) => {
    let {visible, onClose} = props;
    return (
        <Modal
            visible={visible}
            onClose={onClose}
            width={'50%'}
            placement='right'
            title={(
                <h5 className="card-action-title mb-0">
                    <i className='bx bx-bar-chart-alt-2 bx-lg text-body me-4'></i>
                    <b>Notes</b>
                </h5>
            )}>
            <NotesComponent {...props}/>
        </Modal>
    )
}
export default NoteModal
