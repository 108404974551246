import {apiUrl} from "../../../settings"

export const addComplaintUrl = () => {
    return apiUrl + '/complaint/add'
}

export const ComplaintListUrl = () => {
    return apiUrl + '/complaint/list'
}
export const resolveComplaintUrl = () => {
    return apiUrl + '/complaint/resolveComplaint'
}
