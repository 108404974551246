import {apiUrl} from "../../../settings";

export const loginSendOtpUrl = () => {
    return apiUrl + "/login/sendOtp"
}

export const loginSubmitOtpUrl = () => {
    return apiUrl + "/login/submitOtp"
}
export const resendOtpUrl = () => {
    return apiUrl + "/login/resendOtp"
}
