import React, {useEffect, useState} from "react"
import 'react-phone-input-2/lib/style.css'
import {useDispatch} from "react-redux";

import {Card, Form, notification} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import _ from "lodash"
import {addResourceFxn} from "./action";

const initState = {
    name:"",
    document: null,
};



const AddResource = (props) => {
    let {form: {getFieldDecorator, getFieldError, setFieldsValue, getFieldValue}} = props
    const dispatch = useDispatch();
    const [state, setState] = useState(initState);
    const events = {
        _updateState: (data) => {
            setState((prevState) => ({
                ...prevState,
                ...data,
            }));
        },
    };

    let inputTypes = [

        {
            key: "name",
            label: "Name",
            placeholder: "Enter Name",
            required: true
        },


        {
            key: "document",
            label: "File",
            type: "file",
            required: true,
            span: "col-md-6",
            onChange: (files) => {
                if (files && files.length) {
                    setFieldsValue({"document": files[0]});
                }
            }
        },
    ]

    const handleSubmit = async (e) => {
        e.preventDefault()
        const {form} = props
        form.validateFields(async (err, valData) => {

            if (!err) {
                let fd = new FormData();
                fd.append('obj', JSON.stringify({...valData}));
                if (valData.document && valData.document.name) {
                    fd.append('document', valData.document);
                }
                let resp = await dispatch(addResourceFxn(fd))
                if (resp && resp.success) {
                    setFieldsValue({
                        name: "",
                    });
                    form.setFieldsValue(initState)
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-xl">
                    <Card title={'Add Resource'}>

                        <Form onSubmit={handleSubmit}>
                            <div className={'row'}>
                                {inputTypes.map((item, key) => {
                                    return !item.hidden ? (
                                        <div className={`${item.span ? item.span : "col-md-6"}`}
                                             key={key}>
                                            <GetEachFormFields
                                                getFieldDecorator={getFieldDecorator}
                                                getFieldError={getFieldError}
                                                {...props.form}
                                                item={item}
                                                keyAccessor={item.keyAccessor}
                                                valueAccessor={item.valueAccessor}
                                            />
                                        </div>
                                    ) : null;
                                })}
                            </div>

                            <button type="submit" className="btn btn-primary">
                                Send
                            </button>
                        </Form>
                    </Card>
                </div>
            </div>

        </>
    )
}
export default Form.create()(AddResource);

