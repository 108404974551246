import React, { useEffect, useState } from "react";
import { Drawer, Form, notification } from "../../../components/Elements/appUtils";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../../components/_utils/formUtils";
import { addPaymentFxn, addInstallmentFxn } from "../actions";

const initState = {
    paymentMode: "Direct Payment",
    date: undefined,
    receivedByUser: "",
    details: "",
    transactionBrief: "",
    receiptUpload: undefined,
    invoiceId: "",
    studentId: "",
    applicationId: "",
    installmentDate: undefined,
    installmentAmount: "",
    installments: []
};

const InvoicePaymentDrawer = (props) => {
    const {
        form: { getFieldDecorator, getFieldError, setFieldsValue, getFieldValue },
        onClose,
        visible,
        invoiceId,
        studentId,
        applicationId
    } = props;
    const dispatch = useDispatch();
    const [state, setState] = useState({ ...initState, invoiceId, studentId, applicationId });
    const [fileName, setFileName] = useState("");

    useEffect(() => {
        setFieldsValue({ paymentMode: state.paymentMode });
        if (invoiceId || applicationId) {
            setState(prevState => ({ ...prevState, invoiceId, applicationId }));
        }
    }, [state.paymentMode, setFieldsValue, invoiceId, applicationId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { form } = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (state.paymentMode === "Pay In Installments") {
                    const installmentsData = {
                        ...valData,
                        invoiceId: state.invoiceId,
                        studentId: state.studentId,
                        applicationId: state.applicationId,
                        installments: [
                            {
                                installmentAmount: valData.installmentAmount,
                                installmentDate: valData.installmentDate
                            }
                        ]
                    };

                    let fd = new FormData();
                    fd.append('obj', JSON.stringify(installmentsData));

                    let resp = await dispatch(addInstallmentFxn(fd));

                    if (resp && resp.success) {
                        onClose();
                    } else {
                        notification.error({
                            message: "Error submitting installment.",
                        });
                    }
                } else {
                    const paymentData = {
                        ...valData,
                        invoiceId: state.invoiceId,
                        studentId: state.studentId,
                        applicationId: state.applicationId
                    };

                    let fd = new FormData();
                    fd.append('obj', JSON.stringify(paymentData));

                    let resp = await dispatch(addPaymentFxn(fd));

                    if (resp && resp.success) {
                        onClose();
                    } else {
                        notification.error({
                            message: "Error submitting payment.",
                        });
                    }
                }
            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    const handleRadioChange = (value) => {
        setState(prevState => ({...prevState, paymentMode: value}));
        setFieldsValue({paymentMode: value});
    };

    const handleFieldChange = (key, value) => {
        setState(prevState => ({...prevState, [key]: value}));
        setFieldsValue({[key]: value});
    };

    const sections = [
        {
            key: "paymentMode",
            label: "Payment Mode",
            type: "radio",
            required: true,
            options: [
                { label: "Direct Payment", value: "Direct Payment" },
                { label: "Online Payment", value: "Online Payment" },
                { label: "Pay In Installments", value: "Pay In Installments" },
            ],
            span: "col-md-12",
            placeholder: "Select Payment Mode",
            keyAccessor: (option) => option.value,
            valueAccessor: (option) => option.label,
            value: state.paymentMode,
            onChange: handleRadioChange,
        },
        ...(state.paymentMode === "Direct Payment" ? [
            {
                key: "date",
                label: "Date",
                type: "date",
                required: true,
                placeholder: "Select date",
                onChange: (date) => handleFieldChange("date", date),
            },
            {
                key: "receivedByUser",
                label: "Received By User",
                type: "input",
                required: true,
                placeholder: "Enter name",
                onChange: (e) => handleFieldChange("receivedByUser", e.target.value),
            },
            {
                key: "details",
                label: "Details",
                type: "textarea",
                placeholder: "Enter details",
                onChange: (e) => handleFieldChange("details", e.target.value),
            }
        ] : []),
        ...(state.paymentMode === "Online Payment" ? [
            {
                key: "date",
                label: "Date",
                type: "date",
                required: true,
                placeholder: "Select date",
                onChange: (date) => handleFieldChange("date", date),
            },
            {
                key: "transactionBrief",
                label: "Brief of Transaction",
                type: "textarea",
                required: true,
                placeholder: "Enter transaction brief",
                onChange: (e) => handleFieldChange("transactionBrief", e.target.value),
            },
            {
                key: "receiptUpload",
                label: "Receipt Upload",
                type: "file",
                // required: true,
                placeholder: "Upload receipt",
                onChange: (files) => {
                    if (files && files.length) {
                        setFileName(files[0].name);
                        setFieldsValue({ "receiptUpload": files[0] });
                    }
                }
            },
            {
                key: "details",
                label: "Details",
                type: "textarea",
                placeholder: "Enter details",
                onChange: (e) => handleFieldChange("details", e.target.value),
            }
        ] : []),
        ...(state.paymentMode === "Pay In Installments" ? [
            {
                key: "installmentDate",
                label: "Installment Date",
                type: "date",
                required: true,
                placeholder: "Select date",
                onChange: (date) => handleFieldChange("installmentDate", date),
            },
            {
                key: "installmentAmount",
                label: "Installment Amount",
                type: "input",
                required: true,
                placeholder: "Enter amount",
                onChange: (e) => handleFieldChange("installmentAmount", e.target.value),
            },
            {
                key: "details",
                label: "Details",
                type: "textarea",
                placeholder: "Enter details",
                onChange: (e) => handleFieldChange("details", e.target.value),
            },
            {
                key: "receiptUpload",
                label: "Receipt Upload",
                type: "file",
                // required: true,
                placeholder: "Upload receipt",
                onChange: (files) => {
                    if (files && files.length) {
                        setFileName(files[0].name);
                        setFieldsValue({ "receiptUpload": files[0] });
                    }
                }
            },
        ] : []),
    ];

    return (
        <Drawer onClose={onClose} visible={visible} title={'Pay Now'}>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    {sections.map((item, key) => (
                        !item.hidden &&
                        <div className={`${item.span ? item.span : "col-md-12"}`} key={key}>
                            <GetEachFormFields
                                getFieldDecorator={getFieldDecorator}
                                getFieldError={getFieldError}
                                {...props.form}
                                item={item}
                                keyAccessor={item.keyAccessor}
                                valueAccessor={item.valueAccessor}
                                value={item.value}
                                onChange={item.onChange}
                            />
                            {item.key === "receiptUpload" && fileName && (
                                <div className="file-info">
                                    <strong>Uploaded File:</strong> {fileName}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <hr className="my-6 mx-n6" />
                <button type="submit" className={`btn btn-primary`}>
                   Submit
                </button>
            </Form>
        </Drawer>
    );
};

export default Form.create()(InvoicePaymentDrawer);
