import React from "react";
import FooterLogo from '../../footer_logo-2.png';
import Linkedin from '../../assests/icons/linkedin.png'
import Facebook from '../../assests/icons/facebook.png'

const Footer = (props) => {
    return (
        <>
            <footer id="footer">
                <div className="container-lg row">
                    <div className="col-md-7">
                        <div id="text-2" className="widget widget_text col-md-6">
                            <div className="textwidget">
                                <p>
                                    <a href="/" target="_self" rel="noopener">
                                        <img src={FooterLogo} width="200px" height="auto" alt="Footer Logo"/>
                                    </a>
                                    <br/>
                                    © 2017 GASTOWN BUSINESS COLLEGE. All rights reserved.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div id="text-3" className="widget widget_text">
                            <div className="textwidget">
                                <p>
                                    <i className="fa fa-globe" aria-hidden="true"></i>&nbsp; Suite 200-73 Water Street, Vancouver, BC, Canada, V6B 1A1<br/>
                                    <i className="fa fa-phone-square" aria-hidden="true"></i>&nbsp; Phone: (604) 642-0411<br/>
                                    <i className="fa fa-file" aria-hidden="true"></i>&nbsp; Fax: (604) 642-0415<br/>
                                    <i className="fa fa-envelope" aria-hidden="true"></i>&nbsp; Email: info@gbcol.ca<br/>
                                    <a href="https://www.linkedin.com/company/gastown-business-college" target="_blank" rel="noopener">
                                        <img src={Linkedin} width="46px" height="auto" alt="LinkedIn"/>
                                    </a>
                                    &nbsp;
                                    <a href="https://www.instagram.com/gastownbusinesscollege/" target="_blank" rel="noopener">
                                        <img src={Facebook} width="46px" height="auto" alt="Instagram"/>
                                    </a>
                                    &nbsp;
                                    Follow us <i>!</i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div id="copyright" className="clearfix">
                <div className="container">
                    <div className="sixteen columns">
                        <div className="copyright-text copyright-col1">
                            Designed by <a href="http://vanplenetworks.com" target="_blank" rel="noopener">Vanple Networks</a>
                        </div>
                        <div className="col-12 col-sm-6 text-center text-sm-start">
                            &copy; All Right Reserved.
                        </div>
                    </div>
                </div>
            </div>
            <div id="back-to-top">
                <a href="#"><i className="fa fa-chevron-up"></i></a>
            </div>
        </>
    );
};

export default Footer;
