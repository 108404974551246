import {customAxios as axios, getToken} from "../../../request";
import {addComplaintUrl, ComplaintListUrl, resolveComplaintUrl} from "../api";

import {hideLoader, showLoader} from "../../../actions/loader";
import {notification} from "../../../components/Elements/appUtils";
import {studentListUrl} from "../../student/api";
import {addCourseUrl} from "../../course/api";

export const addComplaintFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(addComplaintUrl(), valData, await getToken());

    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};
export const complaintListFxn = (valData) => async (dispatch) => {
    // dispatch(showLoader())
    let {data} = await axios.get(ComplaintListUrl(), {params: valData})
    dispatch(hideLoader())
    return data.data;
}
export const resolveComplaintFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(resolveComplaintUrl(), valData, await getToken());

    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};
