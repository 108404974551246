import {customAxios as axios, getToken} from "../../../request";
import {loginSendOtpUrl, loginSubmitOtpUrl, resendOtpUrl} from "../apis";
import {showLoader, hideLoader} from "../../../actions/loader";
import {notification} from "../../../components/Elements/appUtils";
import {addAgentUrl, changePasswordUserUrl, getAgentsUrl, updateAgentUrl} from "../api";

export const loginSendOtpFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(loginSendOtpUrl(), valData)
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const getAgentFxn = (valData) => async (dispatch) => {
    // dispatch(showLoader())
    let config = {
        params: valData
    }
    let {data} = await axios.get(getAgentsUrl(), config);
    dispatch(hideLoader())
    return data.data;
};

export const updateAgentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(updateAgentUrl(), valData);
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
};

export const loginSubmitOtpFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(loginSubmitOtpUrl(), valData)
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
export const resendOtpFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(resendOtpUrl(), valData)
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
export const addAgentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(addAgentUrl(), valData);
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};


export const changePasswordUserFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(changePasswordUserUrl(), valData);
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};

