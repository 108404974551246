import React, { useState, useEffect, useRef } from 'react'

import { useDispatch } from 'react-redux'
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import debounce from 'lodash/debounce'
import moment from 'moment'
import {Card, Form, notification, Select} from "../../../components/Elements/appUtils";
import {conditionOptions, ConditionOptionsForRaiseAComplaint} from "../../../components/_utils/_utils";
import {addComplaintFxn} from "../action";
import { studentListFxn} from "../../student/actions";
import _ from "lodash";

const initialState = {
    name:'',
    designation:'',
    addStuDrawer: false,
    details: '',
    feedback:'',
    contactNo: ''
}
const AddComplaint = (props) => {
    const tableRef = useRef()
    const [state, setState] = useState(initialState)
    const [arrData, setArrData] = useState([])
    let {form: {getFieldDecorator, getFieldError, setFieldsValue, getFieldValue}} = props
    const dispatch = useDispatch()
    const fetchStudent = async (value) => {
        let params = {
            results: 20,
            count: 20,
            regExFilters: ["firstName","studentId", "studentIdNo"],
            select: ["firstName","studentId", "studentIdNo"],
        };

        if (value) {
            params.firstName = value;
        }
        if (value) {
            let {data} = await dispatch(studentListFxn(params));

            setArrData(data)
        } else {
            setArrData([]);
        }
    }
    const handleRelatedToChange = async (value) => {
        setFieldsValue({relatedTo: value});
    };
    let [clientId, setClientId] = useState(undefined);
    let [studentIdNumber, setStudentIdNumber] = useState('');
    const formFields = [

        {
            key: 'relatedTo',
            label: 'Is it related to?',
            required: true,
            type: 'select',
            showSearch: true,
            options: ConditionOptionsForRaiseAComplaint,
            keyAccessor: x => x.value,
            valueAccessor: x => `${x.name}`,
            onChange:handleRelatedToChange

        },
        {
            key: 'studentId',
            hidden: getFieldValue('relatedTo') != 'student',
            label: 'Choose Student',
            type: 'select',
            filterOption:false,
            autoClearSearchValue:true,
            allowClear:true,
            value:clientId,
            // onSearch: debouncedFetchStudent,
            onSearch: x => {
                fetchStudent(x)
            },
             showSearch: true,
            options: arrData,
            keyAccessor: x => x._id,
            valueAccessor: x => `${x.firstName}`,
            dropdownRender:(menu) => {
        return <div key={arrData}>{menu}</div>;
       },
        onChange: selectedValue => {
                const selectedStudent = arrData?.find(student => `${student._id}` == selectedValue);
                console.log(selectedStudent, "ssssssssssssss")
                if (selectedStudent) {
                    setClientId(selectedStudent.studentId)
                    setStudentIdNumber(selectedStudent.studentIdNo)
                }

            }
        },
        {
            key: 'addSpecificPerson',
            hidden: getFieldValue('relatedTo') == 'student' || getFieldValue('relatedTo') == undefined,
            required: getFieldValue('relatedTo') == 'complain a misbehaviour' || getFieldValue('relatedTo') == 'others',
            label: 'Do you want to add a specific person\'s name?',
            type: 'select',
            showSearch: true,
            options: conditionOptions,
            keyAccessor: x => x.value,
            valueAccessor: x => `${x.name}`,
            onChange: x => {
                setFieldsValue({
                    addSpecificPerson: x
                })
            }
        },
        {
            key: 'personName',
            label: 'Person Name',
            hidden: !getFieldValue('addSpecificPerson'),
            required: getFieldValue('addSpecificPerson')
        },
        {
            key: 'name',
            label: 'Your Name',
            required: true
        },
        {
            key: 'designation',
            label: 'Your Designation',
            required: true
        },
        {
            key: 'contactNo',
            label: 'Your Contact',
            required: true
        },
        {
            key: 'feedback',
            label: 'Complaint',
            type: 'textarea',
            required: true,
            span: 24
        }
    ]
    const handleSubmit = (e) => {
        const { form } = props
        e.preventDefault()
        form.validateFields(async (err, valData) => {
            if (!err) {
                if(valData.relatedTo ===  'student'){
                    valData.studentId = clientId
                    valData.studentIdNo = studentIdNumber
                }
                if (valData.relatedTo == 'student' && !valData.studentId) {
                    notification.warning({ message: 'Please Choose Student!' })
                    return { checkError: true }
                }
                let resp = await dispatch(addComplaintFxn(valData))
                if (resp && resp.success) {
                    form.resetFields();
                    form.setFieldsValue(initialState)
                    setState(initialState);
                    setClientId('')
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }

    return (
        <div className="row">
            <div className="col-xl">
                <Card title={'Raise A Complaint'}>

                    <Form onSubmit={handleSubmit}>
                        <div className={'row'}>
                            {formFields.map((item, key) => {
                                return !item.hidden  ? (
                                    <div className={`${item.span ? item.span : "col-md-6"}`}
                                         key={key}>
                                        <GetEachFormFields
                                            getFieldDecorator={getFieldDecorator}
                                            getFieldError={getFieldError}
                                            {...props.form}
                                            item={item}
                                            keyAccessor={item.keyAccessor}
                                            valueAccessor={item.valueAccessor}
                                        />
                                    </div>
                                ) : null;
                            })}
                        </div>
                        <button type="submit" className="btn btn-primary mt-3">
                            Send
                        </button>
                    </Form>
                </Card>
            </div>
        </div>
    )
}

export default Form.create()(AddComplaint)
