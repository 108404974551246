import React, {useState, useEffect} from "react";
import {
    DatePicker,
    Drawer,
    Card,
    Form,
    notification,
    Table,
    Button,
    displayDate
} from "../../components/Elements/appUtils";
import {useDispatch} from "react-redux";
import _ from "lodash";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {months, getIntakeYear, conditionOptions} from "../../components/_utils/_utils";
import {addMarketingManagerFxn} from "../manager/action";
import {
    courseListFxn,
    singleCourseFxn,
    updateCourseFxn,
    updateIntakeCourseFxn,
    updateIntakeFxn
} from "../course/action";
import moment from "moment";
import {Switch} from "../../components/Elements/appUtils";

const initState = {
    month: "",
    year: "",
    startDate: "",
    endDate: "",
    secondYearStartDate: "",
    secondYearEndDate: "",
    academySchedule: ""

};
let {TableCard} = Card;
const EditIntakeDrawer = (props) => {
    let dispatch = useDispatch();
    let {form: {getFieldDecorator, getFieldError, setFieldsValue}} = props

    let {visible, onClose, records, pathway, onSubmit, courseId, singleIntake, intakeId} = props;


    const [state, setState] = useState(initState);
    const [image, setImage] = useState(null);
    const events = {
        _updateState: (data) => {
            setState((prevState) => ({
                ...prevState,
                ...data,
            }));
        },
    };

    const setDefaultValues = () => {
        setTimeout(() => {
            setFieldsValue({
                month: singleIntake.month ? singleIntake.month : false,
                year: singleIntake.year ? singleIntake.year : false,
                active: singleIntake.active ? singleIntake.active : false,
                endDate: singleIntake.endDate ? moment(singleIntake.endDate) : '',
                startDate: singleIntake.startDate ? moment(singleIntake.startDate) : '',
                secondYearStartDate: singleIntake.secondYearStartDate ? moment(singleIntake.secondYearStartDate) : '',
                secondYearEndDate: singleIntake.secondYearEndDate ? moment(singleIntake.secondYearEndDate) : '',
            })
        }, 500)
    }
    useEffect(() => {
        setDefaultValues()
    }, [singleIntake])

    const handleSubmit = async (e) => {
        e.preventDefault();
        let {form} = props;
        form.validateFields(async (err, valData) => {

            if (!err) {

                if (pathway === false) {
                    valData.secondYearStartDate = ''
                    valData.secondYearEndDate = ''
                }

                let resp = await dispatch(updateIntakeFxn({_id: courseId, intakeId: intakeId, intakeList: valData}))

                if (resp && resp.success) {
                    form.resetFields()
                    onClose()

                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }

        });
    };


    const inputSchema = [
        {
            title: "Month",
            key: "month",
            label: "Intake Month",
            required: true,
            placeholder: "Intake Month",
            type: 'select',
            options: months.list,
            span: "col-md-6",
            onChange: (date) => {
                setFieldsValue({month: date})
            }
        },
        {
            title: "Year",
            key: "year",
            type: "select",
            label: "Intake Year",
            required: true,
            placeholder: "Intake Year",
            span: "col-md-6",
            options: getIntakeYear(),
            onChange: (date) => {
                setFieldsValue({year: date})
            }
        },
        {
            title: "Start Date",
            key: "startDate",
            type: "date",
            label: "Start Date",
            required: true,
            placeholder: "Start Date",
            span: "col-md-6",
            onChange: (date) => {
                setFieldsValue({startDate: date})
            }
        },
        {
            title: "End Date",
            key: "endDate",
            type: "date",
            label: "End Date",
            required: true,
            placeholder: "End Date",
            span: "col-md-6",
            onChange: (date) => {
                setFieldsValue({endDate: date})
            }
        },
        {
            title: "Second Year Start Date",
            key: "secondYearStartDate",
            type: "date",
            label: "Second Year Start Date",
            placeholder: "Second Year Start Date",
            span: "col-md-6",
            hidden: pathway !== true,
            required: pathway !== true,
            onChange: (date) => {
                setFieldsValue({secondYearStartDate: date})
            }
        },

        {
            title: "Second Year End Date",
            key: "secondYearEndDate",
            type: "date",
            label: "Second Year End Date",
            placeholder: "Second Year End Date",
            span: "col-md-6",
            hidden: pathway !== true,
            required: pathway !== true,
            onChange: (date) => {
                setFieldsValue({secondYearEndDate: date})
            }
        },
        {
            key: "active",
            label: "Status",
            type: "switch",
            span: "col-md-6",
            options: conditionOptions,
            keyAccessor: (x) => x.value,
            valueAccessor: (x) => `${x.name}`,
            onChange: (data) => {
                setFieldsValue({active: data});
            },
        },
    ];
    return (
        <>
            <Drawer visible={visible} onClose={onClose} title={'Edit Intake'}>


                <form onSubmit={handleSubmit}>
                    <div className="row">
                        {inputSchema.map((field, key) => (

                            <div
                                className={`${field.span ? field.span : "col-md-6"}`}
                                key={key}
                            >
                                <GetEachFormFields
                                    getFieldDecorator={getFieldDecorator}
                                    getFieldError={getFieldError}
                                    {...props.form}
                                    item={field}
                                    keyAccessor={field.keyAccessor}
                                    valueAccessor={field.valueAccessor}
                                />
                            </div>
                        ))}
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </Drawer>
        </>
    );
};
export default Form.create()(EditIntakeDrawer);
