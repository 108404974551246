import React, {useEffect} from "react"
import {Form, Modal, Card, notification} from "../../../components/Elements/appUtils";
import {conditionOptions} from "../../../components/_utils/_utils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {addStudentFxn, generateOfferLetterFxn} from "../actions";
import {useDispatch} from "react-redux";

const OfferLetterGenerateModal = (props) => {
    let dispatch = useDispatch();
    let {
        visible, onClose, onSubmit,
        form: {getFieldDecorator, getFieldError, setFieldsValue, getFieldValue}, applicationId,
        scholarshipAmount, scholarship
    } = props;

    useEffect(() => {
        setFieldsValue({
            scholarship
        })
        setTimeout(() => {
            setFieldsValue({
                scholarshipAmount
            })
        }, 200)
    }, [])
    const fields = [
        {
            key: "scholarship",
            label: "Do you want to add a scholarship?",
            type: "radio",
            required: true,
            options: conditionOptions,
            span: "col-md-12",
            keyAccessor: (option) => option.value,
            valueAccessor: (option) => option.name,
            onChange: (value) => {
                setFieldsValue({
                    scholarship: value
                });
            },
        },
        {
            key: "scholarshipAmount",
            label: "Enter Scholarship Amount",
            placeholder: "Enter Scholarship Amount",
            required: true,
            type: "number",
            span: "col-md-12",
            hidden: !getFieldValue('scholarship')
        },
    ]
    const handleSubmit = async (e) => {
        e.preventDefault();
        const {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                const resp = await dispatch(generateOfferLetterFxn({
                    ...valData,
                    applicationId
                }));
                if (resp && resp.success) {
                    onSubmit()
                }
            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            width={'30%'}
            placement='right'
            title={(
                <>
                    <h5 className="card-action-title mb-0">
                        <span>Generate Offer Letter</span>
                    </h5>
                </>
            )}>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    {fields.map((item, key) => (
                        !item.hidden &&
                        <div className={`${item.span ? item.span : "col-md-12"}`} key={key}>
                            <GetEachFormFields
                                getFieldDecorator={getFieldDecorator}
                                getFieldError={getFieldError}
                                {...props.form}
                                item={item}
                                keyAccessor={item.keyAccessor}
                                valueAccessor={item.valueAccessor}
                                onChange={item.onChange}
                            />
                        </div>
                    ))}
                </div>

                <button type="submit" className="btn btn-primary mt-3">
                    Generate Offer Letter
                </button>
            </Form>
        </Modal>
    )
}
export default Form.create()(OfferLetterGenerateModal);
