import React from "react"
import {Modal} from "../../../components/Elements/appUtils";
import ActivitiesComponent from "../components/activities";

const ActivityModal = (props) => {
    let {visible, onClose} = props;
    return (
        <Modal
            visible={visible}
            onClose={onClose}
            width={'50%'}
            placement='right'
            title={(
                <h5 className="card-action-title mb-0">
                    <i className='bx bx-bar-chart-alt-2 bx-lg text-body me-4'></i>
                    <span>Activity Timeline</span>
                </h5>
            )}>
            <ActivitiesComponent {...props}/>
        </Modal>
    )
}
export default ActivityModal
