import {hideLoader, showLoader} from "../../../actions/loader";
import {customAxios as axios, getToken} from "../../../request";
import {addNoteUrl, documentListUrl, latestDocumentListUrl, NoteListUrl, uploadDocumentUrl} from "../api";
import {notification} from "../../Elements/appUtils";
import {courseListUrl} from "../../../containers/course/api";
import {editUserUrl} from "../../../containers/manager/api";
import {applicationListUrl} from "../../../containers/student/api";

export const addNoteFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(addNoteUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};
export const noteListFxn = async (filters) => {
    let config = {
        params: {...filters},
        ...await getToken()
    }
    let {data} = await axios.get(NoteListUrl(), config);
    return data;
};
export const uploadDocumentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(uploadDocumentUrl(), valData,{
        headers: {
            'Content-Type': 'multipart/form-data',
            ...await getToken()
        }
    });
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    } else {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data;
};
export const uploadPalDocumentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(uploadDocumentUrl(), valData,{
        headers: {
            'Content-Type': 'multipart/form-data',
            ...await getToken()
        }
    });
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    } else {
        notification.success({
            message: data.message || 'Success'
        })
    }

    return data;
};
export const documentListFxn = (valData) => async (dispatch) => {
    // dispatch(showLoader())
    let {data} = await axios.get(documentListUrl(), {params: valData})
    dispatch(hideLoader())
    return data.data;
}

export const latestDocumentListFxn = (valData) => async (dispatch) => {
    // dispatch(showLoader())
    let {data} = await axios.get(latestDocumentListUrl(), {params: valData})
    dispatch(hideLoader())
    return data;
}
