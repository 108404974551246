import React, {Suspense, useEffect, useRef, useState} from "react";
import Projects from "../student/components/documents";
import Connections from "../../components/profileComponent/Invoice";
import Profile from "../../components/profileComponent/profile";
import {useDispatch} from "react-redux";
import {singleApplicationFxn, updateStudentDetailFxn, updateStudentImageFxn} from "../student/actions";
import ActivitiesComponent from "../student/components/activities";
import NoteComponent from "../student/components/notes"
import {Avatar, Card, displayDate, notification} from "../../components/Elements/appUtils";
import Documents from "../student/components/documents";
import {Link, useParams} from "react-router-dom";
import {editUserFxn} from "../manager/action";
import { useLocation } from 'react-router-dom';
import {hideLoader, showLoader} from "../../actions/loader";
import Invoice from "../../components/profileComponent/Invoice";
import IntakeEditModal from "../student/modals/intakeEditModal";
const ProfileTab = (props) => {
    const dispatch = useDispatch()
    const location = useLocation();
    const {  studentId } = location.state || {};

    const [activeTab, setActiveTab] = useState('activity');
    const [profileSendPic , setProfileSendPic] = useState({});
    const [profilePic , setProfilePic] = useState()
    function extractParamsFromUrl(url) {
        const params = new URLSearchParams(url.split('?').pop());

        const applicationId = params.get('applicationId');
        // const studentId = params.get('studentId');
        return {applicationId};
    }
    const url = window.location.href;
    const {applicationId} = extractParamsFromUrl(url);
    const [singleStudentDetail, setSingleStudentDetail] = useState();
    console.log(singleStudentDetail, "singleStudentDetail")
    const [isHovered , setIsHovered] = useState(false);
    const apiRequest1 = async () => {
        let resp = await dispatch(singleApplicationFxn({applicationId}));
        setSingleStudentDetail(resp)
    };
    useEffect(() => {
        if (applicationId) {
            apiRequest1();
        }
    }, [applicationId]);
    const fileInputRef = useRef(null);
    const handleImageClick = () => {
        fileInputRef.current.click();
    };
    const onFileChange = (e) => {
        const file = e.target.files[0];
        setProfileSendPic(file);

    }
    const [editIntakeModal, setEditIntakeModal] = useState({
        courseId: "",
        visible: false,
        applicationId: '',
        record: {}
    })
    const events = {
        hideEditIntakeModal: async () => {
            setEditIntakeModal({
                agentId: "",
                visible: false,
                applicationId: ""
            })
            await apiRequest1()
        },
    }
    useEffect(()=>{
        const updateProfilePic = async ()=>{
            if(profileSendPic&&profileSendPic.name) {
                const {form} = props;
                try {
                    let fd = new FormData();
                    fd.append('obj', JSON.stringify({_id: studentId}));

                    if (profileSendPic && profileSendPic.name) {
                        fd.append('profile', profileSendPic);
                    }
                    let resp = await dispatch(updateStudentImageFxn(fd))

                    let data = resp.data;
                    if (resp && resp.success) {
                        setProfileSendPic({});
                        setProfilePic(data?.profile?.path);
                    }
                } catch (e) {
                    notification.warning({
                        message: 'error in updating'
                    })
                }
            }  };
        updateProfilePic();

        apiRequest1()

    },[profileSendPic])
    const renderTabContent = () => {
        switch (activeTab) {
            case 'activity':
                return <Card><ActivitiesComponent applicationId={applicationId} studentId={studentId}/></Card>;
            case 'note':
                return <NoteComponent applicationId={applicationId} studentId={studentId}/>;
            case 'documents':
                return <Documents applicationId={applicationId} studentId={studentId} />;
            case 'invoice':
                return <Invoice applicationId={applicationId} />;

            default:
                return null;
        }
    };
    const hasProfilePic = singleStudentDetail?.studentId?.profile?.path;
    return (

        <>

            <div className="row">

                <div className="col-xl-4 col-lg-5 order-1 order-md-0">

                    <div className="card mb-6">
                        <div className="card-body pt-12">
                            <div className="user-avatar-section">
                                <div className=" d-flex align-items-center flex-column">
                                    {/*<img className="img-fluid rounded mb-5" src="../../assets/img/studIcon.png"*/}
                                    {/*     height="120" width="120" alt="User avatar"/>*/}
                                    {/*{singleStudentDetail?.studentId?.firstName && singleStudentDetail?.studentId?.lastName ?*/}
                                    {/*    <div className={`avatar avatar me-2`}>*/}
                                    {/*    <span className={`avatar-initial rounded-circle bg-label-primary`} style={{border: "none",}}>*/}
                                    {/*       {singleStudentDetail?.studentId?.firstName.charAt(0)}*/}
                                    {/*        {singleStudentDetail?.studentId?.lastName.charAt(0)}*/}
                                    {/*    </span>*/}
                                    {/*</div> : null}*/}
                                    {/*<div style={{'textAlign':'center'}}> <img*/}
                                    {/*    src={profilePic?.path}*/}
                                    {/*    width={"60px"}*/}
                                    {/*    height={"55px"}*/}
                                    {/*    style={{*/}
                                    {/*        borderRadius: "50%",*/}
                                    {/*        border: "4px solid #696cffc7",*/}
                                    {/*    }}*/}

                                    {/*/> </div>*/}
                                    <div style={{ textAlign: 'center' }}>
                                        {hasProfilePic ? (
                                            <img
                                                src={singleStudentDetail?.studentId?.profile.path}
                                                alt="Profile"
                                                width="120px"
                                                height="120px"
                                                style={{
                                                    borderRadius: '50%',
                                                    border: '4px solid #696cffc7',
                                                }}
                                            />
                                        ) : (
                                            <div className="avatar design me-2 ">
                                              <span
                                                  className="avatar-initial rounded-circle bg-label-primary"
                                                  style={{ border: 'none', fontSize: '24px' }}
                                              >
                                                {singleStudentDetail?.studentId?.firstName?.charAt(0)}
                                                  {singleStudentDetail?.studentId?.lastName?.charAt(0)}
                                              </span>
                                            </div>
                                        )}
                                    </div>
                                    <div style={{'textAlign':'center',}} className="mb-3"> <button
                                        onClick={handleImageClick}
                                        style={{
                                            cursor: 'pointer',
                                            height:'13px',
                                            width:'90px',
                                            fontSize:'10px',
                                            borderRadius:'10px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            margin:'auto',
                                            backgroundColor: isHovered ? '#dcdcdc' : '#fff',
                                            color: isHovered ? '#000' : '#333',
                                            border: '1px solid #ccc',
                                            padding: '10px 20px',
                                            transition: 'background-color 0.3s, color 0.3s'
                                        }}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                    >
                                        Upload
                                    </button></div>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={onFileChange}
                                    />
                                    <div className="user-info text-center">
                                        <h5 className="username m-0 fw-bold">
                                            <Link to={`/edit-student/${singleStudentDetail?.studentId._id}`}>
                                                <i className={`menu-icon tf-icons bx bx-edit-alt`}/>
                                            </Link>
                                            {singleStudentDetail && singleStudentDetail.studentId.firstName} {singleStudentDetail && singleStudentDetail.studentId.lastName}
                                        </h5>
                                        <p className="username m-0">{singleStudentDetail && singleStudentDetail.studentId.email}</p>
                                        <p className="username m-0">
                                            {singleStudentDetail && singleStudentDetail.studentId.cityName}, {singleStudentDetail && singleStudentDetail.studentId.stateName}, {singleStudentDetail && singleStudentDetail.studentId.countryName}
                                        </p>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="card ">
                        <div className="card-body ">


                            <h5 className="pb-2 border-bottom  details-section fw-bold">Details</h5>
                            <div className="info-container">
                                <div className="row">
                                    <div className="d-flex justify-content-between align-items-center mb-1">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <a className="btn rounded-pill btn-label-secondary">
                                                    <i className="fa fa-university" aria-hidden="true"></i>
                                                          </a>
                                            </div>
                                            <div className="col-md-10">
                                                <h6 className="mb-0 small fw-medium text-capitalize fw-bold"> {singleStudentDetail && singleStudentDetail.courseId.courseName}{' '} -
                                                                    {' '} {singleStudentDetail && singleStudentDetail.courseDetails.courseDuration} </h6>
                                                                    <small className={'text-body fee-structure'}>Application Fee: {' '}{singleStudentDetail && singleStudentDetail.applicationFee} | {'   '}
                                                                    </small>
                                                                <small className={'text-body fee-structure'}>Tuition Fee: {' '}{singleStudentDetail && singleStudentDetail.tuitionFee} | {'   '}
                                                                </small>

                                                                <small className={'text-body fee-structure'} onClick={() => {
                                                                    if (!singleStudentDetail.invoiceCount)
                                                                        setEditIntakeModal({
                                                                            courseId: singleStudentDetail.courseId._id,
                                                                            visible: true,
                                                                            applicationId: singleStudentDetail._id,
                                                                            record: singleStudentDetail
                                                                        })
                                                                    else
                                                                        notification.warning({message: "Invoice generated . Intake changes are no longer allowed"});

                                                                }}>Intake: {' '}<span style={{color:'blue'}}>{singleStudentDetail && singleStudentDetail?.intake?.month}, {' '}
                                                                    {singleStudentDetail && singleStudentDetail?.intake?.year}</span> </small><br />
                                                <div className="agent-info" style={{marginTop:'-10px'}}>
                                                    <small className={'text-body fee-structure'}>Added On:{'   '}{displayDate(singleStudentDetail && singleStudentDetail.addedOn)} {'  '}
                                                    </small>
                                                    {'   '}<small className={'text-body fee-structure'}>Agent Name:{'  '}{singleStudentDetail && singleStudentDetail.agentId.name}</small>
                                                </div>


                                            </div>
                                        </div>


                                    </div>


                                </div>
            {/*                    <div className="card-document-content ml-5">*/}
            {/*                        <div className="fee-section ">*/}
            {/*                            <div className="fee-item">*/}
            {/*                                <a >*/}

            {/*                                </a>*/}
            {/*                                <span className="course-name">*/}
            {/*    {singleStudentDetail && singleStudentDetail.courseId.courseName}{' '} -*/}
            {/*</span>*/}
            {/*                                <span className="duration">*/}
            {/*    {singleStudentDetail && singleStudentDetail.courseDetails.courseDuration}*/}
            {/*</span>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="card-document-content mb-3">*/}
            {/*                        <div className="fee-section">*/}
            {/*                            <div className="fee-item ">*/}
            {/*                                <span>Added On: {displayDate(singleStudentDetail && singleStudentDetail.addedOn)} </span>*/}
            {/*                            </div>*/}
            {/*                            <div className="fee-item ">*/}
            {/*                                <span>Agent Name: {singleStudentDetail && singleStudentDetail.agentId.name} </span>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}

                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-xl-8 col-lg-7 order-0 order-md-1">

                    <div className="nav-align-top">
                        <ul className="nav nav-pills flex-column flex-sm-row nav-pills mb-4 ml-0">
                            <li className="nav-item">
                                <a className={`nav-link cursor-pointer ${activeTab === 'activity' ? 'active' : ''}`}
                                   onClick={() => setActiveTab('activity')}>
                                    <i className='bx bx-user me-1_5 bx-sm'></i> Activity
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link cursor-pointer ${activeTab === 'note' ? 'active' : ''}`}
                                   onClick={() => setActiveTab('note')}>
                                    <i className='bx bx-group me-1_5 bx-sm'></i> Notes
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link cursor-pointer ${activeTab === 'documents' ? 'active' : ''}`}
                                   onClick={() => setActiveTab('documents')}>
                                    <i className='bx bx-grid-alt me-1_5 bx-sm'></i> Documents
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link cursor-pointer ${activeTab === 'invoice' ? 'active' : ''}`}
                                   onClick={() => setActiveTab('invoice')}>
                                    <i className='bx bx-grid-alt me-1_5 bx-sm'></i> Invoice
                                </a>
                            </li>

                        </ul>
                    </div>
                    {editIntakeModal.visible ?
                        <IntakeEditModal
                            onClose={events.hideEditIntakeModal}
                            {...editIntakeModal} /> : null}

                    <div className="col-md-12">
                        {renderTabContent()}
                    </div>
                </div>

            </div>

        </>
    )
}
export default ProfileTab
