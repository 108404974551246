import React from "react"
import "./App.css";
import Routes from "./routes/route";
import {connect} from 'react-redux'
import {hideLoader} from "./actions/loader";
import {getUserRightFxn} from "./containers/manager/action";

class App extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.dispatch(hideLoader())
        this.loadRights()
    }

    loadRights = async () => {
        let {dispatch, currentUser} = this.props;
        if (currentUser && currentUser._id) {
            let {data} = await getUserRightFxn();
            if (data && data.rights) {
                dispatch({type: "RIGHTS", rights: data.rights})
            }
        }
    }

    render() {
        let {isLoading} = this.props;
        return (
            <div className="App">
                {isLoading ? <div className={'loader_outer_view'}>
                    <div className={'loader_inner'}>
                        <br/>
                        <div className="sk-chase sk-primary">
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                        </div>
                        <div className={'spinnerText'}>
                            Loading
                        </div>
                    </div>
                </div> : null}

                <Routes {...this.props}/>

                <div id={'confirm-dialog'}></div>

            </div>
        );
    }
}

const mapStateToProps = ({global, router}) => ({
    isLoading: global.isLoading,
    currentUser: global.currentUser,
    rights: global.rights
})
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App)

