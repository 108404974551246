import React, {Suspense} from "react";
import {
    Route,
    BrowserRouter as Router,
    Routes, Navigate
} from "react-router-dom";

import FrontLayout from "../layout/FrontLayout";
import AuthLayout from "../layout/AuthLayout";
import MainLayout from "../layout/MainLayout";
import {history} from "../reducers/store";
import {menuData} from "./menu";
import {authMenuData} from "./authMenu";
import ContactUs from "../containers/contact";
import WhyGbc from "../containers/whyGbc";
import {checkUserRight} from "../userRights";
import UserRightComponent from "../userRights";
import {connect} from "react-redux";

const RouteList = (props) => {

    let {currentUser = {}, rights} = props;
    let checkRight = (child, user) => {
        if (child.authority && child.authority.length) {
            if (child.authority.includes(user.userType)) {
                let obj = {user, rights, route: child}
                return checkUserRight(obj)
            } else {
                return false
            }
        } else {
            return true
        }
    }

    return (
        <Router history={history}>
            <Routes>

                <Route path={"/"} element={<MainLayout {...props} />}>

                    {menuData.map((item) => {
                        let {component: Component} = item;
                        if (currentUser) {
                            if (item.subMenu && item.subMenu.length) {
                                return item.subMenu.map((child, k) => {
                                    let {component: Component} = child;
                                    let routeRow = (
                                        <Route
                                            exact
                                            path={child.path}
                                            key={child.key}
                                            element={<Component {...props} user={currentUser}/>}
                                        />
                                    );
                                    return checkRight(child, currentUser) ? routeRow : null
                                });
                            } else {
                                let routeRow = (
                                    <Route
                                        exact
                                        path={item.path}
                                        key={item.key}
                                        element={<Component {...props} user={currentUser}/>}
                                    />
                                );
                                return checkRight(item, currentUser) ? routeRow : null
                            }
                        }
                    })}

                </Route>

                <Route path={"/"} element={
                    <Suspense fallback={<></>}>
                        <FrontLayout {...props} />
                    </Suspense>
                }>
                    <Route path={"/contactUs"} element={<ContactUs/>}/>
                    <Route path={"/whyGbc"} element={<WhyGbc/>}/>

                </Route>
                <Route path={"/"} element={
                    <Suspense fallback={<></>}>
                        <AuthLayout {...props} />
                    </Suspense>
                }>
                    {authMenuData.map((item) => {
                        let {component: Component} = item;
                        return (
                            <Route
                                exact
                                path={item.path}
                                key={item.key}
                                element={<Component {...props}/>}/>
                        )
                    })}
                </Route>
                <Route path="*"
                       element={<Navigate replace to={currentUser && currentUser._id ? "/dashboard" : "/login"}/>}/>
            </Routes>
        </Router>
    );
};

const mapStateToProps = ({global}) => ({
    currentUser: global.currentUser,
    rights: global.rights
})
export default connect(
    mapStateToProps,
    null
)(RouteList)
