import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import CountryJson from '../../assests/jsonFiles/countries.json';
import StatesJson from "../../assests/jsonFiles/states.json";
import {Card, Form, notification} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {courseListFxn} from "../course/action";
import {getAllUsersFxn} from "../manager/action";
import _ from "lodash";
import moment from "moment";
import {conditionOptions} from "../../components/_utils/_utils";

const initState = {
    firstName: "",
    lastName: "",
    dateOfBirth: undefined,
    email: "",
    nationality: "",
    studentType: "",
    course: "",
    intake: "",
    isEsl: "",
    startDate: undefined,
    endDate: undefined,
    expirationDate: undefined,
    issueDate: undefined,
    agent: "",
    marketingManager: "",
    country: "",
    state: "",
    city: "",
    address: "",
};

const AddStudent = (props) => {
    const {
        form: {getFieldDecorator, getFieldError, setFieldsValue}, currentUser: user
    } = props;
    const dispatch = useDispatch();
    const [state, setState] = useState(initState);
    const [courseList, setCourseList] = useState([]);
    const [agentList, setAgentList] = useState([]);
    const [marketingManagerList, setMarketingManagerList] = useState([]);
    const [allStates, setAllStates] = useState([]);
    const [intakeList, setIntakeList] = useState([])
    let [intake, setIntake] = useState({})

    const listEvents = {
        loadCourseList: async () => {
            try {
                const {data} = await courseListFxn();
                setCourseList(data.data);
            } catch (error) {
                console.error("Failed to load course list:", error);
            }
        },
        loadAgentList: async () => {
            let obj = {
                results: 500,
                count: 500,
                userType: "agent"
            }
            let resp = await getAllUsersFxn(obj);
            setAgentList(resp.data);
        }
    }

    useEffect(() => {
        if (user.userType == "agent" && agentList && agentList.length) {
            let singleAgent = agentList[0]
            setFieldsValue({agentId: singleAgent._id})
            setTimeout(() => {
                events.chooseAgent(singleAgent._id)
            }, 200)
        }
    }, [agentList])

    const events = {

        _updateState: (data) => {
            setState((prevState) => ({
                ...prevState,
                ...data,
            }));
        },
        loadState: (countryId) => {
            if (countryId) {
                let filterArr = _.filter(StatesJson, (item) => item.country_id === countryId);
                return filterArr;
            }
            return [];
        },
        chooseCountry: (value) => {
            let country = _.find(CountryJson, (item) => item.name === value);
            if (country) {
                setFieldsValue({countryName: value})
                setAllStates(country.id ? events.loadState(country.id) : []);
            }
        },
        chooseState: (value) => {
            setFieldsValue({stateName: value})
        },
        chooseCourse: (courseId) => {
            let findCourse = _.find(courseList, (item) => {
                return item && item._id == courseId
            })
            if (findCourse) {
                let data = []
                _.each(findCourse.intakeList, (item) => {
                    if (item) {
                        item.value = `${item.month}-${item.year}`
                        item.label = `${item.month}, ${item.year}`
                        data.push(item)
                    }
                })
                setIntakeList(data)
            }
            setFieldsValue({courseId: courseId})
        },
        chooseIntake: (value) => {
            let findIntake = _.find(intakeList, (item) => {
                return item && item.value == value
            })
            if (findIntake) {
                setFieldsValue({
                    intakeTemp: value,
                    startDate: findIntake.startDate ? moment(findIntake.startDate) : undefined,
                    endDate: findIntake.endDate ? moment(findIntake.endDate) : undefined,
                })
            }
            if (value) {
                let intakeVal = value.split('-')
                let intake = {
                    month: intakeVal[0],
                    year: intakeVal[1]
                }
                setIntake(intake)
            } else {
                setIntake({})
            }
        },
        chooseAgent: (data) => {
            let obj = {
                agentId: data
            }
            let findAgent = _.find(agentList, (item) => {
                return item && item._id == data
            })
            if (findAgent && findAgent.marketingManager) {
                obj.marketingManagerId = findAgent.marketingManager.userId
                setMarketingManagerList([findAgent.marketingManager])
            }
            setFieldsValue({
                ...obj
            })
        }
    };

    const sections = [
        {
            title: "Personal Information",
            fields: [
                {
                    key: "firstName",
                    label: "First Name",
                    span: "col-md-6",
                    placeholder: "Enter First Name",
                    required: false
                },
                {
                    key: "lastName",
                    label: "Last Name",
                    span: "col-md-6",
                    placeholder: "Enter Last Name",
                },
                {
                    key: "dateOfBirth",
                    label: "Date of Birth",
                    type: "dateOfBirth",
                    span: "col-md-6",
                    placeholder: "Enter Date of Birth",
                    required: true,
                    onChange: (value) => {
                        console.log(value)
                        setFieldsValue({
                            dateOfBirth: value
                        })
                    },
                },
                /*{
                    key: "email",
                    label: "Email",
                    type: "email",
                    span: "col-md-6",
                    placeholder: "Enter Email",
                    required: false
                },*/
                /* {
                     key: "nationality",
                     label: "Nationality",
                     span: "col-md-6",
                     placeholder: "Enter Nationality",
                     required: false
                 },
                 {
                     key: "studentType",
                     label: "Student Type",
                     type: "checkbox",
                     required: false,
                     options: [
                         {label: "International", value: "international"},
                         {label: "Onshore", value: "onshore"},
                     ],
                     span: "col-md-6",
                     placeholder: "Select Student Type",
                     keyAccessor: (option) => option.value,
                     valueAccessor: (option) => option.label,
                     onChange: (value) => {
                         setFieldsValue({
                             studentType: value
                         })
                     },
                 },*/
                /* {
                     key: "studentRadio",
                     label: "Student Radio",
                     type: "radio",
                     required: false,
                     options: [
                         {label: "International", value: "international"},
                         {label: "Onshore", value: "onshore"},
                     ],
                     span: "col-md-6",
                     placeholder: "Select Student Type",
                     keyAccessor: (option) => option.value,
                     valueAccessor: (option) => option.label,
                     onChange: (value) => {
                         setFieldsValue({
                             studentRadio: value
                         })
                     },
                 },
                 {
                     key: "isEsl",
                     label: "ESL",
                     type: "radio",
                     required: false,
                     options: conditionOptions,
                     span: "col-md-6",
                     keyAccessor: (option) => option.value,
                     valueAccessor: (option) => option.name,
                     onChange: (value) => {
                         setFieldsValue({
                             isEsl: value
                         })
                     },
                 },*/
                /*       {
                           key: "mobile",
                           type: "phone",
                           label: "Phone no",
                           required: false,
                           placeholder: "Enter mobile no",
                           span: 'col-md-6',
                           onChange: (value) => {
                               setFieldsValue({mobile: value});
                           }
                       },
                       {
                           key: "address",
                           label: "Address",
                           span: "col-md-6",
                           placeholder: "Enter Address",
                       },
                       {
                           key: "countryName",
                           type: "select",
                           label: "Country",
                           placeholder: "Select Country",
                           span: "col-md-4",
                           required: false,
                           showSearch: true,
                           options: CountryJson.map((country) => country.name),
                           onChange: (value) => {
                               events.chooseCountry(value)
                           },
                       },
                       {
                           key: "state",
                           type: "select",
                           label: "State",
                           placeholder: "Select State",
                           span: "col-md-4",
                           showSearch: true,
                           options: allStates.map((state) => state.name),
                           onChange: (value) => {
                               setFieldsValue({
                                   stateName: value
                               })
                           },
                       },
                       {
                           key: "city",
                           label: "City",
                           span: "col-md-4",
                           placeholder: "Enter City",
                       },
                       {
                           key: "active",
                           label: "Active",
                           type: "switch",
                           span: "col-md-4",
                           placeholder: "Enter City",
                       },*/
            ],
        }
    ];


    useEffect(() => {
        listEvents.loadCourseList();
        listEvents.loadAgentList();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const {form} = props;
        form.validateFields(async (err, valData) => {
            console.log(err)
            if (!err) {

            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-xl">
                    <Card title={"Add Student"}>
                        <Form onSubmit={handleSubmit}>
                            {sections.map((section, sectionIndex) => (
                                <div className="form-section" key={sectionIndex}>
                                    <h6 className="form-title">{section.title}</h6>
                                    <div className="row">
                                        {section.fields.map((item, key) => (
                                            !item.hidden &&
                                            <div className={`${item.span ? item.span : "col-md-6"}`} key={key}>
                                                <GetEachFormFields
                                                    getFieldDecorator={getFieldDecorator}
                                                    getFieldError={getFieldError}
                                                    {...props.form}
                                                    item={item}
                                                    keyAccessor={item.keyAccessor}
                                                    valueAccessor={item.valueAccessor}
                                                    onChange={item.onChange}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <hr className="my-6 mx-n6"/>

                                </div>
                            ))}
                            <button type="submit" className="btn btn-primary">
                                Submit
                            </button>
                        </Form>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default Form.create()(AddStudent);
