import {customAxios as axios, getToken} from "../../../request";
import {
    addMarketingManagerUrl,
    addResourceUrl,
    editUserUrl,
    getAllAgentUrl,
    getAllUsersUrl,
    getMarketingManagerUrl, ResourceListUrl
} from "../api";
import {hideLoader, showLoader} from "../../../actions/loader";

import {notification} from "../../../components/Elements/appUtils";

export const addResourceFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(addResourceUrl(), valData, getToken());
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    } else {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data;
};
export const resourceListFxn = async (filters) => {
    let config = {
        params: {...filters}
    }
    let {data} = await axios.get(ResourceListUrl(), config);
    return data.data;
};

