import React, {useEffect, useRef, useState} from "react";
import {Card, displayDate, Table} from "../../../components/Elements/appUtils";
import {getAllUsersFxn, getMarketingManagerFxn} from "../action";
import {Link, useNavigate} from "react-router-dom";
import moment from "moment";
import UserRightComponent from "../../../userRights";

const {TableCard} = Card;

const ManagerList = () => {
    const navigate = useNavigate();
    const tableRef = useRef();
    const [total, setTotal] = useState(0);
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
    const [dateVal, setDateVal] = useState()
    const filterInput = [
        {
            key: "name",
            label: "Name",
            span: "col-md-3",
            type: "text",
        },
        {
            key: "mobile",
            label: "Mobile",
            span: "col-md-3",
            type: "text",
        },
        {
            key: "email",
            label: "Email",
            span: "col-md-3",
            type: "text",
        },
        {
            key: "postName",
            label: "Post Name",
            span: "col-md-3",
            type: "text",
        },
        {
            key: "joiningDate",
            label: "Date",
            type: "date",
            span: "col-md-3",
            placeholder: "Choose Date",
            getValue: (value) => {
                setDateVal(value._d)
            }
        },
        {
            key: "address",
            label: "Address",
            span: "col-md-3",
            type: "text"
        },
    ];

    const columns = [
        {title: "Sr. No.", key: "serialNumber", render: (text, record, index) => index + 1,},
        {
            title: "Name", dataIndex: "name", key: "name",
            render: (item, record) => {
                return (
                    <>
                        {item}<br/>
                        {record.referCode ?
                            <span className="badge bg-label-primary mt-1">Refer Code : {record.referCode}</span> : null}
                    </>
                )
            }
        },
        {title: "Type", dataIndex: "userType", key: "userType"},
        {title: "Mobile", dataIndex: "mobile", key: "mobile"},
        {title: "Email", dataIndex: "email", key: "email"},
        {title: "Post Name", dataIndex: "postName", key: "postName"},
        {
            title: "Joining Date", dataIndex: "joiningDate", key: "joiningDate",
            render: (item) => {
                return item ? displayDate(item) : null
            }
        },
        {
            title: "Added on", dataIndex: "createdAt", key: "createdAt",
            render: (item) => {
                return item ? displayDate(item) : null
            }
        },
        {title: "Address", dataIndex: "address", key: "address"},
        {
            title: "Action",
            key: "action",
            render: (text, record) =>
                <> <UserRightComponent route={{right: "users", rightType: "edit"}} name={'edit-user'}>
                    <Link to={`/editUser/${record._id}`}>
                        <i className={`menu-icon tf-icons bx bx-edit-alt`}/>
                    </Link>
                </UserRightComponent></>
        }

    ];

    const initialColumns = ['serialNumber', 'userType', 'name', 'mobile', 'email', 'joiningDate', 'action', 'createdAt'];

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            if (dateVal) {
                const date1 = moment(dateVal, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
                const startOfDay = date1.startOf('day').toISOString();
                const endOfDay = date1.endOf('day').toISOString();
                const dateFilter = {
                    $gte: startOfDay,
                    $lte: endOfDay
                };
                params.joiningDate = dateFilter;
            }
            const regExFilters = ["name", "email", "password", "mobile", "address"];
            let data = await getAllUsersFxn({...params, regExFilters});
            setTotal(data.total);
            setDateVal('')
            resolve(data);
        });
    };


    return (
        <>
            <TableCard title={`Team/BDM (Total: ${total})`} actions={
                <> <UserRightComponent route={{right: "users", rightType: "add"}} name={'add-user'}>
                    <a className="btn btn-label-primary" onClick={() => navigate(`/add-team-and-bdm`)}>
                        <span className={'tf-icons bx bx-plus bx-18px me-2'}></span>
                        Add Team
                    </a>
                </UserRightComponent></>
            }
            >
                <Table
                    filterFields={filterInput}
                    initialColumns={initialColumns}
                    apiRequest={apiRequest}
                    columns={columns}
                    bordered
                    ref={tableRef}
                />
            </TableCard>
        </>
    );
};

export default ManagerList;
