import {apiUrl} from "../../../settings"

export const addMarketingManagerUrl = () => {
    return apiUrl + '/addUser'
}
export const getMarketingManagerUrl = () => {
    return apiUrl + '/getMarketingManager'
}
export const editUserUrl = () => {
    return apiUrl + '/updateUser'
}

export const getAllUsersUrl = () => {
    return apiUrl + '/getAllUsers'
}

export const getAllAgentUrl = () => {
    return apiUrl + '/getAllAgent'
}

export const getUserRightUrl = () => {
    return apiUrl + '/api/rights'
}

export const getSingleUserUrl = (id) => {
    return apiUrl + `/getSingleUser/${id}`
}
