import {apiUrl} from "../../../settings"

export const addNoteUrl = () => {
    return apiUrl + '/note/add'
}


export const NoteListUrl = () => {
    return apiUrl + '/note/list'
}
export const uploadDocumentUrl = () => {
    return apiUrl + '/api/document'
}
export const documentListUrl = () => {
    return apiUrl + '/document/list'
}

export const latestDocumentListUrl = () => {
    return apiUrl + '/document/latest'
}
