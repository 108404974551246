export const universities = {
    onshoreFcmtCollege: '6572f5f243aa131f8cc58616',
    onshoreCambieCollege: '6572f5f243aa131f8cc5861c',
    ciraCollege: '63cf85082b3f7a227856ec9e',
    gbcCollege: '6690d1881f7f226bfe94cc2f',
    cornerstoneCollege: '663df74476f34c0bd0f234e9',
}
export const universityLayouts = {
    [universities.onshoreFcmtCollege]: "FcmtLayout",
    [universities.onshoreCambieCollege]: "CambieLayout",
    [universities.ciraCollege]: "CiraLayout",
    [universities.gbcCollege]: "GbcLayout",
    [universities.cornerstoneCollege]: "CornerstoneLayout",
}
